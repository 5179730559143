import { compact, xor } from 'lodash';
import React, { FC } from 'react';

import {
    AdminInvoiceFilters,
    BillingEntityDto,
    InvoicingEntityFilter,
    invoicingEntityFilterColors,
    PaymentStatusFilter,
    paymentStatusFilterColours,
} from '@hofy/api-admin';
import {
    InvoiceStatus,
    invoiceStatusColors,
    netsuiteStatusColors,
    NetsuiteSyncStatus,
    OrganizationRefDto,
    useTrInvoiceStatus,
} from '@hofy/api-shared';
import { DateRangeStrings } from '@hofy/global';
import { formatDateRange } from '@hofy/helpers';
import { FilterChip } from '@hofy/ui';

import { useTrInvoicingEntityFilter } from '../../../../../store/invoices/useTrInvoicingEntityFilter';
import { useTrPaymentStatusFilter } from '../../../../../store/invoices/useTrPaymentStatusFilter';
import { useTrNetsuiteSyncStatus } from '../../../../../store/netsuite/useTrNetsuiteSyncStatus';

interface InvoicesPageActiveFilterChipsProps {
    filters: AdminInvoiceFilters;
    organizations: OrganizationRefDto[];
    billingEntities: BillingEntityDto[];
    onNetsuiteStatusChange(statuses: NetsuiteSyncStatus[]): void;
    onOrganizationChange(organizations: number[]): void;
    onBillingEntityChange(billingEntities: number[]): void;
    onInvoiceStatusChange(statuses: InvoiceStatus[]): void;
    onPaymentStatusChange(statuses: PaymentStatusFilter[]): void;
    onInvoicingEntityFilterChange(invoicingEntity: InvoicingEntityFilter | null): void;
    onInvoiceDateChange(date: DateRangeStrings | null): void;
}

export const InvoicesPageActiveFilterChips: FC<InvoicesPageActiveFilterChipsProps> = ({
    filters,
    organizations,
    billingEntities,
    onNetsuiteStatusChange,
    onOrganizationChange,
    onBillingEntityChange,
    onInvoiceStatusChange,
    onPaymentStatusChange,
    onInvoicingEntityFilterChange,
    onInvoiceDateChange,
}) => {
    const trNetsuiteSyncStatus = useTrNetsuiteSyncStatus();
    const trInvoiceStatus = useTrInvoiceStatus(false);
    const trPaymentStatusFilter = useTrPaymentStatusFilter();
    const trInvoicingEntityFilter = useTrInvoicingEntityFilter();

    const removeOrganization = (id: number) => {
        onOrganizationChange(xor(filters.organization, [id]));
    };
    const removeBillingEntity = (id: number) => {
        onBillingEntityChange(xor(filters.billingEntity, [id]));
    };

    const organizationRefs = compact(
        filters.organization.map(id => organizations.find(org => org.id === id)),
    );
    const billingEntityRefs = compact(
        filters.billingEntity.map(id => billingEntities.find(b => b.id === id)),
    );

    return (
        <>
            {organizationRefs.map(org => (
                <FilterChip
                    key={org.id}
                    label={org.name}
                    onClear={() => removeOrganization(org.id)}
                    color='blue'
                />
            ))}
            {billingEntityRefs.map(b => (
                <FilterChip
                    key={b.id}
                    label={b.name}
                    onClear={() => removeBillingEntity(b.id)}
                    color='teal'
                />
            ))}
            {filters.netsuiteStatus.map(status => (
                <FilterChip
                    key={status}
                    label={trNetsuiteSyncStatus(status)}
                    onClear={() => onNetsuiteStatusChange(xor(filters.netsuiteStatus, [status]))}
                    color={netsuiteStatusColors[status]}
                />
            ))}
            {filters.invoiceStatus.map(status => (
                <FilterChip
                    key={status}
                    label={trInvoiceStatus(status)}
                    onClear={() => onInvoiceStatusChange(xor(filters.invoiceStatus, [status]))}
                    color={invoiceStatusColors[status]}
                />
            ))}
            {filters.paymentStatus.map(status => (
                <FilterChip
                    key={status}
                    label={trPaymentStatusFilter(status)}
                    onClear={() => onPaymentStatusChange(xor(filters.paymentStatus, [status]))}
                    color={paymentStatusFilterColours[status]}
                />
            ))}
            {filters.invoicingEntity !== null && (
                <FilterChip
                    label={trInvoicingEntityFilter(filters.invoicingEntity)}
                    onClear={() => onInvoicingEntityFilterChange(null)}
                    color={invoicingEntityFilterColors[filters.invoicingEntity]}
                />
            )}
            {filters.invoiceDate && (
                <FilterChip
                    label={formatDateRange(filters.invoiceDate)}
                    onClear={() => onInvoiceDateChange(null)}
                    color='teal'
                />
            )}
        </>
    );
};
