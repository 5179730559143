import { AuditableModelDto } from '@hofy/api-shared';
import { ColorDye, DateTimeString, getEnumValues } from '@hofy/global';

import { JobStatus } from './JobStatus';

export enum JobContext {
    Invoices = 'invoices',
    Suppliers = 'suppliers',
    PurchaseOrders = 'purchase_orders',
    Journals = 'journals',
}
export const jobContextColors: Record<JobContext, ColorDye> = {
    [JobContext.Invoices]: 'red',
    [JobContext.Suppliers]: 'green',
    [JobContext.PurchaseOrders]: 'blue',
    [JobContext.Journals]: 'purple',
};

export const allJobContexts = getEnumValues<JobContext>(JobContext);

export interface JobDto extends AuditableModelDto {
    name: string;
    context: JobContext | null;
    status: JobStatus;
    startedAt: DateTimeString | null;
    finishedAt: DateTimeString | null;
    duration: string | null;
    tasksProcessed: number;
    tasksTotal: number;
    activeTask: string | null;
    failureReasons: string[];
}

export const jobIsCancellable = (job: JobDto) =>
    job.status === JobStatus.Pending || job.status === JobStatus.Processing;
