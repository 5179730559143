import { Price } from '@hofy/global';

import { CategoryContractSettingsConfigDto } from './ContractSettingConfigDto';
import { OrderAction } from './OrderAction';
import { TeamRefDto } from './TeamRefDto';

export interface TeamDto extends TeamRefDto {
    allowance: Price;
    allowanceProductsCount: number;
    note: string;
    withinBudgetAction: OrderAction;
    autoApproveFirstLaptop: boolean;
    defaultContractSettings?: CategoryContractSettingsConfigDto[];
    overrideCompanyDefaultContractSettings: boolean;
    overBudgetAction: OrderAction;
}

export const isTeamNameUsed = (teams: TeamDto[] | undefined = [], name: string | undefined = '') => {
    const newName = name.toLowerCase();
    return teams.some(team => team.name.toLowerCase() === newName);
};
