import { useQueryClient } from '@tanstack/react-query';
import React, { FC } from 'react';

import { assignmentsCacheKey, ShipmentDetailsDto, shipmentsCacheKey } from '@hofy/api-admin';
import {
    ShipmentTrackingDto,
    ShipmentTrackingExceptionStatus,
    useTrShipmentTrackingExceptionStatus,
    useTrShipmentTrackingProvider,
    useTrShipmentTrackingStatus,
} from '@hofy/api-shared';
import { formatDate, formatDateTime } from '@hofy/helpers';
import {
    Alert,
    BooleanBadge,
    Box,
    Button,
    FormGridRow,
    FormSection,
    Icon,
    Labeled,
    LabeledText,
    Paragraph3,
    SvgIcon,
    Tooltip,
} from '@hofy/ui';

import {
    providersIcons,
    trackingExceptionIconDetails,
} from '../../../shipmentsPage/components/table/ShipmentTrackingStatus';
import { ShipmentTrackingUpdateTable } from './ShipmentTrackingUpdate';

interface ShipmentTrackingProps {
    shipment: ShipmentDetailsDto;
}

export const ShipmentTracking: FC<ShipmentTrackingProps> = ({ shipment }) => {
    const trTrackingProvider = useTrShipmentTrackingProvider();
    const trTrackingExceptionStatusStatus = useTrShipmentTrackingExceptionStatus();

    if (!shipment.tracking) {
        return null;
    }
    return (
        <Box column gap={24} paddingVertical={24}>
            <FormSection label='Tracking provider' overflow='hidden'>
                <FormGridRow columns={3}>
                    <Labeled
                        label='Provider name'
                        content={
                            <Box row gap={5}>
                                <Icon size={16} svg={providersIcons[shipment.tracking.provider]} />
                                <Paragraph3>{trTrackingProvider(shipment.tracking.provider)}</Paragraph3>
                            </Box>
                        }
                        flex={1}
                    />
                    <LabeledText
                        label='External id'
                        content={shipment.tracking.providerExternalId}
                        flex={1}
                    />
                    <LabeledText
                        label='Exception status'
                        content={
                            <Box row gap={5}>
                                <TrackingExceptionStatusIcon status={shipment.trackingExceptionStatus} />
                                {shipment.trackingExceptionStatus
                                    ? trTrackingExceptionStatusStatus(shipment.trackingExceptionStatus)
                                    : '--'}
                            </Box>
                        }
                        flex={1}
                    />
                </FormGridRow>
            </FormSection>
            <TrackingUpdateDetails tracking={shipment.tracking} shipmentId={shipment.id} />
        </Box>
    );
};
const TrackingExceptionStatusIcon: FC<{
    status: ShipmentTrackingExceptionStatus | null;
}> = ({ status }) => {
    const { icon, color, tooltip } = trackingExceptionIconDetails(status);
    return (
        <Tooltip placement='bottom' body={tooltip} enabled>
            <Icon svg={icon} color={color} />
        </Tooltip>
    );
};
const TrackingUpdateDetails: FC<{
    tracking: ShipmentTrackingDto;
    shipmentId: number;
}> = ({ tracking, shipmentId }) => {
    const trTrackingStatus = useTrShipmentTrackingStatus();
    const lastUpdate = tracking.lastUpdate;
    const queryClient = useQueryClient();

    if (!lastUpdate) {
        const refreshPage = () => {
            queryClient.invalidateQueries({
                queryKey: [assignmentsCacheKey, 'shipment', shipmentId],
            });
            queryClient.invalidateQueries({
                queryKey: [shipmentsCacheKey, shipmentId],
            });
        };
        return (
            <Alert
                icon={SvgIcon.Lightbulb}
                type='informative'
                title='Tracking data'
                description='Tracking data might take some time to be fetched the first time'
            >
                <Button
                    leftIcon={SvgIcon.Refresh}
                    type='secondary'
                    label='Refresh tracking'
                    onClick={refreshPage}
                />
            </Alert>
        );
    }

    return (
        <>
            <FormSection label='Latest update' overflow='hidden'>
                <FormGridRow columns={3}>
                    <LabeledText
                        label='Estimated date of arrival'
                        content={formatDate(lastUpdate.estimatedDateOfArrival)}
                    />
                    <LabeledText
                        label='Is late'
                        content={<BooleanBadge value={lastUpdate.isLate} onColor='red' offColor='green' />}
                    />
                    <LabeledText label='Status' content={trTrackingStatus(lastUpdate.status)} />
                </FormGridRow>
                <FormGridRow columns={3}>
                    <LabeledText label='Updated at' content={formatDateTime(lastUpdate.updatedAt)} />
                    <LabeledText label='Message' content={lastUpdate.message} />
                    <LabeledText label='Tracking location' content={lastUpdate.trackingLocation} />
                </FormGridRow>
            </FormSection>
            <FormSection label='Details' flex={1}>
                <ShipmentTrackingUpdateTable updates={tracking.updates} />
            </FormSection>
        </>
    );
};
