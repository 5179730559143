import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

import {
    assignmentsCacheKey,
    assignmentService,
    CollectAssignmentPayload,
    useAssignmentQuery,
} from '@hofy/api-admin';
import {
    AddressDto,
    AssignmentCollectionReason,
    canSendReplacement,
    customerOwnedStoreAndReuseActivationFeeFromOption,
    CustomerOwnedStoreAndReuseLocation,
    customerOwnedStoreAndReuseMonthlyFeeFromOption,
    rentalStoreAndReuseActivationFeeFromOption,
    RentalStoreAndReuseLocation,
} from '@hofy/api-shared';
import { Currency, DateString, Price, UUID, zeroPrice } from '@hofy/global';
import { isRequired, isRequiredIf, useForm, useToast, validator } from '@hofy/ui';

import { useOrganizationDetailsQuery } from '../organizations/useOrganizationDetailsQuery';
import {
    EnableStoreAndReuseCollectionFormData,
    EnableStoreAndReuseCollectionFormErrorData,
} from './useEnableStoreAndReuseCollection';

interface AssignmentCollectFormData extends EnableStoreAndReuseCollectionFormData {
    sendReplacement: boolean;
    collectionReason: AssignmentCollectionReason | null;
    collectionNotes: string;
    deliveryAddressId: UUID | null;
    collectionNotBefore: DateString | null;
    isStoreAndReuseCollection: boolean;
}

interface ValidatedAssignmentCollectFormData extends AssignmentCollectFormData {
    collectionReason: AssignmentCollectionReason;
    rentalStoreAndReuse: {
        activationFee: Price;
        collectToLocation: RentalStoreAndReuseLocation;
    } | null;
    customerOwnedStoreAndReuse: {
        activationFee: Price;
        storageMonthlyFee: Price;
        collectToLocation: CustomerOwnedStoreAndReuseLocation;
    } | null;
}

interface AssignmentCollectFormDataErrors extends EnableStoreAndReuseCollectionFormErrorData {
    collectionReason?: string;
    collectionNotes?: string;
    deliveryAddressId?: string;
}

export const useCollectAssignment = (
    assignmentId: number,
    isActiveRental: boolean,
    deliveryAddress: AddressDto,
    onSuccess: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const { data: assignmentDetails, isLoading: feesLoading } = useAssignmentQuery(assignmentId);
    const { data: orgDetails } = useOrganizationDetailsQuery(assignmentDetails?.organization.id);
    const mutation = useMutation({
        mutationFn: (v: CollectAssignmentPayload) => assignmentService.collectAssignment(assignmentId, v),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [assignmentsCacheKey],
            });
            showToast({
                type: 'positive',
                message: 'Collection requested',
            });
            onSuccess();
        },
    });

    const collectToHub = !!(
        assignmentDetails?.availableActions?.rentalStoreAndReuse?.hubFee ??
        assignmentDetails?.availableActions?.customerOwnedStoreAndReuse?.hubFee
    );

    const collectToLocalPartner = !!(
        assignmentDetails?.availableActions?.rentalStoreAndReuse?.localFee ??
        assignmentDetails?.availableActions?.customerOwnedStoreAndReuse?.localFee
    );

    const form = useForm<
        AssignmentCollectFormData,
        ValidatedAssignmentCollectFormData,
        AssignmentCollectFormDataErrors
    >({
        initial: {
            sendReplacement: false,
            collectionReason: null,
            collectionNotBefore: null,
            collectionNotes: '',
            isStoreAndReuseCollection: false,
            rentalStoreAndReuse: !isActiveRental
                ? null
                : {
                      activationFee: zeroPrice(Currency.GBP),
                      collectToLocation: collectToHub
                          ? RentalStoreAndReuseLocation.Zone1
                          : RentalStoreAndReuseLocation.Local,
                  },
            customerOwnedStoreAndReuse: isActiveRental
                ? null
                : {
                      activationFee: zeroPrice(Currency.GBP),
                      storageMonthlyFee: zeroPrice(Currency.GBP),
                      collectToLocation: collectToHub
                          ? CustomerOwnedStoreAndReuseLocation.HofyHub
                          : CustomerOwnedStoreAndReuseLocation.LocalHub,
                  },
            deliveryAddressId: deliveryAddress.uuid,
        },
        onSubmit: formData => mutation.mutate(formData),
        validate: validator<AssignmentCollectFormData, AssignmentCollectFormDataErrors>({
            collectionReason: isRequired('Collection reason is required when reason is Other'),

            collectionNotes: [
                isRequiredIf(
                    ({ collectionReason, collectionNotes }) =>
                        collectionReason === AssignmentCollectionReason.Other && isEmpty(collectionNotes),
                    'Collection notes are required',
                ),
                isRequiredIf(
                    ({ collectionNotes }) => collectionNotes !== undefined && collectionNotes.length > 500,
                    'Collection notes exceed max 500 chars',
                ),
            ],
            rentalStoreAndReuse: isRequiredIf(
                ({ isStoreAndReuseCollection, rentalStoreAndReuse }) =>
                    isStoreAndReuseCollection && isActiveRental && rentalStoreAndReuse === null,
                'Please select one of the options',
            ),
            customerOwnedStoreAndReuse: isRequiredIf(
                ({ isStoreAndReuseCollection, customerOwnedStoreAndReuse }) =>
                    isStoreAndReuseCollection && !isActiveRental && customerOwnedStoreAndReuse === null,
                'Please select one of the options',
            ),
            deliveryAddressId: isRequiredIf(
                ({ collectionReason, sendReplacement, deliveryAddressId }) =>
                    canSendReplacement(collectionReason) && sendReplacement && !deliveryAddressId,
                'Address is required when Replacement is selected',
            ),
        }),
    });

    useEffect(() => {
        if (
            !orgDetails ||
            !assignmentDetails?.availableActions ||
            (!form.values.rentalStoreAndReuse?.collectToLocation &&
                !form.values.customerOwnedStoreAndReuse?.collectToLocation)
        ) {
            return;
        }

        const noPrice = zeroPrice(orgDetails?.currency);
        const { rentalStoreAndReuse, customerOwnedStoreAndReuse } = assignmentDetails.availableActions;
        if (customerOwnedStoreAndReuse) {
            form.setValues({
                customerOwnedStoreAndReuse: {
                    ...form.values.customerOwnedStoreAndReuse!,
                    activationFee:
                        customerOwnedStoreAndReuseActivationFeeFromOption(
                            customerOwnedStoreAndReuse,
                            form.values.customerOwnedStoreAndReuse?.collectToLocation,
                        ) ?? noPrice,
                    storageMonthlyFee:
                        customerOwnedStoreAndReuseMonthlyFeeFromOption(
                            customerOwnedStoreAndReuse,
                            form.values.customerOwnedStoreAndReuse?.collectToLocation ?? undefined,
                        ) ?? noPrice,
                },
            });
        }
        if (rentalStoreAndReuse) {
            form.setValues({
                rentalStoreAndReuse: {
                    ...form.values.rentalStoreAndReuse!,
                    activationFee:
                        rentalStoreAndReuseActivationFeeFromOption(
                            rentalStoreAndReuse,
                            form.values.rentalStoreAndReuse?.collectToLocation,
                        ) ?? noPrice,
                },
            });
        }
    }, [
        orgDetails,
        assignmentDetails,
        form.values.rentalStoreAndReuse?.collectToLocation,
        form.values.customerOwnedStoreAndReuse?.collectToLocation,
    ]);

    return {
        form,
        collectToHub,
        collectToLocalPartner,
        isLoading: mutation.isPending ?? feesLoading,
        isSubmitted: mutation.isSuccess,
    };
};
