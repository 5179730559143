import React, { FC, ReactNode } from 'react';

import { UserAssignmentDto } from '@hofy/api-admin';
import {
    AddressDto,
    AssignmentStatus,
    isCollectionPending,
    isDeliveryPending,
    ShipmentType,
} from '@hofy/api-shared';
import { Box, Divider, Paragraph3 } from '@hofy/ui';

import { ShipmentPointCard } from '../../../../components/domain/shipments/ShipmentPointCard';
import { ShipmentPoint, ShipmentPointType } from '../../../../store/shipments/types/ShipmentPoint';
import { ShipmentActionButton } from './cells/ShipmentAction';

interface NoShipmentGroupProps {
    userAddress: AddressDto;
    assignments: UserAssignmentDto[];
    selectedAssignments: UserAssignmentDto[];
    onCreateShipment(t: ShipmentType): void;
    onInPersonTransfer(): void;
    children?: ReactNode;
}

export const NoShipmentGroup: FC<NoShipmentGroupProps> = ({
    userAddress,
    assignments,
    selectedAssignments,
    onCreateShipment,
    onInPersonTransfer,
    children,
}) => {
    const shipmentPoint = (status: AssignmentStatus): ShipmentPoint | null => {
        if (isDeliveryPending(status)) {
            return {
                type: ShipmentPointType.Address,
                address: assignments[0].deliveryAddress,
            };
        }
        if (isCollectionPending(status)) {
            return {
                type: ShipmentPointType.Address,
                address: userAddress,
            };
        }
        return null;
    };

    const point = shipmentPoint(assignments[0].status);
    const isDestination = isDeliveryPending(assignments[0].status);
    return (
        <>
            <Box row gap={16} height={32}>
                <Paragraph3 bold>No Shipments</Paragraph3>
                <Divider />
                {point && <ShipmentPointCard point={point} isDelivery={isDestination} />}
            </Box>

            {children}

            <Box row flex={1} justify='flex-end'>
                <ShipmentActionButton
                    assignments={assignments}
                    selectedAssignments={selectedAssignments}
                    onCreateShipment={onCreateShipment}
                    onInPersonTransfer={onInPersonTransfer}
                />
            </Box>
        </>
    );
};
