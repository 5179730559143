import React, { FC } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { ShipmentDetailsDto, useShipmentQuery } from '@hofy/api-admin';
import { ShipmentStatus } from '@hofy/api-shared';
import { BlockLoader, Slideout, SlideoutFooter } from '@hofy/common';
import { useGoBack } from '@hofy/hooks';
import { EnumRoute, IntRoute } from '@hofy/router';

import { CancelButton } from '../../components/design/button/CancelButton';
import { ShipmentSlideoutTab } from '../../store/shipments/types/ShipmentSlideoutTab';
import { NewPurchaseOrderSlideout } from '../inventoryPage/purchaseOrders/purchaseOrderCreateEditSlideout/NewPurchaseOrderSlideout';
import { BackorderShipmentSlideout } from './backorderShipmentSlideout/BackorderShipmentSlideout';
import { BookShipmentSlideout } from './bookShipmentSlideout/BookShipmentSlideout';
import { CancelShipmentSlideout } from './cancelShipmentSlideout/CancelShipmentSlideout';
import { CompleteShipmentSlideout } from './completeShipmentSlideout/CompleteShipmentSlideout';
import { TransitionStatusRoute } from './components/routing/TransitionStatusRoute';
import { ShipmentAction } from './ShipmentAction';
import { ShipmentSlideoutContent } from './ShipmentSlideoutContent';
import { ShipShipmentSlideout } from './shipShipmentSlideout/ShipShipmentSlideout';
import { UpdateShipmentSlideout } from './updateShipmentSlideout/UpdateShipmentSlideout';

interface ShipmentSlideoutRouterProps {
    base: string;
    assignmentsBase: string;
    onCloseBase: string;
}

export const ShipmentSlideoutRouter: FC<ShipmentSlideoutRouterProps> = ({
    base,
    assignmentsBase,
    onCloseBase,
}) => {
    const history = useHistory();
    const { location } = history;

    const historyPush = (path: string, query?: string) => {
        const search = location.search;
        const params =
            search && query ? `${location.search}&${query}` : query ? `?${query}` : search ? `${search}` : '';
        history.push(`${path}${params}`);
    };

    const handleTabChange = (shipmentId: number, tab: ShipmentSlideoutTab) => {
        history.replace(`${base}/${shipmentId}/${tab}${location.search}`);
    };

    const handleAction = (shipmentId: number, tab: ShipmentSlideoutTab, action: ShipmentAction) => {
        historyPush(`${base}/${shipmentId}/${tab}/${action}`);
    };

    const handleOrderClick = (orderId: number) => {
        historyPush(`${assignmentsBase}/${orderId}/details`);
    };

    const handleCreatePurchaseOrder = (shipmentId: number, tab: ShipmentSlideoutTab) => {
        historyPush(
            `${base}/${shipmentId}/${tab}/${ShipmentAction.CreatePurchaseOrder}`,
            `shipmentId=${shipmentId}`,
        );
    };

    const handleClose = () => {
        historyPush(onCloseBase ? onCloseBase : base);
    };

    return (
        <ShipmentSlideoutRoute path={`${base}/:shipmentId(\\d+)`}>
            {({ shipmentId }) => (
                <Switch>
                    <ShipmentSlideoutTabRoute path={`${base}/${shipmentId}/:activeTab`}>
                        {({ activeTab }) => (
                            <ShipmentSlideoutDetailsRouter
                                base={base}
                                shipmentId={shipmentId}
                                onClose={handleClose}
                                onAction={handleAction}
                                activeTab={activeTab}
                                onTabChange={handleTabChange}
                                onOrderClick={handleOrderClick}
                                onCreatePurchaseOrder={handleCreatePurchaseOrder}
                            />
                        )}
                    </ShipmentSlideoutTabRoute>
                    <Redirect to={`${base}/${shipmentId}/${ShipmentSlideoutTab.Details}${location.search}`} />
                </Switch>
            )}
        </ShipmentSlideoutRoute>
    );
};

interface ShipmentSlideoutDetailsRouterProps {
    base: string;
    onClose(): void;
    shipmentId: number;
    activeTab: ShipmentSlideoutTab;
    onTabChange(shipmentId: number, tab: ShipmentSlideoutTab): void;
    onAction(shipmentId: number, tab: ShipmentSlideoutTab, action: ShipmentAction): void;
    onOrderClick(orderId: number): void;
    onCreatePurchaseOrder(shipmentId: number, tab: ShipmentSlideoutTab): void;
}

const ShipmentSlideoutDetailsRouter: FC<ShipmentSlideoutDetailsRouterProps> = ({
    shipmentId,
    activeTab,
    onAction,
    onTabChange,
    onOrderClick,
    onCreatePurchaseOrder,
    onClose,
    base,
}) => {
    const { data: optionalShipment } = useShipmentQuery(shipmentId);
    const shipmentTabRoute = `${base}/:shipmentId(\\d+)/:tabName`;
    const { goBack } = useGoBack();
    const loadingContent = () => (
        <>
            <BlockLoader fullHeight flex={1} />
            <SlideoutFooter>
                <CancelButton label='Close' onClick={onClose} />
            </SlideoutFooter>
        </>
    );
    const handleClose = () => {
        onClose();
    };

    const content = (shipment: ShipmentDetailsDto) => {
        const onClose = () => goBack(`${base}/${shipment.id}`);

        return (
            <>
                <ShipmentSlideoutContent
                    activeTab={activeTab}
                    shipment={shipment}
                    onTabChange={tab => onTabChange(shipment.id, tab)}
                    onClose={handleClose}
                    onAction={a => onAction(shipmentId, activeTab, a)}
                    onCreatePurchaseOrder={onCreatePurchaseOrder}
                    onOrderClick={onOrderClick}
                />
                <Switch>
                    <Route path={`${shipmentTabRoute}/${ShipmentAction.CreatePurchaseOrder}`} exact>
                        <NewPurchaseOrderSlideout onClose={onClose} />
                    </Route>
                    <TransitionStatusRoute
                        from={shipment.status}
                        to={ShipmentStatus.Booked}
                        path={`${shipmentTabRoute}/${ShipmentAction.Book}`}
                        exact
                    >
                        <BookShipmentSlideout shipment={shipment} onClose={onClose} />
                    </TransitionStatusRoute>
                    <TransitionStatusRoute
                        from={shipment.status}
                        to={ShipmentStatus.WithCourier}
                        path={`${shipmentTabRoute}/${ShipmentAction.Ship}`}
                        exact
                    >
                        <ShipShipmentSlideout shipment={shipment} onClose={onClose} />
                    </TransitionStatusRoute>
                    <TransitionStatusRoute
                        from={shipment.status}
                        to={ShipmentStatus.Canceled}
                        path={`${shipmentTabRoute}/${ShipmentAction.Cancel}`}
                        exact
                    >
                        <CancelShipmentSlideout shipment={shipment} onClose={onClose} />
                    </TransitionStatusRoute>
                    <TransitionStatusRoute
                        from={shipment.status}
                        to={ShipmentStatus.Completed}
                        path={`${shipmentTabRoute}/${ShipmentAction.Complete}`}
                        exact
                    >
                        <CompleteShipmentSlideout shipment={shipment} onClose={onClose} />
                    </TransitionStatusRoute>
                    <TransitionStatusRoute
                        from={shipment.status}
                        to={ShipmentStatus.Backorder}
                        path={`${shipmentTabRoute}/${ShipmentAction.Backorder}`}
                        exact
                    >
                        <BackorderShipmentSlideout shipment={shipment} onClose={onClose} />
                    </TransitionStatusRoute>
                    {(shipment.status === ShipmentStatus.Created ||
                        shipment.status === ShipmentStatus.Booked ||
                        shipment.status === ShipmentStatus.Backorder ||
                        shipment.status === ShipmentStatus.WithCourier) && (
                        <Route path={`${shipmentTabRoute}/${ShipmentAction.Edit}`} exact>
                            <UpdateShipmentSlideout shipment={shipment} onClose={onClose} />
                        </Route>
                    )}
                </Switch>
            </>
        );
    };
    return (
        <Slideout width={960} onClose={handleClose} slideoutId='shipment-details'>
            {optionalShipment ? content(optionalShipment) : loadingContent()}
        </Slideout>
    );
};

const ShipmentSlideoutRoute = IntRoute('shipmentId', Route);
const ShipmentSlideoutTabRoute = EnumRoute<ShipmentSlideoutTab>('activeTab', ShipmentSlideoutTab, Route);
