import React, { FC } from 'react';

import { useAddonsQuery } from '@hofy/api-admin';
import {
    CategoryContractSettingsConfigDto,
    Permission,
    useTrAcquisitionType,
    useTrParentProductCategory,
    useTrPaymentSchema,
    useTrRentalTerm,
} from '@hofy/api-shared';
import { TeamItem } from '@hofy/core';
import { BaseTable, BooleanBadge, ErrorStatePlaceholder, IconButton, SvgIcon } from '@hofy/ui';
import { PermissionWrapper } from '@hofy/ui-domain';

interface ContractSettingsTabProps {
    contractSettings: CategoryContractSettingsConfigDto[];
    onEditContractSetting(id: number): void;
}

export const ContractSettingsTab: FC<ContractSettingsTabProps> = ({
    contractSettings,
    onEditContractSetting,
}) => {
    const trParentProductCategory = useTrParentProductCategory();
    const trRentalTerm = useTrRentalTerm();
    const trPaymentSchema = useTrPaymentSchema();
    const trAcquisitionType = useTrAcquisitionType();
    const { data: addons, isLoading, isError } = useAddonsQuery();

    if (isError) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <BaseTable
            data={contractSettings}
            toKey={item => item.id}
            isLoading={isLoading}
            columns={[
                {
                    id: 'id',
                    header: 'id',
                    width: 80,
                    flexGrow: 0,
                    renderer: ({ id }) => id,
                },
                {
                    id: 'parentProductCategory',
                    header: 'Parent product category',
                    flexGrow: 1,
                    renderer: ({ parentProductCategory }) => trParentProductCategory(parentProductCategory),
                },
                {
                    id: 'rentalTerm',
                    header: 'Rental duration',
                    flexGrow: 0,
                    width: 120,
                    renderer: ({ rentalTerm }) => (rentalTerm ? trRentalTerm(rentalTerm) : '--'),
                },
                {
                    id: 'acquisitionType',
                    header: 'Acquisition type',
                    flexGrow: 1,
                    renderer: ({ acquisitionType }) => trAcquisitionType(acquisitionType),
                },
                {
                    id: 'addonName',
                    header: 'Addon name',
                    flexGrow: 1,
                    renderer: ({ serviceBundleId }) =>
                        (serviceBundleId && addons.find(addon => addon.id === serviceBundleId)?.name) || '--',
                },
                {
                    id: 'extendedWarranty',
                    header: 'Extended warranty',
                    flexGrow: 0,
                    width: 140,
                    renderer: ({ extendedWarranty }) => <BooleanBadge value={extendedWarranty} />,
                },
                {
                    id: 'equipmentPaymentSchema',
                    header: 'Equipment billing frequency',
                    flexGrow: 1,
                    renderer: ({ paymentSchema }) => (paymentSchema ? trPaymentSchema(paymentSchema) : '--'),
                },
                {
                    id: 'servicePaymentSchema',
                    header: 'Service billing frequency',
                    flexGrow: 1,
                    renderer: ({ servicePaymentSchema }) =>
                        servicePaymentSchema ? trPaymentSchema(servicePaymentSchema) : '--',
                },
                {
                    id: 'warrantyPaymentSchema',
                    header: 'Warranty billing frequency',
                    flexGrow: 1,
                    renderer: ({ warrantyPaymentSchema }) =>
                        warrantyPaymentSchema ? trPaymentSchema(warrantyPaymentSchema) : '--',
                },
                {
                    id: 'team',
                    header: 'Team',
                    flexGrow: 0,
                    width: 120,
                    renderer: item => (item.team ? <TeamItem team={item.team} /> : '--'),
                },
                {
                    id: 'edit',
                    header: '',
                    width: 20,
                    flexGrow: 0,
                    renderer: item => (
                        <PermissionWrapper permission={Permission.AdminOrganizationUpdate}>
                            <IconButton
                                disabled={!!item.team}
                                icon={SvgIcon.Edit}
                                onClick={() => onEditContractSetting(item.id)}
                            />
                        </PermissionWrapper>
                    ),
                },
            ]}
        />
    );
};
