import React, { FC, MouseEvent, useEffect, useState } from 'react';

import {
    allRentalTerms,
    OrganizationDiscountDto,
    Permission,
    useTrParentProductCategory,
    useTrProductCategory,
    useTrRentalTerm,
    visibleParentProductCategories,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { FormDropdown, FormFractionInput, FormNumberInput } from '@hofy/common';
import { formatDate } from '@hofy/helpers';
import { useDecimal } from '@hofy/hooks';
import {
    Box,
    Button,
    ConfirmModal,
    FormDateInput,
    IconButton,
    Modals,
    Placeholder,
    SortFilterDataTable,
    SvgIcon,
    SvgIllustration,
} from '@hofy/ui';

import { Footer } from '../../../components/design/layout/Footer';
import { OrganizationDetailsDto } from '../../../services/organizations/types/OrganizationDto';
import { useDeleteOrganizationDiscount } from '../../../store/organizations/useDeleteOrganizationDiscount';
import { useUpdateOrganizationDiscount } from '../../../store/organizations/useUpdateOrganizationDiscount';

interface DiscountsTabProps {
    organization: OrganizationDetailsDto;
}

export const DiscountsTab: FC<DiscountsTabProps> = ({ organization }) => {
    const trRentalTerm = useTrRentalTerm();
    const trProductCategory = useTrProductCategory();
    const trParentProductCategory = useTrParentProductCategory();
    const { formatPercent } = useDecimal();
    const { hasPermission } = useSession();

    const [discountRows, setDiscountRows] = useState<OrganizationDiscountDto[]>([]);
    const [editedRow, setEditedRow] = useState<OrganizationDiscountDto>();
    const [deleteCandidateId, setDeleteCandidateId] = useState<number>();

    useEffect(() => {
        setDiscountRows(organization.discounts);
    }, [organization.discounts]);

    const onUpdateSuccess = () => {
        setEditedRow(undefined);
    };

    const { deleteDiscount } = useDeleteOrganizationDiscount(organization.id);
    const { form, isLoadingMutation } = useUpdateOrganizationDiscount(
        organization.id,
        editedRow,
        onUpdateSuccess,
    );

    const onRowClick = (r: OrganizationDiscountDto) => {
        if (
            hasPermission(Permission.AdminOrganizationUpdateFinancialSettings) &&
            !organization.unbundlingEnabled
        ) {
            setEditedRow(r);
        }
    };

    const handleDeleteClick = (e: MouseEvent<HTMLDivElement>, entry: OrganizationDiscountDto) => {
        e.stopPropagation();
        setDeleteCandidateId(entry.id);
    };

    const onAddRow = () => {
        const newRows = [
            ...discountRows,
            {
                id: 0,
                organizationId: organization.id,
                discountPercent: '0.00',
                productCategory: null,
                parentProductCategory: null,
                rentalTerm: null,
                precedence: 0,
                validStartOn: null,
                validEndOn: null,
            },
        ];
        setDiscountRows(newRows);
        setEditedRow(newRows[newRows.length - 1]);
    };

    const deleteDiscountHandler = () => {
        if (deleteCandidateId !== undefined) {
            deleteDiscount(deleteCandidateId);
        }
        setEditedRow(undefined);
    };
    const cancelHandler = () => {
        setDiscountRows(discountRows.filter(r => !!r.id));
        setEditedRow(undefined);
    };

    return (
        <Box column fullHeight>
            <SortFilterDataTable
                data={discountRows}
                toKey={entry => entry.id}
                width='100%'
                flex='auto'
                defaultSort={{
                    sortBy: 'precedence',
                    sortDirection: 'DESC',
                }}
                onRowClick={onRowClick}
                emptyContent={
                    <Placeholder
                        illustration={SvgIllustration.FinanceSearch}
                        title='No discounts'
                        message='No custom discounts for this organization'
                    />
                }
                columns={[
                    {
                        id: 'discount_percent',
                        header: '% Discount',
                        flexGrow: 1,
                        renderer: entry => {
                            if (entry.id === editedRow?.id) {
                                return (
                                    <FormFractionInput
                                        unit='%'
                                        value={form.values.discountPercent}
                                        onChange={discountPercent =>
                                            form.setValues({
                                                discountPercent,
                                            })
                                        }
                                        isError={!!form.errors.discountPercent}
                                        errorMessage={form.errors.discountPercent}
                                    />
                                );
                            } else {
                                return formatPercent(entry.discountPercent);
                            }
                        },
                        comparatorBy: entry => entry.discountPercent,
                    },
                    {
                        id: 'category',
                        header: 'Product category',
                        flexGrow: 2,
                        renderer: entry => {
                            if (entry.id === editedRow?.id) {
                                return (
                                    <FormDropdown
                                        emptyContent='Category'
                                        items={visibleParentProductCategories}
                                        labelFormatter={trParentProductCategory}
                                        onChange={productCategory =>
                                            form.setValues({
                                                productCategory,
                                            })
                                        }
                                        value={form.values.productCategory}
                                    />
                                );
                            } else {
                                if (entry.parentProductCategory) {
                                    return trParentProductCategory(entry.parentProductCategory);
                                }
                                return entry.productCategory
                                    ? trProductCategory(entry.productCategory)
                                    : '--';
                            }
                        },
                    },
                    {
                        id: 'rental_term',
                        header: 'Rental term',
                        flexGrow: 1,
                        renderer: entry => {
                            if (entry.id === editedRow?.id) {
                                return (
                                    <FormDropdown
                                        emptyContent='Rental term'
                                        items={allRentalTerms}
                                        labelFormatter={trRentalTerm}
                                        onChange={rentalTerm =>
                                            form.setValues({
                                                rentalTerm,
                                            })
                                        }
                                        value={form.values.rentalTerm}
                                    />
                                );
                            } else {
                                return entry.rentalTerm ? trRentalTerm(entry.rentalTerm) : '--';
                            }
                        },
                    },
                    {
                        id: 'precedence',
                        header: 'Precedence',
                        renderer: entry => {
                            if (entry.id === editedRow?.id) {
                                return (
                                    <FormNumberInput
                                        label=''
                                        value={form.values.precedence}
                                        onChange={precedence =>
                                            form.setValues({
                                                precedence,
                                            })
                                        }
                                        isError={!!form.errors.precedence}
                                        errorMessage={form.errors.precedence}
                                    />
                                );
                            } else {
                                return entry.precedence;
                            }
                        },
                        comparatorBy: entry => entry.precedence,
                    },
                    {
                        id: 'valid_starts_on',
                        header: 'Starts on',
                        flexGrow: 1,
                        renderer: entry => {
                            if (entry.id === editedRow?.id) {
                                return <FormDateInput label='' api={form.fields.validStartOn} nullable />;
                            } else {
                                return formatDate(entry.validStartOn);
                            }
                        },
                    },
                    {
                        id: 'valid_end_on',
                        header: 'Ends on',
                        flexGrow: 1,
                        renderer: entry => {
                            if (entry.id === editedRow?.id) {
                                return (
                                    <FormDateInput label='' api={form.fields.validEndOn} nullable flex={1} />
                                );
                            } else {
                                return formatDate(entry.validEndOn);
                            }
                        },
                    },
                    {
                        id: 'delete',
                        header: '',
                        flexShrink: 0,
                        width: 30,
                        flexGrow: 0,
                        renderer: entry => {
                            return hasPermission(Permission.AdminOrganizationUpdateFinancialSettings) ? (
                                <Box flex={1} row justify='flex-end'>
                                    <IconButton
                                        disabled={organization.unbundlingEnabled}
                                        icon={SvgIcon.Trash}
                                        onClick={e => handleDeleteClick(e, entry)}
                                    />
                                </Box>
                            ) : null;
                        },
                    },
                ]}
            />
            {hasPermission(Permission.AdminOrganizationUpdateFinancialSettings) && (
                <Footer justify='flex-end'>
                    {editedRow !== undefined && (
                        <>
                            <Button
                                type='secondary'
                                width={100}
                                marginRight={20}
                                label='Cancel'
                                onClick={cancelHandler}
                            />
                            <Button
                                width={100}
                                label='Save'
                                disabled={isLoadingMutation}
                                onClick={() => {
                                    form.submit();
                                }}
                            />
                        </>
                    )}
                    {editedRow === undefined && (
                        <Button
                            leftIcon={SvgIcon.Add}
                            label='Add discount'
                            onClick={onAddRow}
                            disabled={organization.unbundlingEnabled}
                        />
                    )}

                    <Modals>
                        {!!deleteCandidateId && (
                            <ConfirmModal
                                onClose={() => setDeleteCandidateId(undefined)}
                                onConfirm={deleteDiscountHandler}
                                keyPrefix='delete-organization-discounts-modal'
                            />
                        )}
                    </Modals>
                </Footer>
            )}
        </Box>
    );
};
