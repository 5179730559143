import { useEffect, useState } from 'react';

import { HofyWarehouseDetailsDto, useHofyWarehousesQuery } from '@hofy/api-admin';
import { useLocalStorage } from '@hofy/hooks';

const warehouseKey = 'hofy-admin:warehouse';

export const useHofyWarehouse = (): [
    HofyWarehouseDetailsDto | null,
    (w: HofyWarehouseDetailsDto | undefined) => void,
] => {
    const { data: warehouses } = useHofyWarehousesQuery();
    const [warehouseId, setWarehouseId] = useLocalStorage<number | null>(warehouseKey, null);
    const [warehouse, setWarehouse] = useState<HofyWarehouseDetailsDto | null>(null);

    useEffect(() => {
        if (warehouses.length === 0) {
            return;
        }

        const matchingWarehouse = warehouseId && warehouses.find(({ id }) => id === warehouseId);
        if (matchingWarehouse) {
            setWarehouse(matchingWarehouse);
        } else {
            // fallback to the first warehouse on the list if nothing was selected
            setWarehouse(warehouses[0]);
        }
    }, [warehouses, warehouseId]);

    const setWarehouseState = (warehouse: HofyWarehouseDetailsDto | undefined) => {
        setWarehouseId(warehouse ? warehouse.id : null);
    };

    return [warehouse, setWarehouseState];
};
