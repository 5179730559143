import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';

import { defaultInvoiceGroups, emptyInvoiceGroupPayload, InvoiceGroupPayload } from '@hofy/api-admin';
import {
    allInvoiceEntryTypes,
    allowedSplitterTypes,
    canCreateMultiple,
    Permission,
    SplitterType,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MultiSelectDropdown, SeparatedContainer } from '@hofy/common';
import { Color } from '@hofy/theme';
import {
    Alert,
    Box,
    Button,
    FormGridRow,
    Icon,
    IconButton,
    LabeledNumberInput,
    LabeledSelect,
    LabeledText,
    Modals,
    SvgIcon,
    TooltipIcon,
} from '@hofy/ui';

import { Footer } from '../../../components/design/layout/Footer';
import { OrganizationDetailsDto } from '../../../services/organizations/types/OrganizationDto';
import { useTrInvoiceEntryType } from '../../../store/invoiceEntries/useTrInvoiceEntryType';
import {
    UpdateInvoiceGroupForm,
    useAdminUpdateInvoiceGroups,
} from '../../../store/organizations/useAdminUpdateInvoiceGroups';
import { useTrSplitterType } from '../../../store/organizations/useTrSplitterType';
import { InvoiceGroupSampleModal } from './InvoiceGroupExampleModal';

interface InvoiceGroupsTabProps {
    organization: OrganizationDetailsDto;
}

export const InvoiceGroupsTab: FC<InvoiceGroupsTabProps> = ({ organization }) => {
    const { hasPermission } = useSession();

    const canEdit = hasPermission(Permission.AdminOrganizationUpdateFinancialSettings);
    const onSaveSuccess = () => setEditedRow(undefined);

    const { form, addItem, deleteItem, setItemAt } = useAdminUpdateInvoiceGroups(organization, onSaveSuccess);
    const [editedRow, setEditedRow] = useState<number>();

    const onRowClick = (index: number) => {
        setEditedRow(index);
    };
    const handleCancel = () => {
        setEditedRow(undefined);
    };

    const [exampleModal, setExampleModal] = useState(false);

    return (
        <>
            <Box fullHeight column flex='auto'>
                <Alert
                    margin={16}
                    type='informative'
                    description='The invoice groups will be applied in the below order. The first 3 groups are predefined for all organizations and cannot be changed. Custom groups are ordered by precedence (highest first).'
                />
                <Box flex='auto' overflow='auto' paddingVertical={30}>
                    <SeparatedContainer flex={1} lined orientation='vertical' spacing={16}>
                        {defaultInvoiceGroups.map((group, index) => (
                            <ItemContent key={index} item={group} isDefault />
                        ))}
                        {form.values.invoiceGroups.map((_, index) => (
                            <InvoiceGroupForm
                                index={index}
                                key={index}
                                form={form}
                                isEdit={editedRow === index}
                                onSetItem={c => setItemAt(index, c)}
                                onDeleteItem={() => {
                                    deleteItem(index);
                                    setEditedRow(undefined);
                                }}
                                onClick={() => onRowClick(index)}
                            />
                        ))}
                    </SeparatedContainer>
                </Box>
                {canEdit && (
                    <Footer justify='flex-end'>
                        {editedRow !== undefined && (
                            <>
                                <Button
                                    type='secondary'
                                    marginRight={20}
                                    label='Cancel'
                                    onClick={handleCancel}
                                />
                                <Button label='Save' onClick={form.submit} />
                            </>
                        )}
                        {editedRow === undefined && (
                            <Box gap={16}>
                                <Button
                                    type='secondary'
                                    label='Show example grouping'
                                    onClick={() => setExampleModal(true)}
                                />
                                <Button leftIcon={SvgIcon.Add} label='Add item' onClick={addItem} />
                            </Box>
                        )}
                    </Footer>
                )}
            </Box>
            <Modals>
                {exampleModal && (
                    <InvoiceGroupSampleModal
                        customGroups={form.values.invoiceGroups}
                        onClose={() => setExampleModal(false)}
                    />
                )}
            </Modals>
        </>
    );
};

interface InvoiceGroupFormProps {
    index: number;
    form: UpdateInvoiceGroupForm;
    onSetItem(p: Partial<InvoiceGroupPayload>): void;
    onDeleteItem(): void;
    onClick(): void;
    isEdit: boolean;
}

const InvoiceGroupForm: FC<InvoiceGroupFormProps> = ({
    index,
    form,
    onSetItem,
    onDeleteItem,
    isEdit,
    onClick,
}) => {
    const entry = form.values.invoiceGroups[index];
    const trType = useTrSplitterType();

    const availableSplitterTypes = useMemo(
        () =>
            allowedSplitterTypes.filter(splitterType => {
                if (canCreateMultiple(splitterType)) {
                    return true;
                }
                const currentGroups = form.values.invoiceGroups || [];
                return !currentGroups.some(group => group.config.type === splitterType);
            }),
        [form.values.invoiceGroups],
    );

    if (isEdit && index >= 0) {
        return (
            <Box row marginHorizontal={40}>
                <Box flex={1}>
                    <FormGridRow columns={6}>
                        <LabeledNumberInput
                            label='Precedence'
                            value={entry.precedence}
                            onChange={precedence => onSetItem({ precedence })}
                        />
                        <LabeledSelect
                            label='Type'
                            options={availableSplitterTypes}
                            toText={trType}
                            value={entry.config.type}
                            onChange={t =>
                                onSetItem({
                                    config: emptyInvoiceGroupPayload(t).config,
                                })
                            }
                        />
                        <ItemFormContent item={entry} onSetItem={onSetItem} />
                    </FormGridRow>
                </Box>
                <IconButton icon={SvgIcon.Trash} onClick={onDeleteItem} disabled={index === -1} />
            </Box>
        );
    }

    return <ItemContent item={entry} onClick={onClick} />;
};

interface ItemFormContentProps {
    item: InvoiceGroupPayload;
    onSetItem(p: Partial<InvoiceGroupPayload>): void;
}

const ItemFormContent: FC<ItemFormContentProps> = ({ item, onSetItem }) => {
    const trInvoiceEntryType = useTrInvoiceEntryType();
    const config = item.config;

    if (config.type !== SplitterType.ByInvoiceEntryType) {
        return null;
    }
    return (
        <MultiSelectDropdown
            label='Invoice entry types'
            items={allInvoiceEntryTypes}
            labelFormatter={trInvoiceEntryType}
            value={config.byInvoiceEntryType.types}
            onChange={types =>
                onSetItem({
                    config: {
                        ...config,
                        byInvoiceEntryType: {
                            types,
                        },
                    },
                })
            }
        />
    );
};

const hoverStyles = `
    cursor: pointer;
    :hover {
        background: ${Color.BackgroundSubtleNeutral};
    }
`;

const HoverableBox = styled(Box)(({ onClick }) => {
    if (!onClick) {
        return;
    }
    return hoverStyles;
});

interface ItemContentProps {
    item: InvoiceGroupPayload;
    isDefault?: boolean;
    onClick?(): void;
}

const ItemContent: FC<ItemContentProps> = ({ item, isDefault, onClick }) => {
    const trInvoiceEntryType = useTrInvoiceEntryType();
    const trType = useTrSplitterType();

    const content = () => {
        const config = item.config;
        if (config.type !== SplitterType.ByInvoiceEntryType) {
            return null;
        }
        return (
            <LabeledText
                label='Invoice entry types'
                content={config.byInvoiceEntryType.types.map(i => trInvoiceEntryType(i)).join(', ') || '--'}
            />
        );
    };
    return (
        <HoverableBox row padding={8} marginHorizontal={24} onClick={onClick}>
            <Box flex={1}>
                <FormGridRow columns={6}>
                    <LabeledText label='Precedence' content={isDefault ? '--' : item.precedence} />
                    <LabeledText label='Type' content={trType(item.config.type)} />
                    {content()}
                </FormGridRow>
            </Box>
            {isDefault ? (
                <TooltipIcon body='This group cannot be changed.' icon={SvgIcon.Lock} />
            ) : (
                <Icon svg={SvgIcon.Edit} />
            )}
        </HoverableBox>
    );
};
