import React, { FC } from 'react';

import { Modals } from '@hofy/ui';

import { ProductDetailsTab } from '../../../store/products/types/ProductDetailsTab';
import { useUpdateProduct } from '../../../store/products/useUpdateProduct';
import { ProductDetails } from '../components/ProductDetails';
import { ReplaceProductModal } from '../replaceProductModal/ReplaceProductModal';

interface UpdateProductOverlayProps {
    tab: ProductDetailsTab;
    productId: number;
    onChangeTab(v: ProductDetailsTab): void;
}

export const UpdateProductOverlay: FC<UpdateProductOverlayProps> = ({ productId, tab, onChangeTab }) => {
    const {
        form,
        product,
        isLoadingMutation,
        lockVariantPrices,
        setLockVariantPrices,
        needReplacement,
        replaceProduct,
        forceReplacementProduct,
        setNeedReplacement,
    } = useUpdateProduct(productId);

    if (!product) {
        return null;
    }

    return (
        <>
            <ProductDetails
                tab={tab}
                onTabChange={onChangeTab}
                form={form}
                title={product.name}
                id={product.id}
                product={product}
                isLoading={isLoadingMutation}
                lockVariantPrices={lockVariantPrices}
                onLockVariantPricesChange={setLockVariantPrices}
            />
            <Modals>
                {needReplacement && (
                    <ReplaceProductModal
                        product={product}
                        onReplace={replaceProduct}
                        onForceReplacement={forceReplacementProduct}
                        sendNotificationConfig={form.values.sendNotification}
                        onCancel={() => setNeedReplacement(false)}
                    />
                )}
            </Modals>
        </>
    );
};
