import { useMutation, useQueryClient } from '@tanstack/react-query';

import { billingEntitiesService } from '@hofy/api-admin';
import { Currency } from '@hofy/global';
import { useToast } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

export const useAdminUpdateBillingEntityCurrency = (billingEntityId: number, onSuccess: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (currency: Currency) =>
            billingEntitiesService.updateBillingEntityCurrency(billingEntityId, currency),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Currency updated',
            });
            onSuccess();
        },
    });

    const updateCurrency = (currency: Currency) => {
        mutation.mutate(currency);
    };

    return {
        updateCurrency,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
