import { addonErrorI18n } from './addon/i18n/addonErrorI18n';
import { assignmentErrorI18n } from './assignment/i18n/assignmentErrorI18n';
import { betaFeatureErrorI18n } from './betaFeature/i18n/betaFeatureErrorI18n';
import { collectionRequestErrorI18n } from './collectionRequest/i18n/collectionRequestErrorI18n';
import { contractErrorI18n } from './contract/i18n/contractErrorI18n';
import { contractFilterTypeI18n } from './contract/i18n/contractFilterTypeI18n';
import { contractTabI18n } from './contract/i18n/contractTabI18n';
import { storeAndReuseStatusFilterI18n } from './contract/i18n/storeAndReuseStatusFilterI18n';
import { contractSettingErrorI18n } from './contractSetting/i18n/contractSettingErrorI18n';
import { dataErasureErrorI18n } from './dataErasure/i18n/dataErasureErrorI18n';
import { exchangeRateErrorI18n } from './exchangeRate/i18n/exchangeRateErrorI18n';
import { fulfilmentCountrySettingErrorI18n } from './fulfilmentCountrySetting/i18n/fulfilmentCountrySettingErrorI18n';
import { hostErrorI18n } from './host/i18n/hostErrorI18n';
import { hrisErrorI18n } from './hris/i18n/hrisErrorI18n';
import { inspectionErrorI18n } from './inspection/i18n/inspectionErrorI18n';
import { invoiceErrorI18n } from './invoice/i18n/invoiceErrorI18n';
import { issueErrorI18n } from './issue/i18n/issueErrorI18n';
import { itemErrorI18n } from './item/i18n/itemErrorI18n';
import { jobStatusI18n } from './job/i18n/jobStatusI18n';
import { journalErrorI18n } from './journal/i18n/journalErrorI18n';
import { messagingErrorI18n } from './messaging/i18n/messagingErrorI18n';
import { orderErrorI18n } from './order/i18n/orderErrorI18n';
import { organizationErrorI18n } from './organization/i18n/organizationErrorI18n';
import { addonConfigInvalidReasonI18n } from './organizationAddonConfig/I18n/addonConfigInvalidReasonI18n';
import { paymentErrorI18n } from './payment/i18n/paymentErrorI18n';
import { productErrorI18n } from './product/i18n/productErrorI18n';
import { purchaseOrderErrorI18n } from './purchaseOrder/i18n/purchaseOrderErrorI18n';
import { repairErrorI18n } from './repair/i18n/repairErrorI18n';
import { repaymentPlanErrorI18n } from './repaymentPlan/i18n/repaymentPlanErrorI18n';
import { salesOrderErrorI18n } from './salesOrder/i18n/salesOrderErrorI18n';
import { shipmentErrorI18n } from './shipment/i18n/shipmentErrorI18n';
import { shipmentTrackingErrorI18n } from './shipmentTracking/i18n/shipmentTrackingErrorI18n';
import { subscriptionBillingFrequencyI18n } from './subscription/i18n/subscriptionBillingFrequencyI18n';
import { subscriptionErrorI18n } from './subscription/i18n/subscriptionErrorI18n';
import { subscriptionTermI18n } from './subscription/i18n/subscriptionTermI18n';
import { teamErrorI18n } from './team/i18n/teamErrorI18n';
import { userErrorI18n } from './user/i18n/userErrorI18n';
import { warehouseErrorI18n } from './warehouse/i18n/warehouseErrorI18n';
import { zendeskErrorI18n } from './zendesk/i18n/zendeskErrorI18n';

export const adminApiI18n = {
    ...addonErrorI18n,
    ...assignmentErrorI18n,
    ...betaFeatureErrorI18n,
    ...collectionRequestErrorI18n,
    ...contractErrorI18n,
    ...contractFilterTypeI18n,
    ...contractTabI18n,
    ...storeAndReuseStatusFilterI18n,
    ...contractSettingErrorI18n,
    ...dataErasureErrorI18n,
    ...exchangeRateErrorI18n,
    ...fulfilmentCountrySettingErrorI18n,
    ...hostErrorI18n,
    ...hrisErrorI18n,
    ...inspectionErrorI18n,
    ...invoiceErrorI18n,
    ...issueErrorI18n,
    ...itemErrorI18n,
    ...jobStatusI18n,
    ...journalErrorI18n,
    ...messagingErrorI18n,
    ...orderErrorI18n,
    ...organizationErrorI18n,
    ...addonConfigInvalidReasonI18n,
    ...paymentErrorI18n,
    ...productErrorI18n,
    ...purchaseOrderErrorI18n,
    ...repairErrorI18n,
    ...repaymentPlanErrorI18n,
    ...salesOrderErrorI18n,
    ...shipmentErrorI18n,
    ...shipmentTrackingErrorI18n,
    ...subscriptionBillingFrequencyI18n,
    ...subscriptionErrorI18n,
    ...subscriptionTermI18n,
    ...teamErrorI18n,
    ...userErrorI18n,
    ...warehouseErrorI18n,
    ...zendeskErrorI18n,
};

export type AdminApiI18nKey = keyof typeof adminApiI18n;
