import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { TermAndConditionDto } from '@hofy/api-admin';
import { errorMap, nowISODate } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import { AdminAddOrganizationTermAndConditionFormData } from './types/AdminAddOrganizationTermAndConditionFormData';
import { organizationTermAndConditionsCacheKey } from './useOrganizationTermAndConditionsQuery';

export const useAdminAddOrganizationTermAndCondition = (
    organizationId: number,
    defaultTermAndCondition: TermAndConditionDto,
    onEnd: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: adminOrganizationsService.addOrganizationTermAndCondition,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [organizationTermAndConditionsCacheKey],
            });
            showToast({
                type: 'positive',
                message: 'Term and Condition has been added',
            });
            onEnd();
        },
    });

    const form = useForm<AdminAddOrganizationTermAndConditionFormData>({
        initial: {
            organizationId,
            termAndCondition: defaultTermAndCondition,
            startOn: nowISODate(),
        },
        onSubmit: mutation.mutate,
        onDiscard: onEnd,
        validate: ({ startOn }) => ({
            startOn: errorMap([isEmpty(startOn), 'Term and Condition Start date is required']),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
