import { ParentProductCategory, ProductCategory, RentalTerm } from '@hofy/api-shared';
import { DateString, Percent } from '@hofy/global';

export interface OrganizationDiscountFormData {
    id: number | null;
    discountPercent: Percent;
    productCategory: ParentProductCategory | ProductCategory | null;
    rentalTerm: RentalTerm | null;
    precedence: number;
    validStartOn: DateString | null;
    validEndOn: DateString | null;
}

export const emptyOrganizationDiscountFormData: OrganizationDiscountFormData = {
    id: 0,
    discountPercent: '0.00',
    productCategory: null,
    rentalTerm: null,
    precedence: 0,
    validStartOn: null,
    validEndOn: null,
};
