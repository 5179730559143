import React, { FC, useMemo, useState } from 'react';

import { ContractDetailsDtoWithRental, RentalSubContractDto } from '@hofy/api-admin';
import { ContractType, Permission, RentalContractStatus, RentalContractType } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { Overlay } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Box, Button, ConfirmModal, Link, Modals } from '@hofy/ui';

import { PartOfSalesOrderModal } from '../../../components/domain/salesOrders/PartOfSalesOrderModal';
import { getSalesOrderLink } from '../../../components/routing/adminLinks';
import { ContractTab, rentalContractTabs } from '../../../store/contracts/types/ContractTab';
import { useCreditContract } from '../../../store/contracts/useCreditContract';
import { useRevertContractEnd } from '../../../store/contracts/useRevertContractEnd';
import { AssignmentsTable } from '../../itemsPage/AssignmentsTable';
import { ActivateContractModal } from '../activateContractModal/ActivateContractModal';
import { CancelContractModal } from '../cancelContractModal/CancelContractModal';
import { EndContractModal } from '../endContractModal/EndContractModal';
import { RolloverContractModal } from '../rolloverContractModal/RolloverContractModal';
import { AddonsTable } from './AddonsTable';
import { CommonContractHeader } from './components/CommonContractHeader';
import { ContractInvoiceEntriesTab } from './ContractInvoiceEntriesTab';
import { ContractScheduleTab } from './ContractScheduleTab';
import { RentalContractDetailsTab } from './RentalContractDetailsTab';
import { RentalSubContractsTable } from './RentalSubContractsTable';
import { RepaymentPlansTab } from './RepaymentPlansTab';
import { StorageAssignmentsTable } from './StorageAssignmentsTable';

interface RentalDetailsProps {
    tab: ContractTab;
    contract: ContractDetailsDtoWithRental;
    onChangeTab(t: ContractTab): void;
    onOpenAddSlideout(organizationId: number): void;
    onInvoiceEntryClick(entryId: number): void;
    onEdit(contractId: number): void;
}

enum ContractModal {
    EndModal,
    RolloverModal,
    CancelModal,
    ActivateModal,
    CreditModal,
    RevertContractEnd,
    PartOfSalesOrderModal,
}

export const RentalDetails: FC<RentalDetailsProps> = ({
    tab,
    contract,
    onChangeTab,
    onOpenAddSlideout,
    onInvoiceEntryClick,
    onEdit,
}) => {
    const { hasPermission } = useSession();
    const addons = useMemo(() => {
        if (!contract) {
            return [];
        }
        return contract.rentalContract.rentalContracts.flatMap(subContract => subContract.addons);
    }, [contract]);
    const mainRentalContract = useMemo(
        () => contract.rentalContract.rentalContracts.find(v => v.isMain),
        [contract],
    );
    const assignments = useMemo(() => (contract ? contract.assignments : []), [contract]);
    const billingSchedule = useMemo(
        () => contract.rentalContract.rentalContracts.flatMap(v => v.billingSchedule),
        [contract],
    );
    const [currentModal, setCurrentModal] = useState<ContractModal>();
    const closeModal = () => setCurrentModal(undefined);
    const storageAssignments = contract.managementContract?.storageAssignments ?? [];
    const [includeDeletedLines, setIncludeDeletedLines] = useState(false);
    const renderContent = () => {
        switch (tab) {
            case ContractTab.Details:
                return <RentalContractDetailsTab contract={contract} mainSubContract={mainRentalContract!} />;
            case ContractTab.Schedule:
                return <ContractScheduleTab schedule={billingSchedule} />;
            case ContractTab.InvoiceEntries:
                return (
                    <ContractInvoiceEntriesTab
                        contractId={contract.id}
                        includeDeleted={includeDeletedLines}
                        onRowClick={entry => onInvoiceEntryClick(entry.id)}
                    />
                );
            case ContractTab.Assignments:
                return <AssignmentsTable maxWidth={2000} assignments={assignments} inSlideout={false} />;
            case ContractTab.RentalContracts:
                return <RentalSubContractsTable contracts={contract.rentalContract.rentalContracts} />;

            case ContractTab.Addons:
                return <AddonsTable addons={addons} />;
            case ContractTab.RepaymentPlans:
                return <RepaymentPlansTab contractId={contract.id} />;
            case ContractTab.StorageAssignments:
                return <StorageAssignmentsTable storageAssignments={storageAssignments} />;
        }
    };

    const hasStatusAndPermission = (permission: Permission, ...status: RentalContractStatus[]) => {
        return hasPermission(permission) && status.includes(mainRentalContract?.status!);
    };
    const canEndRental = hasStatusAndPermission(Permission.AdminContractsEnd, RentalContractStatus.Active);
    const canEditRental =
        (hasStatusAndPermission(
            Permission.AdminContractsUpdate,
            RentalContractStatus.Pending,
            RentalContractStatus.Active,
        ) ||
            hasStatusAndPermission(Permission.AdminContractsUpdatePending, RentalContractStatus.Pending)) &&
        !contract?.isPartner;
    const canActivateContract = hasStatusAndPermission(
        Permission.AdminContractsActivate,
        RentalContractStatus.Pending,
        RentalContractStatus.Cancelled,
    );
    const canRolloverContract =
        hasStatusAndPermission(Permission.AdminContractsRollover, RentalContractStatus.Active) &&
        mainRentalContract?.rentalType === RentalContractType.Rental;

    const canCancelRental = hasStatusAndPermission(
        Permission.AdminContractsCancel,
        RentalContractStatus.Pending,
        RentalContractStatus.Active,
    );
    const canEditEntries = hasStatusAndPermission(
        Permission.AdminInvoicesEntriesCreate,
        RentalContractStatus.Pending,
        RentalContractStatus.Active,
        RentalContractStatus.Ended,
    );

    const canRevertEndContract =
        hasPermission(Permission.AdminContractsRevertEnd) &&
        contract &&
        mainRentalContract?.status === RentalContractStatus.Ended;

    if (!contract) {
        return null;
    }

    const menuOptions = [
        {
            action: () => {
                setCurrentModal(
                    mainRentalContract?.status === RentalContractStatus.Pending && contract.salesOrderId
                        ? ContractModal.PartOfSalesOrderModal
                        : ContractModal.CancelModal,
                );
            },
            label: 'Cancel',
            visible: canCancelRental,
        },
        {
            action: () => setCurrentModal(ContractModal.ActivateModal),
            label: 'Activate',
            visible: canActivateContract,
        },
        {
            action: () => onEdit(contract.id),
            label: 'Edit',
            visible: canEditRental,
        },
        {
            action: () => setCurrentModal(ContractModal.CreditModal),
            label: 'Credit',
            visible: canEditEntries,
        },
        // TODO HOF-11101 Add possibility to update contract expiry settings
        {
            action: () => setCurrentModal(ContractModal.RevertContractEnd),
            label: 'Revert end of contract',
            visible: canRevertEndContract,
        },
    ];

    const filteredTabs = rentalContractTabs.filter(
        tab => contract.managementContract !== null || tab !== ContractTab.StorageAssignments,
    );

    return (
        <Overlay column flex='auto' bg={Color.BackgroundDefault}>
            <CommonContractHeader
                contractTitle={`Rental #${contract.id}`}
                contractId={contract.id}
                organizationId={contract.organization.id}
                tab={tab}
                tabs={filteredTabs}
                onChangeTab={onChangeTab}
                onOpenAddInvoiceEntrySlideout={onOpenAddSlideout}
                menuOptions={menuOptions}
                onSetIncludeDeleted={setIncludeDeletedLines}
                includeDeleted={includeDeletedLines}
                optionButtons={
                    <>
                        {canEndRental && (
                            <Button
                                type='secondary'
                                label='End contract'
                                onClick={() => setCurrentModal(ContractModal.EndModal)}
                            />
                        )}
                        {canRolloverContract && (
                            <Button
                                type='secondary'
                                label='Rollover contract'
                                onClick={() => setCurrentModal(ContractModal.RolloverModal)}
                            />
                        )}
                    </>
                }
            />
            <Box flex='auto' relative>
                {renderContent()}
            </Box>
            <Modals>
                {mainRentalContract && (
                    <OptionalModalRender
                        currentModal={currentModal}
                        contract={contract}
                        rentalContract={mainRentalContract}
                        closeModal={closeModal}
                    />
                )}
            </Modals>
        </Overlay>
    );
};

const OptionalModalRender: FC<{
    currentModal: ContractModal | undefined;
    contract: ContractDetailsDtoWithRental;
    rentalContract: RentalSubContractDto;
    closeModal(): void;
}> = ({ currentModal, contract, rentalContract, closeModal }) => {
    const { creditContract } = useCreditContract(contract.id);
    const { revertContractEnd } = useRevertContractEnd(contract.id);

    switch (currentModal) {
        case ContractModal.EndModal: {
            return (
                <EndContractModal contract={contract} mainSubContract={rentalContract} onClose={closeModal} />
            );
        }
        case ContractModal.RolloverModal: {
            if (contract.type !== ContractType.Rental) {
                return null;
            }
            return (
                <RolloverContractModal
                    contract={contract}
                    mainSubContract={rentalContract}
                    onClose={closeModal}
                />
            );
        }
        case ContractModal.CancelModal: {
            return <CancelContractModal contract={contract} onClose={closeModal} />;
        }
        case ContractModal.PartOfSalesOrderModal: {
            return (
                <PartOfSalesOrderModal
                    onClose={closeModal}
                    title="Contract can't be cancelled"
                    content={
                        <>
                            This contract is part of a sales order. <br />
                            In order to cancel the contract, you must cancel the sales order{' '}
                            <Link to={getSalesOrderLink(contract.salesOrderId)} inline>
                                #{contract.salesOrderId}
                            </Link>
                        </>
                    }
                />
            );
        }
        case ContractModal.ActivateModal: {
            return <ActivateContractModal contract={contract} onClose={closeModal} />;
        }
        // TODO HOF-11101 Add possibility to update contract expiry settings
        case ContractModal.CreditModal: {
            return (
                <ConfirmModal
                    keyPrefix='credit-contract-modal'
                    onClose={closeModal}
                    onConfirm={() => creditContract(contract.id)}
                />
            );
        }
        case ContractModal.RevertContractEnd: {
            return (
                <ConfirmModal
                    keyPrefix='revert-contract-end-modal'
                    onClose={closeModal}
                    onConfirm={revertContractEnd}
                />
            );
        }
    }

    return null;
};
