import { forcedNetsuiteSyncQuery } from '@hofy/api-shared';
import { stringifyUrl } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { ProductDetailsDto } from './types/ProductDetailsDto';
import { ProductDto } from './types/ProductDto';
import { ProductPayload } from './types/ProductPayload';
import { VariantWithProductAndPriceQuery, VariantWithProductDto } from './types/VariantWithProductDto';

class ProductService {
    public getProducts = async (): Promise<ProductDto[]> => {
        const response = await restClient.getJson<{
            products: ProductDto[];
        }>(`/api/admin/products`);
        return response.products;
    };

    public getVariantsWithProductAndPrice = async (
        query: VariantWithProductAndPriceQuery,
    ): Promise<VariantWithProductDto[]> => {
        return restClient.getJson<VariantWithProductDto[]>(
            stringifyUrl({ url: '/api/admin/products/variants', query: { ...query } }),
        );
    };

    public getProduct = async (id: number): Promise<ProductDetailsDto> => {
        return restClient.getJson<ProductDetailsDto>(`/api/admin/products/${id}`);
    };

    public createProduct = (payload: ProductPayload): Promise<number> => {
        return restClient.postJson<{ id: number }>(`/api/admin/products`, payload).then(r => r.id);
    };

    public updateProduct = (id: number, payload: ProductPayload): Promise<void> => {
        return restClient.put(`/api/admin/products/${id}`, payload);
    };

    public syncToNetsuite = async (id: number) => {
        return restClient.post(`/api/admin/products/${id}/sync-to-netsuite`);
    };

    public syncAllToNetsuite = async () => {
        return restClient.post(
            stringifyUrl({
                url: '/api/admin/products/sync-to-netsuite',
                query: { ...forcedNetsuiteSyncQuery },
            }),
        );
    };
}

export const productsService = new ProductService();
