import { intersection } from 'lodash';
import React, { FC, ReactNode } from 'react';

import { AddressPayload, useTrTaxIdLabel } from '@hofy/api-shared';
import { HelpCenterLink } from '@hofy/config';
import { asArray } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import {
    Box,
    FormCountrySelect,
    FormDateInput,
    FormFieldRecord,
    FormGridRow,
    FormInput,
    FormPhoneInput,
    FormSection,
    FormTextarea,
    HelpTooltipIcon,
    Link,
    Paragraph4,
} from '@hofy/ui';

import { AddressPayloadField, useAddressConfig } from '../store/useAddressConfig';
import { useAddressI18n } from '../store/useAddressI18n';
import { PassportInfoPane } from './PassportInfoPane';
import { StateInput } from './StateInput';
import { TaxIdInfoPane } from './TaxIdInfoPane';

export interface AddressFieldProps {
    label?: ReactNode;
    api: FormFieldRecord<AddressPayload>;
    showAlerts?: boolean;
    countryEditable?: boolean;
    topBadge?: ReactNode;
}

export const AddressField: FC<AddressFieldProps> = ({
    api,
    showAlerts = true,
    countryEditable = true,
    label,
    topBadge,
}) => {
    const { tr } = useAddressI18n();
    const trTaxIdLabel = useTrTaxIdLabel();
    const country = api.country.value;
    const config = useAddressConfig()(api.type.value, api.country.value);
    const requiredFields = config.required;
    const visibleFields = config.visible;
    return (
        <Box column gap={20}>
            <FormSection label={label || tr('address.address.title')}>
                {topBadge}
                <FormCountrySelect
                    isRequired={requiredFields.includes('country')}
                    label={tr('address.country.label')}
                    disabled={!countryEditable}
                    api={api.country}
                />
                <Visible visible={visibleFields} fields='state'>
                    <StateInput
                        api={api.state}
                        country={country}
                        isRequired={requiredFields.includes('state')}
                    />
                </Visible>
                <FormGridRow columns={2}>
                    <Visible visible={visibleFields} fields='postCode'>
                        <FormInput
                            label={tr('address.postcode.label')}
                            api={api.postCode}
                            isRequired={requiredFields.includes('postCode')}
                            testKey='postcode-input'
                            nullable
                        />
                    </Visible>
                    <Visible visible={visibleFields} fields='city'>
                        <FormInput
                            label={tr('address.city.label')}
                            isRequired={requiredFields.includes('city')}
                            api={api.city}
                            testKey='city-input'
                            nullable
                        />
                    </Visible>
                </FormGridRow>
                <Visible visible={visibleFields} fields='line1'>
                    <FormInput
                        label={tr('address.line1.label')}
                        api={api.line1}
                        isRequired={requiredFields.includes('line1')}
                        testKey='address-line-1-input'
                        nullable
                    />
                </Visible>
                <Visible visible={visibleFields} fields='line2'>
                    <FormInput
                        label={tr('address.line2.label')}
                        api={api.line2}
                        testKey='address-line-2-input'
                        nullable
                    />
                </Visible>
                <Visible visible={visibleFields} fields='phoneNumber'>
                    <FormPhoneInput
                        label={tr('address.phone-number.label')}
                        api={api.phoneNumber}
                        isRequired={requiredFields.includes('phoneNumber')}
                        testKey='phone-number-input'
                        initialCountry={country}
                        nullable
                    />
                </Visible>
                <Visible visible={visibleFields} fields='deliveryInstructions'>
                    <FormTextarea
                        api={api.deliveryInstructions}
                        rows={3}
                        testKey='delivery-instructions-input'
                        isRequired={requiredFields.includes('deliveryInstructions')}
                        label={tr('address.delivery-instructions.label')}
                        nullable
                    />
                </Visible>
            </FormSection>
            <Visible visible={visibleFields} fields={['dateOfBirth', 'passportNumber', 'taxIdNumber']}>
                <FormSection
                    label={
                        <Box row inline gap={8}>
                            {tr('address.additional-fields.title')}
                            <HelpTooltipIcon
                                maxWidth={300}
                                bodySlot={
                                    <Paragraph4 color={Color.ContentInvertedPrimary}>
                                        {tr('address.additional-fields.tooltip.text')}
                                        <Link to={HelpCenterLink.CountryIdentifiers} inline invertColor>
                                            {tr('address.additional-fields.tooltip.link')}
                                        </Link>
                                        .
                                    </Paragraph4>
                                }
                                interactive
                            />
                        </Box>
                    }
                >
                    <Visible visible={visibleFields} fields='taxIdNumber'>
                        <FormInput
                            label={trTaxIdLabel(country)}
                            api={api.taxIdNumber}
                            isRequired={requiredFields.includes('taxIdNumber')}
                            nullable
                        />
                        {showAlerts && <TaxIdInfoPane country={country} />}
                    </Visible>
                    <Visible visible={visibleFields} fields='passportNumber'>
                        <FormInput
                            label={tr('address.passport-number.label')}
                            isRequired={requiredFields.includes('passportNumber')}
                            api={api.passportNumber}
                            nullable
                        />
                    </Visible>
                    <Visible visible={visibleFields} fields='dateOfBirth'>
                        <FormDateInput
                            label={tr('address.date-of-birth.label')}
                            api={api.dateOfBirth}
                            isRequired={requiredFields.includes('dateOfBirth')}
                            nullable
                        />
                        {showAlerts && <PassportInfoPane country={country} />}
                    </Visible>
                </FormSection>
            </Visible>
        </Box>
    );
};

interface VisibleProps {
    visible: AddressPayloadField[];
    fields: AddressPayloadField | AddressPayloadField[];
    children?: ReactNode;
}

export const Visible: FC<VisibleProps> = ({ fields, visible, children }) => {
    const allFields = asArray(fields);
    if (intersection(visible, allFields).length === 0) {
        return null;
    }
    return <>{children}</>;
};
