import React, { FC } from 'react';

import { ShipmentCollectionRequestPackagingDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { BooleanBadge, FormGridRow, Labeled, LabeledText, Link } from '@hofy/ui';

interface PackagingDetailsSectionProps {
    packagingDetails: ShipmentCollectionRequestPackagingDto;
}

export const PackagingDetailsSection: FC<PackagingDetailsSectionProps> = ({
    packagingDetails: { invoiceEntryCreated, trackingLink, trackingReference, markedAsReceivedAt },
}) => {
    return (
        <FormGridRow columns={2}>
            <LabeledText label='Tracking reference' content={trackingReference} />
            <LabeledText label='Packaging marked as received on' content={formatDate(markedAsReceivedAt)} />
            <Labeled label='Tracking link' content={trackingLink && <Link to={trackingLink} />} />
            {invoiceEntryCreated !== null && (
                <Labeled
                    label='Invoice line created for packaging'
                    content={<BooleanBadge value={invoiceEntryCreated} />}
                />
            )}
        </FormGridRow>
    );
};
