import { OrganizationType, PlatformTier } from '@hofy/api-shared';
import { Country, Currency } from '@hofy/global';

export interface OrganizationManager {
    firstName: string;
    lastName: string;
    email: string;
    country: Country | null;
    sendInvite: boolean;
}

export interface CreateOrganizationPayload {
    name: string;
    currency: Currency | null;
    manager: OrganizationManager | null;
    organizationType: OrganizationType;
    platformTier: PlatformTier;

    seedProducts: boolean;
    seedProductsWithFurniture: boolean;
    successAdminId: number | null;
    salesAdminId: number | null;
}

export interface CreateOrganizationFormData {
    name: string;
    currency: Currency | null;
    organizationType: OrganizationType;
    platformTier: PlatformTier;

    successAdminId: number | null;
    salesAdminId: number | null;

    seedProducts: boolean;
    seedWithFurniture: boolean;
    createManager: boolean;
    managerFirstName: string;
    managerLastName: string;
    managerEmail: string;
    managerCountry: Country | null;
    managerSendInvite: boolean;
}

export const emptyOrganizationFormData: CreateOrganizationFormData = {
    name: '',
    currency: null,
    seedProducts: true,
    seedWithFurniture: false,
    organizationType: OrganizationType.Standard,
    platformTier: PlatformTier.OrgBasedPro,
    successAdminId: null,
    salesAdminId: null,
    createManager: false,
    managerFirstName: '',
    managerLastName: '',
    managerEmail: '',
    managerCountry: null,
    managerSendInvite: true,
};
