import { getEnumValues } from '@hofy/global';

export enum AddressType {
    Delivery = 'delivery',
    Partial = 'partial',
    Collection = 'collection',
    Billing = 'billing',
    Entity = 'entity',
    Supplier = 'supplier',
    PII = 'pii',
}

export const allAddressTypes = getEnumValues<AddressType>(AddressType);
