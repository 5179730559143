import { ActorDto } from '@hofy/api-shared';
import { DateTimeString, UUID } from '@hofy/global';

import { AuditableTable } from './AuditableTable';
import { AuditLogOperationType } from './AuditLogOperationType';

interface AuditHiddenEntry {
    checksum: string;
}

export interface AuditLogDto {
    id: number;
    createdAt: DateTimeString;
    relation: RelationDto;
    transactionUuid: UUID;
    method: string | null;
    operationType: AuditLogOperationType;
    actor: ActorDto | null;
}

export type DataRecordEntry = string | string[] | boolean | null | AuditHiddenEntry;
type DataRecord = Record<string, DataRecordEntry | Record<string, DataRecordEntry>>;

export interface AuditLogDetailsDto {
    id: number;
    createdAt: DateTimeString;
    relation: RelationDto;
    storedData: DataRecord;
    previousStoredData: DataRecord;
    transactionUuid: UUID;
    method: string | null;
    operationType: AuditLogOperationType;
    actor: ActorDto | null;
    dataRelations: RelationDto[];
}

interface RelationDto {
    tableId: number;
    tableName: AuditableTable;
}

export const isHiddenData = (v: DataRecordEntry): v is AuditHiddenEntry => {
    return typeof v === 'object' && !Array.isArray(v) && !!v?.checksum;
};

export const relationLabel = (relation: RelationDto) => `${relation.tableName} #${relation.tableId}`;
