import { BillingEntityDto } from '@hofy/api-admin';
import {
    AcquisitionType,
    OperatingSystem,
    OrganizationSize,
    OrganizationStatus,
    PaymentSchema,
    PaymentType,
    PlatformTier,
    PlatformTierConfigDto,
    RentalTerm,
} from '@hofy/api-shared';
import { Percent, Price, zeroDollars } from '@hofy/global';

export interface UpdateOrganizationFormData {
    logoUrl: string | null;
    id: number;
    name: string;
    size: OrganizationSize | null;
    welcomePacksEnabled: boolean;
    collectionSurveySkipDeviceReset: boolean;
    deviceConfigRequired: OperatingSystem[];
    unbundlingEnabled: boolean;
    serviceDeskEnabled: boolean;
    dseEnabled: boolean;
    hostAgentEnabled: boolean;
    storeAndReuseManagedByHofy: boolean;
    originalStatus: OrganizationStatus;
    deviceResetInstructionsLink: string | null;
    creditLimit: Price | null;
    sassFee: Price;
    laptopConfigFee: Price | null;
    mdmFee: Price | null;
    successAdminId: number | null;
    salesAdminId: number | null;
    allowedBillingFrequencies: PaymentSchema[];
    allowedRentalTerms: RentalTerm[];
    allowedAcquisitionTypes: AcquisitionType[];
    platformTierConfig: PlatformTierConfigDto;
    billingEntities: BillingEntityDto[];
    hasContracts: boolean;
    usedPaymentSchemas: PaymentSchema[];
    storefrontFee: Percent | null;
    paymentType: PaymentType;
}

export const emptyEditOrganizationFormData: UpdateOrganizationFormData = {
    id: 0,
    name: '',
    logoUrl: null,
    size: null,
    unbundlingEnabled: false,
    welcomePacksEnabled: false,
    collectionSurveySkipDeviceReset: false,
    deviceConfigRequired: [],
    serviceDeskEnabled: false,
    dseEnabled: false,
    hostAgentEnabled: false,
    storeAndReuseManagedByHofy: false,
    originalStatus: OrganizationStatus.Active,
    creditLimit: null,
    sassFee: zeroDollars,
    laptopConfigFee: zeroDollars,
    mdmFee: zeroDollars,
    deviceResetInstructionsLink: null,
    successAdminId: null,
    salesAdminId: null,
    allowedBillingFrequencies: [],
    allowedRentalTerms: [],
    allowedAcquisitionTypes: [],
    platformTierConfig: {
        platformTier: PlatformTier.UserBasedPro,
        managerOnlyOrganizationEnabled: false,
        hrisAutoImportEnabled: false,
        samlEnabled: false,
        autoApprovalEnabled: false,
        billingEntityLimit: null,
        teamLimit: null,
        freeLicensesLimit: 0,
        deviceTrackedLimit: null,
        teamMemberEquipLimit: null,
    },
    billingEntities: [],
    hasContracts: false,
    usedPaymentSchemas: [],
    storefrontFee: null,
    paymentType: PaymentType.Deferred,
};
