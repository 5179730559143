export const organizationTabI18n = {
    'organization-tab.details': 'Details',
    'organization-tab.invoices': 'Invoices',
    'organization-tab.teams': 'Teams',
    'organization-tab.users': 'Users',
    'organization-tab.devices': 'Devices',
    'organization-tab.billing-entities': 'Billing entities',
    'organization-tab.discounts': 'Discounts',
    'organization-tab.rental-equipment-payment-discounts': 'Rental payment discounts',
    'organization-tab.contract-settings': 'Contract settings',
    'organization-tab.terms-and-conditions': 'Terms and conditions',
    'organization-tab.addons': 'Addons',
    'organization-tab.invoice-groups': 'Invoice groups',
    'organization-tab.emails': 'Emails',
    'organization-tab.subscriptions': 'Subscriptions',
};
