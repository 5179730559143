import React, { FC, useState } from 'react';

import { Price } from '@hofy/global';
import { usePrice } from '@hofy/hooks';
import {
    Alert,
    AsyncButton,
    Box,
    LabeledSwitch,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
} from '@hofy/ui';

import { CancelButton } from '../../../../../components/design/button/CancelButton';
import { useResolveCollectionRequestLabelsAndPackaging } from '../../../../../store/collectionRequests/useResolveCollectionRequestLabelsAndPackaging';

interface ResolvePackagingModalProps {
    shipmentId: number;
    collectionRequestId: number;
    packagingFee: Price | null;
    onClose(): void;
}

export const ResolvePackagingModal: FC<ResolvePackagingModalProps> = ({
    collectionRequestId,
    packagingFee,
    shipmentId,
    onClose,
}) => {
    const { formatPrice } = usePrice();
    const { resolveCollectionRequestLabelsAndPackaging, isPending, isError } =
        useResolveCollectionRequestLabelsAndPackaging(shipmentId, collectionRequestId, onClose);

    const [createInvoiceEntry, setCreateInvoiceEntry] = useState(packagingFee ? false : null);
    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader title='Resolve labels and packaging' />
            <ModalContent column gap={40}>
                <Paragraph3 data-test-key='subtitle'>
                    This will mark the labels and the packaging as received by the user. No further action
                    will be required.
                </Paragraph3>
                {packagingFee && createInvoiceEntry !== null && (
                    <Box column gap={24}>
                        <LabeledSwitch
                            label='Create invoice line for packaging'
                            checked={createInvoiceEntry}
                            onChange={setCreateInvoiceEntry}
                        />
                        {createInvoiceEntry && (
                            <Alert
                                type='informative'
                                description={`A ${formatPrice(
                                    packagingFee,
                                )} packaging invoice line will be created for this organisation`}
                            />
                        )}
                    </Box>
                )}
            </ModalContent>
            <ModalFooter>
                <CancelButton label='Cancel' onClick={onClose} />
                <AsyncButton
                    label='Mark as resolved'
                    onClick={() =>
                        resolveCollectionRequestLabelsAndPackaging({
                            createInvoiceEntry,
                        })
                    }
                    isLoading={isPending}
                    isError={isError}
                />
            </ModalFooter>
        </Modal>
    );
};
