import React, { FC, useState } from 'react';

import { InvoiceDetailsDto, useBillingEntityInvoiceContacts } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenu } from '@hofy/common';
import { Modals, Spinner } from '@hofy/ui';

import { useIncludeInDunning } from '../../../../store/invoices/useIncludeInDunning';
import { useSyncToNetsuite } from '../../../../store/invoices/useSyncToNetsuite';
import { useSyncTwoPayment } from '../../../../store/invoices/useSyncTwoPayment';
import { ExcludeFromDunningModal } from './components/ExcludeFromDunningModal';
import { SendInvoiceModal } from './components/SendInvoiceModal';

interface InvoiceDetailsPageMenuProps {
    invoice: InvoiceDetailsDto;
}

export const InvoiceDetailsPageMenu: FC<InvoiceDetailsPageMenuProps> = ({ invoice }) => {
    const { hasPermission } = useSession();
    const { syncToNetsuite, isLoadingMutation: isNetsuiteSyncLoading } = useSyncToNetsuite(invoice.id);
    const { syncTwoPayment, isLoadingMutation: isTwoPaymentSyncLoading } = useSyncTwoPayment(invoice.id);
    const { contacts, isLoading: isLoadingContacts } = useBillingEntityInvoiceContacts(
        invoice.billingEntity.id,
    );

    const [sendInvoiceModalOpen, setSendInvoiceModalOpen] = useState(false);
    const { enableDunning, isLoading: isEnableDunningLoading } = useIncludeInDunning(invoice.id);
    const [disableDunningModalOpen, setDisableDunningModalOpen] = useState(false);

    const isLoading = () => {
        return (
            isNetsuiteSyncLoading || isTwoPaymentSyncLoading || isEnableDunningLoading || isLoadingContacts
        );
    };

    const isTwo = invoice.twoApi || invoice.twoSpreadsheet;

    if (isLoading()) {
        return <Spinner size={16} />;
    }

    return (
        <>
            <MoreMenu
                items={[
                    {
                        action: syncToNetsuite,
                        label: 'Sync to Netsuite',
                        visible: hasPermission(Permission.AdminAccountingTransactionalSync),
                    },
                    {
                        action: syncTwoPayment,
                        label: 'Sync Two Payments',
                        visible: isTwo && hasPermission(Permission.AdminAccountingTransactionalSync),
                    },
                    {
                        action: () => setSendInvoiceModalOpen(true),
                        label: 'Send invoice email',
                        visible: hasPermission(Permission.AdminInvoicesGenerate),
                    },
                    {
                        action: () => {
                            setDisableDunningModalOpen(true);
                        },
                        label: 'Exclude from reminders',
                        visible:
                            !invoice.twoApi &&
                            !invoice.excludedFromDunning &&
                            hasPermission(Permission.AdminInvoicesGenerate),
                    },
                    {
                        action: enableDunning,
                        label: 'Include in reminders',
                        visible:
                            !invoice.twoApi &&
                            !!invoice.excludedFromDunning &&
                            hasPermission(Permission.AdminInvoicesGenerate),
                    },
                ]}
            />
            <Modals>
                {sendInvoiceModalOpen && (
                    <SendInvoiceModal
                        invoiceId={invoice.id}
                        contacts={contacts}
                        emailSent={!!invoice.notification.firstSentAt}
                        onClose={() => setSendInvoiceModalOpen(false)}
                    />
                )}
                {disableDunningModalOpen && (
                    <ExcludeFromDunningModal
                        invoiceId={invoice.id}
                        onClose={() => setDisableDunningModalOpen(false)}
                    />
                )}
            </Modals>
        </>
    );
};
