import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToast } from '@hofy/ui';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import { organizationsCacheKey } from './organizationsCacheKey';

export const useDeleteOrganization = (organizationId: number, onSuccess: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: adminOrganizationsService.deleteOrganization,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey] });
            showToast({
                type: 'positive',
                message: `Organisation deleted`,
            });
            onSuccess();
        },
    });

    return {
        deleteOrganization: () => mutation.mutate(organizationId),
        isLoadingMutation: mutation.isPending,
    };
};
