import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { useEffect } from 'react';

import { OrganizationDiscountDto } from '@hofy/api-shared';
import { isValidFraction, percentToNumber } from '@hofy/global';
import { errorMap } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import {
    emptyOrganizationDiscountFormData,
    OrganizationDiscountFormData,
} from '../../services/organizations/types/OrganizationDiscountFormData';
import { organizationsCacheKey } from './organizationsCacheKey';

export const useUpdateOrganizationDiscount = (
    organizationId: number,
    discount?: OrganizationDiscountDto,
    onSuccess?: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: OrganizationDiscountFormData) =>
            adminOrganizationsService.saveOrganizationDiscount(organizationId, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organizationId] });
            showToast({
                type: 'positive',
                message: `Organization discount successfully saved`,
            });
            onSuccess?.();
        },
    });

    useEffect(() => {
        if (discount) {
            form.setValues({
                id: discount.id,
                discountPercent: discount.discountPercent,
                productCategory: discount.parentProductCategory || discount.productCategory,
                rentalTerm: discount.rentalTerm,
                precedence: discount.precedence,
                validStartOn: discount.validStartOn,
                validEndOn: discount.validEndOn,
            });
        }
    }, [discount]);

    const validatePayload = (formState: OrganizationDiscountFormData) => {
        const { discountPercent, precedence } = formState;

        return {
            discountPercent: errorMap(
                [!isValidFraction(discountPercent), 'Value should be between -100 and 100'],
                [percentToNumber(discountPercent) === 0, "Value shouldn't be zero"],
                [isNil(discountPercent), "Can't be empty"],
            ),
            precedence: errorMap([isNil(precedence), "Can't be empty"]),
        };
    };

    const form = useForm<OrganizationDiscountFormData>({
        initial: emptyOrganizationDiscountFormData,
        onSubmit: mutation.mutate,
        validate: validatePayload,
    });

    return {
        form,
        isLoadingMutation: mutation.isPending,
        isMutationSuccess: mutation.isSuccess,
    };
};
