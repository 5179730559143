import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToast } from '@hofy/ui';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import { organizationTermAndConditionsCacheKey } from './useOrganizationTermAndConditionsQuery';

export const useAdminDeleteOrganizationTermAndCondition = (onSuccess?: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: adminOrganizationsService.deleteOrganizationTermAndCondition,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationTermAndConditionsCacheKey] });
            showToast({
                type: 'positive',
                message: `Organization term and condition deleted`,
            });
            onSuccess?.();
        },
    });

    return {
        deleteOrganizationTermAndCondition: mutation.mutate,
    };
};
