import React, { FC, ReactNode } from 'react';

import { formatUserName, UserRefDto, UserWithTeamRefDto } from '@hofy/api-shared';
import { Country } from '@hofy/global';
import { Avatar } from '@hofy/ui';

import { LocationCard } from './LocationCard';

interface UserLocationCardProps {
    user: UserRefDto | UserWithTeamRefDto;
    label?: string;
    country?: Country;
    link?: string;
    children?: ReactNode;
}

export const UserLocationCard: FC<UserLocationCardProps> = ({ user, label, country, link, children }) => (
    <LocationCard
        icon={<Avatar userUuid={user.uuid} name={user.firstName} pictureUrl={user.pictureUrl} />}
        label={label}
        name={formatUserName(user)}
        country={country}
        link={link}
    >
        {children}
    </LocationCard>
);
