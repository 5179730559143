import { DateString, UUID } from '@hofy/global';

export interface UserRefDto {
    id: number;
    uuid: UUID;
    firstName: string;
    lastName: string;
    isOffBoarded: boolean | null;
    pictureUrl: string | null;
    newJoinerStartsOn: DateString | null;
}

export const formatAdminName = (v: UserRefDto) =>
    `${v.firstName} ${v.lastName}${v.isOffBoarded ? ' (Offboarded)' : ''}`;
export const unassignedUser = 0;
