import React, { FC, useState } from 'react';

import { ContractDetailsDtoWithPurchase } from '@hofy/api-admin';
import { PaymentType, PurchaseContractStatus } from '@hofy/api-shared';
import { Overlay } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Box, Modals } from '@hofy/ui';

import { ContractTab, purchaseContractTabs } from '../../../store/contracts/types/ContractTab';
import { AssignmentsTable } from '../../itemsPage/AssignmentsTable';
import { AddonsTable } from './AddonsTable';
import { BillingEntityUpdateModal } from './components/BillingEntityUpdateModal';
import { CommonContractHeader } from './components/CommonContractHeader';
import { ContractInvoiceEntriesTab } from './ContractInvoiceEntriesTab';
import { PurchaseContractDetailsTab } from './PurchaseContractDetailsTab';
import { RepaymentPlansTab } from './RepaymentPlansTab';
import { StorageAssignmentsTable } from './StorageAssignmentsTable';

interface PurchaseDetailsProps {
    tab: ContractTab;
    contract: ContractDetailsDtoWithPurchase;
    onChangeTab(t: ContractTab): void;
    onOpenAddInvoiceEntrySlideout(organizationId: number): void;
    onInvoiceEntryClick(entryId: number): void;
    onEdit(contractId: number): void;
}

export const PurchaseDetails: FC<PurchaseDetailsProps> = ({
    tab,
    contract,
    onChangeTab,
    onOpenAddInvoiceEntrySlideout,
    onInvoiceEntryClick,
    onEdit,
}) => {
    const storageAssignments = contract.managementContract?.storageAssignments || [];
    const [includeDeletedLines, setIncludeDeletedLines] = useState(false);
    const [showUpdateBillingEntityModal, setShowUpdateBillingEntityModal] = useState(false);

    const renderContent = () => {
        switch (tab) {
            case ContractTab.Details:
                return <PurchaseContractDetailsTab contract={contract} />;
            case ContractTab.InvoiceEntries:
                return (
                    <ContractInvoiceEntriesTab
                        includeDeleted={includeDeletedLines}
                        contractId={contract.id}
                        onRowClick={entry => onInvoiceEntryClick(entry.id)}
                    />
                );
            case ContractTab.Assignments:
                return (
                    <AssignmentsTable maxWidth={2000} assignments={contract.assignments} inSlideout={false} />
                );
            case ContractTab.Addons:
                return <AddonsTable addons={contract.purchaseContract.addons} />;
            case ContractTab.RepaymentPlans:
                return <RepaymentPlansTab contractId={contract.id} />;
            case ContractTab.StorageAssignments:
                return <StorageAssignmentsTable storageAssignments={storageAssignments} />;
        }
    };

    const canEditContract =
        contract.paymentType === PaymentType.Deferred &&
        contract.purchaseContract.status === PurchaseContractStatus.Pending;
    const menuOptions = [
        {
            action: () => onEdit(contract.id),
            label: 'Edit',
            visible: canEditContract,
        },
        {
            action: () => setShowUpdateBillingEntityModal(true),
            label: 'Update billing entity',
        },
    ];

    if (!contract) {
        return null;
    }
    const filteredTabs = purchaseContractTabs.filter(
        tab => contract.managementContract !== null || tab !== ContractTab.StorageAssignments,
    );
    return (
        <Overlay column flex='auto' bg={Color.BackgroundDefault}>
            <CommonContractHeader
                contractTitle={`Purchase #${contract.id}`}
                contractId={contract.id}
                organizationId={contract.organization.id}
                tab={tab}
                tabs={filteredTabs}
                menuOptions={menuOptions}
                includeDeleted={includeDeletedLines}
                onChangeTab={onChangeTab}
                onSetIncludeDeleted={setIncludeDeletedLines}
                onOpenAddInvoiceEntrySlideout={onOpenAddInvoiceEntrySlideout}
            />
            <Box flex='auto' relative overflow='auto'>
                {renderContent()}
            </Box>
            <Modals>
                {showUpdateBillingEntityModal && (
                    <BillingEntityUpdateModal
                        contractId={contract.id}
                        organizationId={contract.organization.id}
                        currentBillingEntity={{
                            id: contract.purchaseContract.billingEntity.id,
                            currency: contract.purchaseContract.billingEntity.currency,
                        }}
                        onClose={() => setShowUpdateBillingEntityModal(false)}
                    />
                )}
            </Modals>
        </Overlay>
    );
};
