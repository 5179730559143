import React, { FC } from 'react';

import { AddressDto, formatUserName, NameAware } from '@hofy/api-shared';
import { Country, UUID } from '@hofy/global';
import { Box, CountryFlag, Paragraph3 } from '@hofy/ui';

interface BaseUserSelectorItemData extends NameAware {
    id?: number;
    uuid: UUID;
}

interface UserSelectorItemDataWithCountry extends BaseUserSelectorItemData {
    country: Country;
}

interface UserSelectorItemDataWithAddress extends BaseUserSelectorItemData {
    address: AddressDto;
}

export type UserSelectorItemData = UserSelectorItemDataWithCountry | UserSelectorItemDataWithAddress;

export interface UserSelectorItemProps {
    user: UserSelectorItemData;
}

export const UserSelectorItem: FC<UserSelectorItemProps> = ({ user }) => {
    const country = 'country' in user ? user.country : user.address.country;
    return (
        <Box row gap={8}>
            <CountryFlag country={country} />
            <Paragraph3 data-test-key={`user-${user.uuid}`}>{formatUserName(user)}</Paragraph3>
        </Box>
    );
};
