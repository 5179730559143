import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { useOrganizationAddonConfigsQuery } from '@hofy/api-admin';
import { addonTypeColors, Permission, useTrAddonType } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { useDecimal, useSearch } from '@hofy/hooks';
import {
    Badge,
    BaseTable,
    BooleanBadge,
    Box,
    ErrorStatePlaceholder,
    Paragraph3,
    Placeholder,
    SvgIllustration,
} from '@hofy/ui';

import { getAddonLink } from '../../../components/routing/adminLinks';
import { Hoverable } from '../../assignmentsPage/components/utils/Hoverable';
import { ValidForOrganizationAddonConfigBadge } from './ValidForOrganizationAddonConfigBadge';

interface AddonConfigurationsTabProps {
    organizationId: number;
    onUpdateAddonConfiguration(addonId: number): void;
    search: string;
}

export const AddonConfigurationsTab: FC<AddonConfigurationsTabProps> = ({
    organizationId,
    onUpdateAddonConfiguration,
    search,
}) => {
    const { data: addonsWithConfig, isLoading, isError } = useOrganizationAddonConfigsQuery(organizationId);

    const configsMatchingName = useSearch(addonsWithConfig, ({ name }) => [name], search);

    const { hasPermission } = useSession();

    const trAddonType = useTrAddonType();
    const { formatPercent } = useDecimal();

    if (isError) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <Box column fullHeight>
            <BaseTable
                data={configsMatchingName}
                toKey={item => item.id}
                onRowClick={({ id }) => onUpdateAddonConfiguration(id)}
                isLoading={isLoading}
                emptyContent={
                    <Placeholder
                        illustration={SvgIllustration.List}
                        title='No addons'
                        message={search && `No addons found for search term '${search}'`}
                    />
                }
                columns={[
                    {
                        id: 'addon',
                        header: 'Addon',
                        renderer: ({ id, name }) => {
                            if (!hasPermission(Permission.AdminAddonsView)) {
                                return <Paragraph3>{name}</Paragraph3>;
                            }
                            return (
                                <Link to={getAddonLink(id)} onClick={event => event.stopPropagation()}>
                                    <Hoverable>
                                        <Paragraph3>{name}</Paragraph3>
                                    </Hoverable>
                                </Link>
                            );
                        },
                    },
                    {
                        id: 'type',
                        header: 'Addon type',
                        renderer: ({ type }) => (
                            <Badge color={addonTypeColors[type]} label={trAddonType(type)} />
                        ),
                    },
                    {
                        id: 'discount',
                        header: 'Discount %',
                        renderer: ({ config }) => formatPercent(config.discount),
                    },
                    {
                        id: 'max-asset-age',
                        header: 'Max asset age (months)',
                        renderer: ({ config }) => config.assetAgeMax ?? '--',
                        width: 180,
                    },
                    {
                        id: 'enrollment-duration',
                        header: 'Enrollment duration (months)',
                        renderer: ({ config }) => config.enrollmentDuration ?? '--',
                        width: 180,
                    },
                    {
                        id: 'active',
                        header: 'Active',
                        width: 80,
                        renderer: ({ config }) => <BooleanBadge value={config.active} />,
                    },
                    {
                        id: 'valid_for_organization',
                        header: 'Valid for organization',
                        width: 100,
                        renderer: ({ name, validForOrganization }) => {
                            return (
                                <ValidForOrganizationAddonConfigBadge
                                    addonName={name}
                                    active={validForOrganization}
                                />
                            );
                        },
                    },
                ]}
            />
        </Box>
    );
};
