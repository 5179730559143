import React, { FC } from 'react';

import { ComponentLoader, Slideout, SlideoutFooter } from '@hofy/common';
import { Color } from '@hofy/theme';
import { AsyncButton, Box, Button, Paragraph3, SvgIcon } from '@hofy/ui';

import { useOrganizationDetailsQuery } from '../../../store/organizations/useOrganizationDetailsQuery';
import { useUpdateOrganization } from '../../../store/organizations/useUpdateOrganization';
import { UpdateOrganizationForm } from './UpdateOrganizationForm';

interface EditOrganizationSlideoutProps {
    organizationId: number;
    onClose(): void;
}

export const UpdateOrganizationSlideout: FC<EditOrganizationSlideoutProps> = ({
    onClose,
    organizationId,
}) => {
    const { data: organization, isLoading } = useOrganizationDetailsQuery(organizationId);
    const { form, isLoadingMutation, updatePermission } = useUpdateOrganization(organization, onClose);

    if (isLoading || !organization) {
        return <ComponentLoader />;
    }
    return (
        <Slideout width={800} onClose={onClose}>
            <UpdateOrganizationForm form={form} organization={organization} />
            <SlideoutFooter>
                <Button
                    type='ghost'
                    negativeMargin
                    onClick={onClose}
                    label='Cancel'
                    leftIcon={SvgIcon.Cross}
                />
                <Box row>
                    <Paragraph3 color={Color.FoundationNegative}>{form.errors.billingEntities}</Paragraph3>
                    <AsyncButton
                        disabled={!updatePermission}
                        marginLeft={10}
                        isLoading={isLoadingMutation}
                        label='Save'
                        onClick={form.submit}
                        disableCheck
                    />
                </Box>
            </SlideoutFooter>
        </Slideout>
    );
};
