import React, { FC } from 'react';

import { isAdminOrganizationWarehouse, UserAssignmentDto } from '@hofy/api-admin';
import {
    AddressDto,
    AssignmentStatus,
    formatUserName,
    ShipmentStatus,
    shipmentStatusNotificationType,
    ShipmentType,
    shipmentTypeAssignmentStatus,
} from '@hofy/api-shared';
import { SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { Alert, AsyncButton, Button, FormSection, LabeledText, SvgIcon } from '@hofy/ui';

import { AddressDetails } from '../../../components/domain/address/AddressDetails';
import { AdminListDropdown } from '../../../components/domain/user/AdminListDropdown';
import { WarehouseDropdown } from '../../../components/domain/warehouses/WarehouseDropdown';
import { useCreateDeliveryShipment } from '../../../store/shipments/useCreateShipment';
import { UserDetailsLink } from '../../usersPage/UserDetailsLink';
import { SendCollectionSurveyForm } from '../components/SendCollectionSurveyForm';
import { AssignmentSelection } from './AssignmentSelection';

interface CreateDeliveryShipmentFormProps {
    userId: number;
    selected: UserAssignmentDto[];
    address: AddressDto;
    onSuccess(id: number): void;
    onClose(): void;
}

export const CreateDeliveryShipmentForm: FC<CreateDeliveryShipmentFormProps> = ({
    onSuccess,
    onClose,
    selected,
    userId,
    address,
}) => {
    const firstItemWarehouse = selected[0].item?.warehouse;
    const { selection, form, isLoadingMutation } = useCreateDeliveryShipment(
        selected,
        address.country,
        address.uuid,
        firstItemWarehouse?.id,
        onSuccess,
    );

    const primaryManager = firstItemWarehouse?.organizationWarehouse?.primaryManager;
    const secondaryManager = firstItemWarehouse?.organizationWarehouse?.secondaryManager;

    return (
        <>
            <SlideoutHeader title='Create shipment' />
            <SlideoutContent column gap={24} paddingVertical={24}>
                <Alert
                    description='Creating shipment'
                    type={shipmentStatusNotificationType[ShipmentStatus.Created]}
                />
                {isAdminOrganizationWarehouse(firstItemWarehouse) && (
                    <FormSection label='Transfer from storage location'>
                        <LabeledText label='Name' content={firstItemWarehouse.name} />
                        <LabeledText
                            label='Storage primary manager'
                            content={
                                primaryManager?.id && (
                                    <UserDetailsLink id={primaryManager.id}>
                                        {formatUserName(primaryManager)}
                                    </UserDetailsLink>
                                )
                            }
                        />
                        <LabeledText
                            label='Storage secondary manager'
                            content={
                                secondaryManager?.id && (
                                    <UserDetailsLink id={secondaryManager.id}>
                                        {formatUserName(secondaryManager)}
                                    </UserDetailsLink>
                                )
                            }
                        />
                        <AddressDetails address={firstItemWarehouse.address} />
                    </FormSection>
                )}
                <FormSection label='Delivery info'>
                    <AddressDetails address={address} />
                </FormSection>
                {!firstItemWarehouse && (
                    <FormSection label='Create delivery shipment'>
                        <WarehouseDropdown
                            label='From warehouse'
                            isRequired
                            onChange={warehouseId =>
                                form.setValues({
                                    warehouseId,
                                })
                            }
                            value={form.values.warehouseId}
                            isError={form.errors.warehouseId}
                        />
                    </FormSection>
                )}
                <AdminListDropdown
                    label='Assigned user'
                    emptyContent='Pick user'
                    value={form.values.assignedUserId}
                    onChange={assignedUserId =>
                        form.setValues({
                            assignedUserId,
                        })
                    }
                />
                <AssignmentSelection
                    status={shipmentTypeAssignmentStatus[ShipmentType.Delivery]}
                    selection={selection}
                    userId={userId}
                    filterAdditionalAssignmentsBy={assignment =>
                        assignment.status !== AssignmentStatus.WithUser &&
                        assignment.item?.warehouse?.uuid === selection.assignments[0]?.item?.warehouse?.uuid
                    }
                />
                {isAdminOrganizationWarehouse(firstItemWarehouse) && (
                    <SendCollectionSurveyForm showSendOption form={form} />
                )}
            </SlideoutContent>
            <SlideoutFooter>
                <Button
                    type='ghost'
                    negativeMargin
                    onClick={onClose}
                    label='Close'
                    leftIcon={SvgIcon.Cross}
                />
                <AsyncButton
                    isLoading={isLoadingMutation}
                    label='Create'
                    onClick={form.submit}
                    disableCheck
                />
            </SlideoutFooter>
        </>
    );
};
