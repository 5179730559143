import { getEnumValues } from '@hofy/global';

export enum OrganizationTab {
    Details = 'details',
    Invoices = 'invoices',
    Teams = 'teams',
    Users = 'users',
    Devices = 'devices',
    BillingEntities = 'billing-entities',
    Discounts = 'discounts',
    RentalEquipmentPaymentDiscounts = 'rental-equipment-payment-discounts',
    ContractSettings = 'contract-settings',
    TermsAndConditions = 'terms-and-conditions',
    Addons = 'addons',
    InvoiceGroups = 'invoice-groups',
    Emails = 'emails',
    Subscriptions = 'subscriptions',
}

export const SearchableOrganizationTabs = [
    OrganizationTab.Invoices,
    OrganizationTab.Teams,
    OrganizationTab.Users,
    OrganizationTab.BillingEntities,
    OrganizationTab.Devices,
    OrganizationTab.TermsAndConditions,
    OrganizationTab.Addons,
];
export const allOrganizationTabs = getEnumValues<OrganizationTab>(OrganizationTab);
