import React, { ReactNode } from 'react';
import { useBoolean } from 'react-use';

import { Color } from '@hofy/theme';
import { Box, Icon, IconButton, SearchInput, SectionTitle3, SvgIcon } from '@hofy/ui';

import { BlockFilter } from './BlockFilter';
import { BlockFilterItem } from './BlockFilterItem';

interface SearchableBlockFilterProps<T> {
    title: string;
    icon?: Svg;
    isSelected(item: T): boolean;
    items: T[];
    onFilterClick(countries: T): void;
    renderItem(item: T): ReactNode;
    searchPlaceholder: string;
    search: string;
    onSearch(query: string): void;
}

export const SearchableBlockFilter = <T,>({
    title,
    icon = SvgIcon.Filter,
    items,
    onFilterClick,
    isSelected,
    renderItem,
    searchPlaceholder,
    search,
    onSearch,
}: SearchableBlockFilterProps<T>) => {
    const [showSearch, toggleShowSearch] = useBoolean(false);

    const renderTitle = () => {
        if (showSearch || !!search) {
            return (
                <Box marginHorizontal={-12}>
                    <SearchInput
                        value={search}
                        onChange={onSearch}
                        onFocus={() => toggleShowSearch(true)}
                        onBlur={() => toggleShowSearch(false)}
                        placeholder={searchPlaceholder}
                        delay={100}
                        fullWidth
                        autoFocus
                    />
                </Box>
            );
        }
        return (
            <>
                <Icon svg={icon} size={16} marginRight={10} />
                <SectionTitle3 flex={1}>{title}</SectionTitle3>
                <IconButton
                    icon={SvgIcon.Search}
                    onClick={() => toggleShowSearch()}
                    color={Color.ContentSecondary}
                />
            </>
        );
    };

    return (
        <BlockFilter title={renderTitle()}>
            {items.map((item, index) => (
                <BlockFilterItem
                    key={`${item}-${index}`}
                    name={renderItem(item)}
                    selected={isSelected(item)}
                    onClick={() => onFilterClick(item)}
                />
            ))}
        </BlockFilter>
    );
};
