import { useMutation, useQueryClient } from '@tanstack/react-query';

import { collectionRequestService, ResolveCollectionRequestLabelsAndPackagingPayload } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

import { shipmentCollectionRequestQueryKey } from '../shipments/useShipmentCollectionRequestQuery';

export const useResolveCollectionRequestLabelsAndPackaging = (
    shipmentId: number,
    collectionRequestId: number,
    onSuccess: () => void,
) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const { mutate, isPending, isError } = useMutation({
        mutationFn: (payload: ResolveCollectionRequestLabelsAndPackagingPayload) =>
            collectionRequestService.resolveCollectionRequestLabelsAndPackaging(
                shipmentId,
                collectionRequestId,
                payload,
            ),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [shipmentCollectionRequestQueryKey, shipmentId] });
            showToast({
                type: 'positive',
                message: 'Labels and packaging resolved successfully',
            });
            onSuccess();
        },
    });

    return {
        resolveCollectionRequestLabelsAndPackaging: mutate,
        isPending,
        isError,
    };
};
