import { useQuery } from '@tanstack/react-query';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import { organizationsCacheKey } from './organizationsCacheKey';

export const useOrganizationDetailsQuery = (id: number | undefined) => {
    const { data, isLoading } = useQuery({
        queryKey: [organizationsCacheKey, id],
        queryFn: () => adminOrganizationsService.getOrganization(id!),
        enabled: !!id,
    });

    return {
        data,
        isLoading,
    };
};
