import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { UserRefDto } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import {
    Box,
    Button,
    FeaturedIcon,
    FeaturedIconSize,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Modals,
    Paragraph3,
    SvgIcon,
} from '@hofy/ui';

import { CancelButton } from '../../design/button/CancelButton';
import { AdminListDropdown } from './AdminListDropdown';
import { UserCard } from './UserCard';

interface AdminUserSelectorProps {
    user: UserRefDto | null;
    onUserChange(userId: number | null): void;
}

export const AdminUserSelector: FC<AdminUserSelectorProps> = ({ user, onUserChange }) => {
    const [modal, setModal] = useState(false);

    return (
        <>
            <AssignedAdminCard assignedUser={user} onClick={() => setModal(true)} />
            <Modals>
                {modal && (
                    <SetAdminUserModal
                        userId={user?.id ?? null}
                        onUserSubmit={onUserChange}
                        onClose={() => setModal(false)}
                    />
                )}
            </Modals>
        </>
    );
};

interface AssignedAdminCardProps {
    assignedUser: UserRefDto | null;
    onClick(): void;
}

const AssignedAdminCard: FC<AssignedAdminCardProps> = ({ assignedUser, onClick }) => {
    if (!assignedUser) {
        return <UnassignedUserCard onClick={onClick} />;
    }

    return <HoverableUserCard user={assignedUser} onClick={onClick} />;
};

const UnassignedUserCard: FC<{ onClick(): void }> = ({ onClick }) => {
    return (
        <HoverableUnassignedUser row padding={4} gap={8} rounded onClick={onClick}>
            <FeaturedIcon
                icon={SvgIcon.User}
                size={30 as FeaturedIconSize}
                shape='circle'
                variant='normal'
                type='informative'
            />
            <Paragraph3 ellipsis overflow='hidden'>
                Unassigned
            </Paragraph3>
        </HoverableUnassignedUser>
    );
};

const hoverStyles = `
    cursor: pointer;
    :hover {
        background: ${Color.BackgroundSubtleNeutral};
    }
`;

const HoverableUnassignedUser = styled(Box)`
    ${hoverStyles}
`;

const HoverableUserCard = styled(UserCard)`
    ${hoverStyles}
`;

interface SetAdminUserModalProps {
    userId: number | null;
    onUserSubmit(userId: number | null): void;
    onClose(): void;
}

const SetAdminUserModal: FC<SetAdminUserModalProps> = ({ userId, onUserSubmit, onClose }) => {
    const [selectedUser, setSelectedUser] = useState(userId ?? null);
    return (
        <Modal onClose={onClose} width={500}>
            <ModalHeader title='Assign admin user' />
            <ModalContent>
                <AdminListDropdown
                    label='User'
                    value={selectedUser ?? undefined}
                    onChange={u => setSelectedUser(u ?? null)}
                />
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onClose} label='Cancel' />
                {userId && (
                    <Button
                        label='Unassign'
                        type='secondary'
                        onClick={() => {
                            onUserSubmit(null);
                            onClose();
                        }}
                    />
                )}
                <Button
                    label='Assign'
                    onClick={() => {
                        onUserSubmit(selectedUser);
                        onClose();
                    }}
                    disabled={!selectedUser}
                />
            </ModalFooter>
        </Modal>
    );
};
