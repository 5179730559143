export enum CollectionRequestError {
    ForbiddenAccess = 'forbidden_access_to_collection_request',
    InactiveCollectionRequest = 'inactive_collection_request',
    InvalidCollectionRequestStatus = 'invalid_collection_request_status',
    UnescalatedCollectionRequest = 'unescalated_collection_request',
    DeferredCollectionRequest = 'deferred_collection_request',
    UnDeferredCollectionRequest = 'undeferred_collection_request',
    InvalidShipmentType = 'invalid_shipment_type',
    InvalidShipmentState = 'invalid_shipment_state',
    InvalidWarehouseOwnership = 'invalid_warehouse_ownership',
    MissingPersonalEmail = 'missing_user_personal_email',
    MissingWarehousePrimaryManager = 'missing_organization_warehouse_primary_manager',
    InvalidCollectionDatesRange = 'invalid_collection_dates_range',
    InvalidCollectionDatesAmount = 'invalid_collection_dates_amount',
    CollectionDatesWithoutWeekday = 'collection_dates_without_weekday',
    ShipmentHasCollectionRequest = 'shipment_has_collection_request',
    InvoiceSettingProvidedForStoreAndReuseItem = 'invoice_setting_provided_for_store_and_reuse_items',
    InvoiceSettingNotProvided = 'invoice_setting_not_provided',
    ResetDeviceConfirmationNotProvided = 'reset_device_confirmation_not_provided',
    ProvidedSerialNumberForNonDevice = 'provided_serial_number_for_non_device',
}
