import React, { FC } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { useGoBack } from '@hofy/hooks';
import { EnumRoute, IntRoute } from '@hofy/router';

import { EmailPreviewSlideout } from '../../components/domain/emailLogs/EmailPreviewSlideout';
import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { InvoiceDetailTabs } from '../../store/invoices/types/InvoiceDetailTabs';
import { useNavigateBillingEntity } from '../../store/invoicing/billingEntities/useNavigateBillingEntity';
import { AdminInvoicingTab } from '../../store/invoicing/types/AdminInvoicingTab';
import { OrganizationTab } from '../../store/organizations/types/OrganizationTab';
import { useNavigateSubscription } from '../../store/subscription/useNavigateSubscription';
import { UserTab } from '../../store/users/types/UserTab';
import { BillingEntityTabRoute } from '../invoicingPage/billingEntities/BillingEntitiesRouter';
import { BillingEntityDetailsOverlay } from '../invoicingPage/billingEntities/billingEntityDetailsOverlay/BillingEntityDetailsOverlay';
import { CreateUpdateBillingEntityOverlay } from '../invoicingPage/billingEntities/createUpdateBillingEntityOverlay/CreateUpdateBillingEntityOverlay';
import { ItemSlideoutRouter } from '../itemsPage/ItemSlideoutRouter';
import { CreateOrganizationSlideout } from './createOrganizationSlideout/CreateOrganizationSlideout';
import { UpdateOrganizationSlideout } from './createOrganizationSlideout/UpdateOrganizationSlideout';
import { CreateSubscriptionSlideoutContainer } from './createSubscriptionSlideout/CreateSubscriptionSlideoutContainer';
import { CreateUserSlideout } from './createUserSlideout/CreateUserSlideout';
import { OrganizationDetailsPage } from './OrganizationDetailsPage';
import { OrganizationsPage } from './OrganizationsPage';
import { UpdateAddonConfigurationSlideout } from './updateAddonConfigurationSlideout/UpdateAddonConfigurationSlideout';
import { UpdateContractSettingsSlideoutOverlay } from './updateContractSettingsSlideout/ContractSettingsSlideoutOverlay';
import { UpdateSubscriptionSlideoutContainer } from './updateSubscriptionSlideout/UpdateSubscriptionSlideoutContainer';

export const OrganizationsRouter: FC = () => {
    const history = useHistory();
    const { goBack } = useGoBack();
    const handleOpenOrganization = (id: number) => {
        history.push(`${AdminNavLink.Organizations}/${id}/details`);
    };

    const handleOpenNewOrganization = () => {
        history.push(`${AdminNavLink.Organizations}/list/new`);
    };

    return (
        <Switch>
            <OrganizationIdRoute path='/organizations/:organizationId(\d+)'>
                {({ organizationId }) => <OrganizationDetailsRouter organizationId={organizationId} />}
            </OrganizationIdRoute>
            <Route path={AdminNavLink.Organizations}>
                <>
                    <OrganizationsPage
                        onNewOrganization={handleOpenNewOrganization}
                        onOpenOrganization={handleOpenOrganization}
                    />
                    <Route exact path='/organizations/list/new'>
                        <CreateOrganizationSlideout onClose={() => goBack(AdminNavLink.Organizations)} />
                    </Route>
                </>
            </Route>
        </Switch>
    );
};

enum Method {
    Create = 'create',
    Edit = 'edit',
}

const OrganizationIdRoute = IntRoute('organizationId', Route);
const BillingEntityRoute = IntRoute('billingEntityId', Route);
const ContractSettingsRoute = IntRoute('contractSettingId', Route);
const AddonConfigurationRoute = IntRoute('addonId', Route);
const SubscriptionRoute = IntRoute('subscriptionId', Route);
const OrganizationTabRoute = EnumRoute<OrganizationTab>('organizationTab', OrganizationTab, Route);
const EmailPreviewSlideoutRoute = IntRoute('emailId', Route);

interface OrganizationDetailsRouterProps {
    organizationId: number;
}

const OrganizationDetailsRouter: FC<OrganizationDetailsRouterProps> = ({ organizationId }) => {
    const { goBack } = useGoBack();
    const history = useHistory();
    const handleOpenEditOrganization = (id: number) => {
        history.push(`${AdminNavLink.Organizations}/${id}/${OrganizationTab.Details}/${Method.Edit}`);
    };

    const handleOpenCreateUser = (id: number) => {
        history.push(`${AdminNavLink.Organizations}/${id}/${OrganizationTab.Users}/${Method.Create}`);
    };
    const handleOpenInvoice = (id: number) => {
        history.push(
            `${AdminNavLink.Invoicing}/${AdminInvoicingTab.Invoices}/${id}/${InvoiceDetailTabs.Details}`,
        );
    };
    const handleOpenItem = (id: number) => {
        history.push(`${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.Devices}/${id}`);
    };
    const handleUpdateContractSetting = (organizationId: number, contractSettingId: number) => {
        history.push(
            `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.ContractSettings}/${contractSettingId}/${Method.Edit}`,
        );
    };
    const handleUpdateAddonConfiguration = (addonId: number) => {
        history.push(
            `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.Addons}/${addonId}/${Method.Edit}`,
        );
    };
    const handleOpenUser = (id: number) => {
        history.push(`${AdminNavLink.Users}/${id}/${UserTab.Details}`);
    };
    const handleOpenEmail = (emailId: number) => {
        history.push(`${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.Emails}/${emailId}`);
    };
    const handleChangeTab = (id: number, tab: OrganizationTab) => {
        history.push(`${AdminNavLink.Organizations}/${id}/${tab}`);
    };

    const {
        navigateBillingEntityDetails,
        navigateBillingEntityTab,
        navigateUpdateBillingEntity,
        navigateBillingEntityList,
        getCreateBillingEntityLink,
    } = useNavigateBillingEntity(
        `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.BillingEntities}`,
    );

    const {
        handleOpenCreateSubscription,
        handleOpenEditSubscription,
        goBack: subscriptionGoBack,
    } = useNavigateSubscription(
        `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.Subscriptions}`,
    );

    return (
        <OrganizationIdRoute path={`/organizations/:organizationId(\\d+)`}>
            {({ organizationId }) => (
                <>
                    <Route
                        path={`${AdminNavLink.Organizations}/:organizationId(\\d+)/${OrganizationTab.Details}/${Method.Edit}`}
                    >
                        <UpdateOrganizationSlideout
                            onClose={() =>
                                goBack(
                                    `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.Details}`,
                                )
                            }
                            organizationId={organizationId}
                        />
                    </Route>
                    <Route
                        path={`/organizations/:organizationId(\\d+)/${OrganizationTab.BillingEntities}/${Method.Create}`}
                    >
                        <CreateUpdateBillingEntityOverlay
                            organizationId={organizationId}
                            onClose={() =>
                                goBack(
                                    `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.BillingEntities}`,
                                )
                            }
                        />
                    </Route>
                    <Route
                        path={`/organizations/:organizationId(\\d+)/${OrganizationTab.Subscriptions}/create`}
                    >
                        <CreateSubscriptionSlideoutContainer
                            organizationId={organizationId}
                            onClose={subscriptionGoBack}
                        />
                    </Route>
                    <ContractSettingsRoute
                        path={`${AdminNavLink.Organizations}/:organizationId(\\d+)/${OrganizationTab.ContractSettings}/:contractSettingId/${Method.Edit}`}
                    >
                        {({ contractSettingId }) => (
                            <UpdateContractSettingsSlideoutOverlay
                                organizationId={organizationId}
                                contractSettingId={contractSettingId}
                                onClose={() =>
                                    goBack(
                                        `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.ContractSettings}`,
                                    )
                                }
                            />
                        )}
                    </ContractSettingsRoute>
                    <AddonConfigurationRoute
                        path={`${AdminNavLink.Organizations}/:organizationId(\\d+)/${OrganizationTab.Addons}/:addonId/${Method.Edit}`}
                    >
                        {({ addonId }) => (
                            <UpdateAddonConfigurationSlideout
                                addonId={addonId}
                                organizationId={organizationId}
                                onClose={() =>
                                    goBack(
                                        `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.Addons}`,
                                    )
                                }
                            />
                        )}
                    </AddonConfigurationRoute>
                    <BillingEntityRoute
                        path={`${AdminNavLink.Organizations}/:organizationId(\\d+)/${OrganizationTab.BillingEntities}/:billingEntityId/${Method.Edit}`}
                    >
                        {({ billingEntityId }) => (
                            <CreateUpdateBillingEntityOverlay
                                onClose={navigateBillingEntityList}
                                billingEntityId={billingEntityId}
                                organizationId={organizationId}
                            />
                        )}
                    </BillingEntityRoute>
                    <BillingEntityTabRoute
                        path={`${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.BillingEntities}/:billingEntityId(\\d+)/:billingEntityTab`}
                    >
                        {({ billingEntityId, billingEntityTab }) => (
                            <BillingEntityDetailsOverlay
                                billingEntityId={billingEntityId}
                                billingEntityTab={billingEntityTab}
                                onChangeTab={tab => navigateBillingEntityTab(billingEntityId, tab)}
                                onInvoiceClick={handleOpenInvoice}
                                onUpdateBillingEntity={navigateUpdateBillingEntity}
                                onClose={navigateBillingEntityList}
                            />
                        )}
                    </BillingEntityTabRoute>
                    <Route
                        path={`${AdminNavLink.Organizations}/:organizationId(\\d+)/${OrganizationTab.Users}/${Method.Create}`}
                    >
                        <CreateUserSlideout
                            organizationId={organizationId}
                            onClose={() =>
                                goBack(
                                    `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.Users}`,
                                )
                            }
                        />
                    </Route>
                    <EmailPreviewSlideoutRoute
                        path={`${AdminNavLink.Organizations}/:organizationId(\\d+)/${OrganizationTab.Emails}/:emailId(\\d+)`}
                    >
                        {({ emailId }) => (
                            <EmailPreviewSlideout
                                emailId={emailId}
                                onClose={() =>
                                    goBack(
                                        `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.Emails}`,
                                    )
                                }
                            />
                        )}
                    </EmailPreviewSlideoutRoute>
                    <SubscriptionRoute
                        path={`${AdminNavLink.Organizations}/:organizationId(\\d+)/${OrganizationTab.Subscriptions}/:subscriptionId(\\d+)/edit`}
                    >
                        {({ subscriptionId }) => (
                            <UpdateSubscriptionSlideoutContainer
                                organizationId={organizationId}
                                subscriptionId={subscriptionId}
                                onClose={subscriptionGoBack}
                            />
                        )}
                    </SubscriptionRoute>
                    <OrganizationTabRoute
                        path={`${AdminNavLink.Organizations}/:organizationId(\\d+)/:organizationTab`}
                    >
                        {({ organizationTab }) => (
                            <OrganizationDetailsPage
                                organizationId={organizationId}
                                organizationTab={organizationTab}
                                onOpenEditOrganization={handleOpenEditOrganization}
                                onOpenCreateUser={handleOpenCreateUser}
                                onInvoiceClick={handleOpenInvoice}
                                onItemClick={handleOpenItem}
                                onOpenUser={handleOpenUser}
                                getCreateBillingEntityLink={getCreateBillingEntityLink}
                                onOpenBillingEntity={navigateBillingEntityDetails}
                                onUpdateContractSetting={handleUpdateContractSetting}
                                onUpdateAddonConfiguration={handleUpdateAddonConfiguration}
                                onCreateSubscription={handleOpenCreateSubscription}
                                onSubscriptionClick={handleOpenEditSubscription}
                                onOpenEmail={handleOpenEmail}
                                onChangeTab={organizationTab =>
                                    handleChangeTab(organizationId, organizationTab)
                                }
                            />
                        )}
                    </OrganizationTabRoute>
                    <ItemSlideoutRouter
                        base={`${AdminNavLink.Organizations}/:organizationId(\\d+)/${OrganizationTab.Devices}`}
                    />
                </>
            )}
        </OrganizationIdRoute>
    );
};
