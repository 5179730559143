import { isEmpty } from 'lodash';

import { BillingEntityDto, getDefaultBillingEntity } from '@hofy/api-admin';
import { isValidRegCompanyNumber } from '@hofy/global';
import { errorMap, isNotEmpty, isValidHTTPSURL } from '@hofy/helpers';
import { useForm } from '@hofy/ui';

import {
    emptyEditOrganizationFormData,
    UpdateOrganizationFormData,
} from '../../services/organizations/types/UpdateOrganizationFormData';

export const useUpdateOrganizationForm = (
    isOrganizationActive: boolean,
    onSubmit: (data: UpdateOrganizationFormData) => void,
) => {
    return useForm<UpdateOrganizationFormData>({
        initial: emptyEditOrganizationFormData,
        onSubmit,
        validate: ({
            name,
            creditLimit,
            sassFee,
            laptopConfigFee,
            mdmFee,
            billingEntities,
            storefrontFee,
            deviceResetInstructionsLink,
        }) => {
            return {
                name: errorMap([isEmpty(name), 'Organization name is required']),
                creditLimit: errorMap([
                    creditLimit !== null && Number(creditLimit.amount) < 0,
                    'Credit limit cannot be negative.',
                ]),
                sassFee: errorMap([Number(sassFee.amount) < 0, 'SAAS fee can not be negative.']),
                laptopConfigFee: errorMap([
                    laptopConfigFee !== null && Number(laptopConfigFee.amount) < 0,
                    'Configuration fee cannot be negative.',
                ]),
                mdmFee: errorMap([
                    mdmFee !== null && Number(mdmFee.amount) < 0,
                    'MDM fee cannot be negative.',
                ]),
                storefrontFee: errorMap([isEmpty(storefrontFee), 'Organization storefront fee is required']),
                billingEntities: errorMap(
                    [
                        isOrganizationActive && !getDefaultBillingEntity(billingEntities),
                        'No default billing entity set',
                    ],
                    [
                        isOrganizationActive &&
                            !getDefaultBillingEntity(billingEntities)?.billingAddress?.country,
                        'Billing entity does not have country set',
                    ],
                    [
                        isOrganizationActive && !validateDefaultBillingEntity(billingEntities),
                        'Billing entity has wrong address or company number',
                    ],
                ),
                deviceResetInstructionsLink: errorMap([
                    isNotEmpty(deviceResetInstructionsLink) && !isValidHTTPSURL(deviceResetInstructionsLink),
                    'Custom device reset instructions link must be a valid URL that begins with https://',
                ]),
            };
        },
    });
};

const validateDefaultBillingEntity = (billingEntities: BillingEntityDto[]): boolean => {
    const defaultBillingEntity = getDefaultBillingEntity(billingEntities);
    if (!defaultBillingEntity?.billingAddress) {
        return false;
    }
    return !(
        isEmpty(defaultBillingEntity.billingAddress.line1) ||
        isEmpty(defaultBillingEntity.billingAddress.postCode) ||
        isEmpty(defaultBillingEntity.billingAddress.city) ||
        !isValidRegCompanyNumber(
            defaultBillingEntity.registeredCompanyNumber,
            defaultBillingEntity.billingAddress.country,
        )
    );
};
