import React, { FC, useState } from 'react';

import {
    getAvailableRentalTerms,
    PaymentSchema,
    Permission,
    useTrOperatingSystem,
    useTrPaymentSchema,
    useTrRentalTermFull,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { SlideoutContent, SlideoutTabbedHeader } from '@hofy/common';
import { Badge, ErrorItem, FormContainer, Tab, Tabs, UseForm } from '@hofy/ui';

import { Image } from '../../../components/design/imageEditor/types/Image';
import { OrganizationDetailsDto } from '../../../services/organizations/types/OrganizationDto';
import { UpdateOrganizationFormData } from '../../../services/organizations/types/UpdateOrganizationFormData';
import { OrganizationUpdateTab } from '../../../store/items/types/OrganizationUpdateTab';
import { OrganizationUpdateDetailsTab } from './tabs/OrganizationUpdateDetailsTab';
import { OrganizationUpdateFeaturesTab } from './tabs/OrganizationUpdateFeaturesTab';
import { OrganizationUpdatePlatformTierConfigTab } from './tabs/OrganizationUpdatePlatformTierConfigTab';
import { OrganizationUpdatePricingTab } from './tabs/OrganizationUpdatePricingTab';

interface EditOrganizationFormProps {
    form: UseForm<UpdateOrganizationFormData>;
    organization: OrganizationDetailsDto;
}

export const UpdateOrganizationForm: FC<EditOrganizationFormProps> = ({ form, organization }) => {
    const trPaymentSchema = useTrPaymentSchema();
    const trRentalTerm = useTrRentalTermFull();
    const trOperatingSystem = useTrOperatingSystem();

    const { hasPermission } = useSession();
    const canUpdateOrganization = hasPermission(Permission.AdminOrganizationUpdate);
    const canUpdateOrganizationStoreAndReuseManagedByHofy = hasPermission(
        Permission.AdminOrganizationUpdateMdm,
    );
    const canUpdateOrganizationFinancialSettings = hasPermission(
        Permission.AdminOrganizationUpdateFinancialSettings,
    );
    const canUpdateOrganizationModelSettings = hasPermission(Permission.AdminOrganizationUpdateModelSettings);

    const { allowedBillingFrequencies, currency, platformTierPrices, platformTierConfigFeatures } =
        organization;

    const isPaymentSchemaUpfront = (schema: PaymentSchema) =>
        schema === PaymentSchema.Upfront && allowedBillingFrequencies.includes(schema);

    const showSchemaWarning = (schema: PaymentSchema) =>
        form.values.usedPaymentSchemas.includes(schema) &&
        !form.values.allowedBillingFrequencies.includes(schema) &&
        canUpdateOrganizationFinancialSettings &&
        !isPaymentSchemaUpfront(schema);

    const setLogo = (images: Image[]) => {
        const activeImages = images.filter(({ isActive }) => !!isActive);
        if (!activeImages.length) {
            form.setValues({
                logoUrl: null,
            });
        } else {
            form.setValues({
                logoUrl: activeImages[activeImages.length - 1].url,
            });
        }
    };

    const availableRentalTerms = getAvailableRentalTerms(form.values.unbundlingEnabled);

    const [tab, setTab] = useState(OrganizationUpdateTab.Details);

    const renderContent = () => {
        switch (tab) {
            case OrganizationUpdateTab.Details:
                return (
                    <OrganizationUpdateDetailsTab
                        form={form}
                        canUpdateOrganization={canUpdateOrganization}
                        canUpdateOrganizationModelSettings={canUpdateOrganizationModelSettings}
                        setLogo={setLogo}
                    />
                );
            case OrganizationUpdateTab.Features:
                return (
                    <OrganizationUpdateFeaturesTab
                        form={form}
                        canUpdateOrganization={canUpdateOrganization}
                        canUpdateOrganizationStoreAndReuse={canUpdateOrganizationStoreAndReuseManagedByHofy}
                        trOperatingSystem={trOperatingSystem}
                    />
                );
            case OrganizationUpdateTab.Pricing:
                return (
                    <OrganizationUpdatePricingTab
                        form={form}
                        canUpdateOrganization={canUpdateOrganization}
                        canUpdateOrganizationFinancialSettings={canUpdateOrganizationFinancialSettings}
                        isPaymentSchemaUpfront={isPaymentSchemaUpfront}
                        showSchemaWarning={showSchemaWarning}
                        currency={currency}
                        availableRentalTerms={availableRentalTerms}
                        trPaymentSchema={trPaymentSchema}
                        trRentalTerm={trRentalTerm}
                    />
                );
            case OrganizationUpdateTab.PlatformTierConfig:
                return (
                    <OrganizationUpdatePlatformTierConfigTab
                        form={form}
                        platformTierPrices={platformTierPrices}
                        platformTierConfigFeatures={platformTierConfigFeatures}
                        currency={currency}
                    />
                );
            default:
                return null;
        }
    };

    const detailsTabBadgeCount = getErrorsCount([form.errors.name, form.errors.storefrontFee]);
    const pricingTabBadgeCount = getErrorsCount([
        form.errors.billingEntities,
        form.errors.creditLimit,
        form.errors.laptopConfigFee,
        form.errors.mdmFee,
    ]);
    const platformTierConfigTabBadgeCount = getErrorsCount([form.errors.sassFee]);

    return (
        <>
            <SlideoutTabbedHeader
                title='Organisation settings'
                tabsSlot={
                    <Tabs active={tab} onChange={tab => setTab(tab)}>
                        <Tab
                            id={OrganizationUpdateTab.Details}
                            label='Details'
                            rightSlot={
                                detailsTabBadgeCount > 0 && (
                                    <Badge label={detailsTabBadgeCount.toString()} color='red' />
                                )
                            }
                        />
                        <Tab id={OrganizationUpdateTab.Features} label='Features' />
                        <Tab
                            id={OrganizationUpdateTab.Pricing}
                            label='Pricing and Terms'
                            rightSlot={
                                pricingTabBadgeCount > 0 && (
                                    <Badge label={pricingTabBadgeCount.toString()} color='red' />
                                )
                            }
                        />
                        <Tab
                            id={OrganizationUpdateTab.PlatformTierConfig}
                            label='Platform tier config'
                            rightSlot={
                                platformTierConfigTabBadgeCount > 0 && (
                                    <Badge label={platformTierConfigTabBadgeCount.toString()} color='red' />
                                )
                            }
                        />
                    </Tabs>
                }
            />
            <SlideoutContent>
                <FormContainer marginTop={30} marginBottom={40}>
                    {renderContent()}
                </FormContainer>
            </SlideoutContent>
        </>
    );
};

const getErrorsCount = (errors: (ErrorItem | undefined)[]) => {
    return errors.filter(error => error !== undefined).length;
};
