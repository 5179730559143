export const assetActionDisabledReasonI18n = {
    'asset-action-disabled-reason.owned-by-organization': 'Asset is owned by organization',
    'asset-action-disabled-reason.owned-by-partner-organization': 'Asset is owned by partner organization',
    'asset-action-disabled-reason.owned-by-hofy': 'Asset is owned by owned by Hofy',
    'asset-action-disabled-reason.controlled-by-hofy': 'Asset is controlled by Hofy',
    'asset-action-disabled-reason.not-with-assignment': 'Asset not linked to an assignment',
    'asset-action-disabled-reason.with-pending-assignment': 'Asset linked to a pending assignment',
    'asset-action-disabled-reason.with-non-delivered-assignment':
        'Asset linked to a non-delivered assignment',
    'asset-action-disabled-reason.with-non-collected-assignment':
        'Asset linked to a non-collected assignment',
    'asset-action-disabled-reason.not-available-for-reuse': 'Asset is not available for reuse',
    'asset-action-disabled-reason.with-loaner-assignment': 'Asset is linked to a loaner assignment',
    'asset-action-disabled-reason.not-a-rental': 'Asset is not a rental',
    'asset-action-disabled-reason.not-at-warehouse': 'Asset is not at warehouse',
    'asset-action-disabled-reason.at-hofy-warehouse': 'Asset is at Hofy warehouse',
    'asset-action-disabled-reason.not-with-user': 'Asset is not with a user',
    'asset-action-disabled-reason.being-repaired': 'Asset is currently being repaired',
    'asset-action-disabled-reason.furniture': 'Not available for furniture',
    'asset-action-disabled-reason.non-device-storage': 'Hofy can only store devices for you at this time',
    'asset-action-disabled-reason.non-device-clearance': 'Hofy can only clear devices for you at this time',
    'asset-action-disabled-reason.consumable': 'Not available for consumables',
    'asset-action-disabled-reason.no-location-for-storage': 'Storage not available for asset location',
    'asset-action-disabled-reason.no_logistics_for_storage':
        'Storage not available as the asset cannot be transferred',
    'asset-action-disabled-reason.archived': 'Asset is archived',
    'asset-action-disabled-reason.coming-soon': 'Coming soon',
    'asset-action-disabled-reason.wrong-terms-and-conditions':
        'This feature is not available on your terms & conditions',
    'asset-action-disabled-reason.unknown': 'Please contact support for assistance',
    'asset-action-disabled-reason.wrong-assignment-status': 'Wrong assignment status',
    'asset-action-disabled-reason.wrong-contract-status': 'Wrong contract status',
    'asset-action-disabled-reason.wrong-contract-type': 'Wrong contract type',
    'asset-action-disabled-reason.ending-contract': 'Ending contract',
};
