import React, { FC } from 'react';

import { getPurchaseOrderStatusDate, PurchaseOrderDetailsDto } from '@hofy/api-admin';
import { Permission, useTrRole } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { formatDate } from '@hofy/helpers';
import { Alert, Box, Button, OuterBoxProps, Paragraph3 } from '@hofy/ui';

import { usePurchaseOrderApproval } from '../../../../../store/purchaseOrders/usePurchaseOrderApproval';
import { useTrPurchaseOrderStatus } from '../../../../../store/purchaseOrders/useTrPurchaseOrderStatus';

interface PurchaseOrderReviewNotificationProps extends OuterBoxProps {
    purchaseOrder: PurchaseOrderDetailsDto;
}

interface ApprovalStatus {
    isReady: boolean;
    byRoles?: string[];
}

export const PurchaseOrderStatusNotification: FC<PurchaseOrderReviewNotificationProps> = ({
    purchaseOrder,
    ...boxProps
}) => {
    const { approvePurchaseOrder, declinePurchaseOrder } = usePurchaseOrderApproval(purchaseOrder.id);
    const { hasPermission, hasRole } = useSession();
    const trRole = useTrRole();
    const hasPurchaseOrderApprovalPermission = hasPermission(Permission.AdminPurchaseOrdersApprove);
    const trPurchaseOrderStatus = useTrPurchaseOrderStatus();

    const readyForApproval = (): ApprovalStatus => {
        const needsReviewBy = purchaseOrder.purchaseOrderApprovals.find(
            approval => !approval.approvedBy && !approval.declinedBy,
        );

        if (!needsReviewBy) {
            return {
                isReady: false,
                byRoles: ['an authorized user'],
            };
        }

        const canApprovePurchaseOrder = !!needsReviewBy.requiredRole.find(role => hasRole(role));
        const transformedRoles = needsReviewBy.requiredRole.flatMap(role => trRole(role));

        return {
            isReady: canApprovePurchaseOrder,
            byRoles: transformedRoles,
        };
    };

    const reviewButtons = () => {
        const userApproval = readyForApproval();

        if (hasPurchaseOrderApprovalPermission && userApproval.isReady) {
            return (
                <Box row gap={10}>
                    <Button
                        type='secondary'
                        width={150}
                        label='Decline'
                        onClick={() => declinePurchaseOrder()}
                    />
                    <Button width={150} label='Approve' onClick={() => approvePurchaseOrder()} />
                </Box>
            );
        }

        return (
            <Box>
                <Paragraph3>{`The purchase order needs approval from a ${userApproval.byRoles?.join(
                    ' or ',
                )}.`}</Paragraph3>
            </Box>
        );
    };

    return (
        <Alert
            type='warning'
            title={trPurchaseOrderStatus(purchaseOrder.status)}
            description={formatDate(getPurchaseOrderStatusDate(purchaseOrder, purchaseOrder.status))}
            {...boxProps}
        >
            {reviewButtons()}
        </Alert>
    );
};
