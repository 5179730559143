import React, { FC } from 'react';

import { SubscriptionDto } from '@hofy/api-admin';
import { orgBasedPlatformTiers } from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { AsyncButton, Button, SvgIcon } from '@hofy/ui';

import { OrganizationDetailsDto } from '../../../services/organizations/types/OrganizationDto';
import { useUpdateSubscription } from '../../../store/subscription/useUpdateSubscription';
import { SubscriptionForm } from '../components/SubscriptionForm';

interface UpdateSubscriptionSlideoutPorps {
    organization: OrganizationDetailsDto;
    subscription: SubscriptionDto;
    onClose(): void;
}

export const UpdateSubscriptionSlideout: FC<UpdateSubscriptionSlideoutPorps> = ({
    organization,
    subscription,
    onClose,
}) => {
    const { form, isLoading, isError } = useUpdateSubscription(organization, subscription, onClose);
    const organizationBased = orgBasedPlatformTiers.includes(organization.platformTierConfig.platformTier);

    return (
        <Slideout width={600} onClose={onClose} slideoutId='edit-subscription'>
            <SlideoutHeader title='Edit subscription' />
            <SlideoutContent>
                <SubscriptionForm form={form} organizationBased={organizationBased} isEditing />
            </SlideoutContent>
            <SlideoutFooter>
                <Button
                    leftIcon={SvgIcon.Cross}
                    label='Cancel'
                    type='ghost'
                    negativeMargin
                    onClick={onClose}
                />
                <AsyncButton
                    label='Save'
                    onClick={form.submit}
                    isLoading={isLoading}
                    isError={isError}
                    disableCheck
                />
            </SlideoutFooter>
        </Slideout>
    );
};
