import { xor } from 'lodash';
import React, { FC, useState } from 'react';

import { OrganizationRefDto } from '@hofy/api-shared';
import { useSearch } from '@hofy/common';
import { SvgIcon } from '@hofy/ui';

import { SearchableBlockFilter } from '../../design/blockFilters/SearchableBlockFilter';

interface OrganizationBlockFilterProps {
    selected: number[];
    organizations: OrganizationRefDto[];
    onChange(ids: number[]): void;
}

export const OrganizationBlockFilter: FC<OrganizationBlockFilterProps> = ({
    selected,
    organizations,
    onChange,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const { results } = useSearch(organizations, org => `${org.id} ${org.name}`, searchQuery);

    return (
        <SearchableBlockFilter
            title='Organization'
            icon={SvgIcon.Building}
            items={results}
            isSelected={org => selected.some(id => id === org.id)}
            onFilterClick={org => onChange(xor(selected, [org.id]))}
            renderItem={org => org.name}
            searchPlaceholder='Search organization'
            search={searchQuery}
            onSearch={setSearchQuery}
        />
    );
};
