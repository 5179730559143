import React, { FC } from 'react';

import { UserRefDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

import { AdminUserSelector } from '../../../components/domain/user/AdminUserSelector';
import { useUpdateInspectionAssignee } from '../../../store/inspections/useUpdateInspectionAssignee';

interface InspectionAdminSelectorProps {
    inspectionUuid: UUID;
    assignedTo: UserRefDto | null;
}

export const InspectionAdminSelector: FC<InspectionAdminSelectorProps> = ({ inspectionUuid, assignedTo }) => {
    const { assignInspection } = useUpdateInspectionAssignee(inspectionUuid);
    return <AdminUserSelector user={assignedTo} onUserChange={assignInspection} />;
};
