import React, { FC } from 'react';

import { allOrganizationSizes, Role, useTrOrganizationSize } from '@hofy/api-shared';
import { FormFractionInput } from '@hofy/common';
import { zeroUuid } from '@hofy/global';
import { Box, FormInput, FormSection, FormSelect, Paragraph3, UseForm } from '@hofy/ui';

import { ImageEditor } from '../../../../components/design/imageEditor/ImageEditor';
import { Image } from '../../../../components/design/imageEditor/types/Image';
import { UpdateOrganizationFormData } from '../../../../services/organizations/types/UpdateOrganizationFormData';
import { OrganizationUsersListByRoleDropdown } from '../../components/OrganizationUsersListByRoleDropdown';
import { OrganizationModelDropdown } from '../OrganizationModelDropdown';

interface OrganizationUpdateDetailsTabProps {
    form: UseForm<UpdateOrganizationFormData>;
    canUpdateOrganization: boolean;
    canUpdateOrganizationModelSettings: boolean;
    setLogo(images: Image[]): void;
}

export const OrganizationUpdateDetailsTab: FC<OrganizationUpdateDetailsTabProps> = ({
    form,
    canUpdateOrganization,
    canUpdateOrganizationModelSettings,
    setLogo,
}) => {
    const trOrganizationSize = useTrOrganizationSize();

    return (
        <>
            <Paragraph3 bold>Organisation details</Paragraph3>
            <FormInput
                disabled={!canUpdateOrganization}
                label='Organisation name'
                api={form.fields.name}
                isRequired
            />
            <FormSelect
                disabled={!canUpdateOrganization}
                label='Organisation size'
                api={form.fields.size}
                options={allOrganizationSizes}
                toText={trOrganizationSize}
            />

            <Paragraph3>Logo</Paragraph3>
            <ImageEditor
                images={
                    form.values.logoUrl
                        ? [
                              {
                                  url: form.values.logoUrl,
                                  isActive: true,
                                  uuid: zeroUuid,
                              },
                          ]
                        : []
                }
                onChange={setLogo}
                showDeleted
            />

            <FormSection label='Organization model'>
                <OrganizationModelDropdown
                    unbundlingEnabled={form.values.unbundlingEnabled}
                    disabled={!canUpdateOrganization || !canUpdateOrganizationModelSettings}
                    isError={form.errors.unbundlingEnabled}
                    onChange={form.setValues}
                />

                {form.values.unbundlingEnabled && (
                    <Box width={150} row>
                        <FormFractionInput
                            label='Store fee (default 25%)'
                            unit='%'
                            disabled={!canUpdateOrganization || !canUpdateOrganizationModelSettings}
                            isError={!!form.errors.storefrontFee}
                            errorMessage={form.errors.storefrontFee}
                            value={form.values.storefrontFee}
                            onChange={storeFrontFee =>
                                form.setValues({
                                    storefrontFee: storeFrontFee,
                                })
                            }
                        />
                    </Box>
                )}
            </FormSection>

            <FormSection label='Sales attribution'>
                <OrganizationUsersListByRoleDropdown
                    role={Role.SalesAdmin}
                    label='Sales representative'
                    value={form.values.salesAdminId}
                    onChange={id =>
                        form.setValues({
                            salesAdminId: id,
                        })
                    }
                    isError={form.errors.salesAdminId}
                />

                <OrganizationUsersListByRoleDropdown
                    role={Role.SuccessAdmin}
                    label='Account manager'
                    value={form.values.successAdminId}
                    onChange={id =>
                        form.setValues({
                            successAdminId: id,
                        })
                    }
                    isError={form.errors.successAdminId}
                />
            </FormSection>
        </>
    );
};
