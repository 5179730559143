export enum OrganizationError {
    TeamLimitReached = 'team_limit_reached',
    BillingEntityLimitReached = 'billing_entity_limit_reached',
    CannotUpdateBillingEntityCurrency = 'cannot_update_billing_entity_currency',
    BillingEntityCurrencyUpdateInvalidArgument = 'invalid_argument_for_billing_entity_currency_update',
    InvoiceCountriesUsedInOtherEntity = 'invoice_countries_used_in_other_entity',
    DuplicateBillingEntityName = 'billing_entity_with_this_name_already_exists',
    TwoSync = 'two_sync_failed',
    AccountingSync = 'accounting_sync_failed',
    InvalidBillingEntityAccountingData = 'invalid_billing_entity_accounting_data',
    OrganizationWithoutDefaultEntity = 'organization_without_default_billing_entity',
    BillingEntityUpdateNonFreeTier = 'update_non_free_tier_organization_billing_entity',
    InvalidSubscriptionPrice = 'invalid_subscription_price',
    SubscriptionAlreadyExists = 'subscription_already_exists',
    SubscriptionAlreadyInvoiced = 'subscription_already_invoiced',
    InvalidSubscriptionBillingFrequency = 'invalid_subscription_billing_frequency',
}
