import React, { FC } from 'react';

import { SupplierDetailDto, VariantWithProductAndPriceMap } from '@hofy/api-admin';
import { FormFractionInput, FormInput, FormNumberInput } from '@hofy/common';
import {
    Currency,
    isPositive,
    isPriceGreaterThan,
    isPurchaseTaxReclaimableCountry,
    minusPrice,
    numberToPercent,
    Price,
    priceToNumber,
    VisualType,
} from '@hofy/global';
import { usePrice } from '@hofy/hooks';
import { ProductImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import {
    BaseTable,
    Box,
    CompactAlert,
    defaultRowRenderer,
    FormContainer,
    FormGridRow,
    IconButton,
    LabeledDateInput,
    LabeledPriceInput,
    Paragraph3,
    SvgIcon,
    TableRowRendererProps,
} from '@hofy/ui';

import { PurchaseOrderFormItemData } from '../../../../store/purchaseOrders/types/PurchaseOrderFormData';
import { CreatePurchaseOrderForm } from '../../../../store/purchaseOrders/useCreatePurchaseOrder';
import { PurchaseOrderNewItemButton } from './components/PurchaseOrderNewItemButton';
import { PurchaseOrderPriceInput } from './components/PurchaseOrderPriceInput';
import { PurchaseOrderSummaryLine } from './components/PurchaseOrderSummaryLine';
import { VariantDropdown } from './components/VariantDropdown';

interface ItemsFormProps {
    orderForm: CreatePurchaseOrderForm;
    isEdit?: boolean;
    disableFields: boolean;
    variantsById: VariantWithProductAndPriceMap;
    isLoading: boolean;
}

export const ItemsForm: FC<ItemsFormProps> = ({
    isEdit,
    disableFields,
    orderForm,
    variantsById,
    isLoading,
}) => {
    const {
        form,
        updateItemQuantity,
        updateItemNotes,
        updateItemWarranty,
        updateItemUnitPrice,
        updateItemTaxRate,
        updateItemSupplierCode,
        updateItemVariant,
        updateItemEstimatedReceiveOn,
        deleteItem,
        addItem,
        supplier,
    } = orderForm;
    const { formatPrice } = usePrice();
    return (
        <FormContainer marginTop={10} marginBottom={10}>
            <BaseTable
                data={form.values.items}
                toKey={(_, index) => index}
                rowHorizontalPadding={0}
                headerHorizontalPadding={0}
                rowRenderer={item => {
                    return (
                        <ItemTableRowRenderer
                            key={item.key}
                            displayCurrency={form.values.currency}
                            supplier={supplier!}
                            tableRowRendererProps={item}
                            variantsById={variantsById}
                        />
                    );
                }}
                emptyContent={<Paragraph3 paddingTop={12}>No items</Paragraph3>}
                fullSize
                isLoading={isLoading}
                columns={[
                    {
                        id: 'variant',
                        flexGrow: 0,
                        width: 150,
                        renderer: v => (
                            <ProductImage
                                image={v.variant ? variantsById[v.variant].image : null}
                                size={115}
                                marginRight={20}
                                marginTop={24}
                            />
                        ),
                    },
                    {
                        id: 'row',
                        flexGrow: 1,
                        renderer: (v, index) => {
                            const itemError = form.errors.items?.at(index);
                            const priceAndTargetState = getPriceAndTargetState({
                                supplier: supplier!,
                                variantsById,
                                unitNetPrice: v.unitNetPrice,
                                totalGrossPrice: v.totalGrossPrice,
                                quantity: v.quantity,
                                variantId: v.variant,
                            });
                            return (
                                <FormGridRow columns={5}>
                                    <VariantDropdown
                                        variant={v.variant}
                                        onVariantSelected={(variant, name) =>
                                            updateItemVariant(index, variant, name)
                                        }
                                        disabled={(!isEdit && form.values.isDropship) || disableFields}
                                        isError={itemError?.variant}
                                    />
                                    <FormInput
                                        label='Product'
                                        disabled
                                        value={v.name ?? '--'}
                                        onChangeText={() => {}}
                                    />
                                    <FormInput
                                        label='Supplier code'
                                        value={v.supplierCode}
                                        onChangeText={description =>
                                            updateItemSupplierCode(index, description)
                                        }
                                        nullable
                                        disabled={disableFields}
                                        isError={itemError?.supplierCode}
                                    />
                                    <FormNumberInput
                                        label='Warranty (y)'
                                        value={v.warranty}
                                        nullable
                                        onChange={warranty => updateItemWarranty(index, warranty)}
                                        disabled={disableFields}
                                        isError={!!itemError?.warranty}
                                        errorMessage={itemError?.warranty}
                                    />
                                    <LabeledDateInput
                                        label='Delivery'
                                        value={v.estimatedReceiveOn ?? form.values.estimatedReceiptOn ?? null}
                                        onChange={time => updateItemEstimatedReceiveOn(index, time)}
                                        disabled={disableFields}
                                        nullable
                                        marginBottom={20}
                                    />
                                    <FormNumberInput
                                        label='Quantity'
                                        value={v.quantity}
                                        disabled={(!isEdit && form.values.isDropship) || disableFields}
                                        onChange={quantity => updateItemQuantity(index, quantity)}
                                        isError={!!itemError?.quantity}
                                        errorMessage={itemError?.quantity}
                                    />
                                    <LabeledPriceInput
                                        label='Unit net price'
                                        value={v.unitNetPrice}
                                        onChange={unitPrice => updateItemUnitPrice(index, unitPrice)}
                                        disabled={disableFields}
                                        errorMessage={itemError?.unitPrice}
                                        helperText={
                                            priceAndTargetState.status ===
                                            TargetPurchasePriceStatus.PriceIsNet
                                                ? `Target price: ${formatPrice(
                                                      priceAndTargetState.unitNetTargetPrice,
                                                  )}`
                                                : undefined
                                        }
                                    />
                                    <FormFractionInput
                                        label='Tax rate'
                                        unit='%'
                                        value={v.taxRate}
                                        onChange={taxRate => updateItemTaxRate(index, taxRate)}
                                        disabled={disableFields}
                                        isError={itemError?.taxRate}
                                    />
                                    <LabeledPriceInput
                                        label='Total gross price'
                                        value={v.totalGrossPrice}
                                        disabled
                                        onChange={() => {}}
                                        helperText={
                                            priceAndTargetState.status ===
                                            TargetPurchasePriceStatus.PriceIsGross
                                                ? `Target price: ${formatPrice(
                                                      priceAndTargetState.totalGrossTargetPrice,
                                                  )}`
                                                : undefined
                                        }
                                    />
                                    <FormInput
                                        label='Notes'
                                        value={v.notes}
                                        onChangeText={notes => updateItemNotes(index, notes)}
                                        nullable
                                        disabled={disableFields}
                                    />
                                </FormGridRow>
                            );
                        },
                    },
                    {
                        id: 'delete',
                        width: 30,
                        flexGrow: 0,
                        renderer: (_, index) => (
                            <IconButton
                                icon={SvgIcon.Trash}
                                onClick={() => deleteItem(index)}
                                disabled={disableFields}
                            />
                        ),
                    },
                ]}
            />
            {form.errors.noItemsError && (
                <Paragraph3 flex={1} justify='flex-end' color={Color.FoundationNegative} row marginRight={24}>
                    {form.errors.noItemsError}
                </Paragraph3>
            )}
            {!form.values.isDropship && (
                <PurchaseOrderNewItemButton
                    label='Add new item'
                    icon={SvgIcon.Add}
                    onClick={addItem}
                    disabled={disableFields}
                />
            )}
            <PurchaseOrderSummaryLine label='Total order net value:' value={form.values.totalNetValue} />
            <PurchaseOrderPriceInput
                label='Shipping/handling cost:'
                value={form.values.totalHandlingCost}
                onChange={p =>
                    form.setValues({
                        totalHandlingCost: p,
                    })
                }
                disabled={disableFields}
                errorMessage={form.errors.totalHandlingCost}
            />
            <PurchaseOrderSummaryLine
                label='Total order value:'
                subLabel='Incl. tax and shipping cost'
                value={form.values.totalValue}
            />
            {form.errors.itemsGenericError && (
                <Paragraph3 flex={1} justify='flex-end' color={Color.FoundationNegative} row marginRight={24}>
                    {form.errors.itemsGenericError}
                </Paragraph3>
            )}
        </FormContainer>
    );
};

// Target purchase price state

enum TargetPurchasePriceStatus {
    NoSelectedVariant = 'no_selected_variant',
    NoExchangeRate = 'no_exchange_rate',
    PriceIsGross = 'price_is_gross',
    PriceIsNet = 'price_is_net',
}

type PriceAndTargetState =
    | {
          status: TargetPurchasePriceStatus.NoSelectedVariant;
      }
    | {
          status: TargetPurchasePriceStatus.NoExchangeRate;
      }
    | {
          status: TargetPurchasePriceStatus.PriceIsGross;
          totalGrossTargetPrice: Price;
          totalGrossPrice: Price;
      }
    | {
          status: TargetPurchasePriceStatus.PriceIsNet;
          unitNetTargetPrice: Price;
          unitNetPrice: Price;
      };

interface PriceAndTargetStateProps {
    supplier: SupplierDetailDto;
    variantsById: VariantWithProductAndPriceMap;
    unitNetPrice: Price;
    totalGrossPrice: Price;
    quantity: number;
    variantId: number | null;
}

const getPriceAndTargetState = ({
    supplier,
    variantsById,
    unitNetPrice,
    totalGrossPrice,
    quantity,
    variantId,
}: PriceAndTargetStateProps): PriceAndTargetState => {
    if (!variantId) {
        return {
            status: TargetPurchasePriceStatus.NoSelectedVariant,
        };
    }

    const targetPurchasePrice = variantsById[variantId].targetPurchasePrice;
    if (!targetPurchasePrice) {
        return {
            status: TargetPurchasePriceStatus.NoExchangeRate,
        };
    }

    if (isPurchaseTaxReclaimableCountry(supplier.billingAddress.country)) {
        return {
            status: TargetPurchasePriceStatus.PriceIsNet,
            unitNetTargetPrice: targetPurchasePrice,
            unitNetPrice,
        };
    }

    return {
        status: TargetPurchasePriceStatus.PriceIsGross,
        totalGrossTargetPrice: {
            currency: targetPurchasePrice.currency,
            amount: (priceToNumber(targetPurchasePrice) * quantity).toFixed(2),
        },
        totalGrossPrice,
    };
};

interface ItemTableRowRendererProps {
    displayCurrency: Currency;
    supplier: SupplierDetailDto;
    tableRowRendererProps: TableRowRendererProps<PurchaseOrderFormItemData>;
    variantsById: VariantWithProductAndPriceMap;
}

/**
 * Hackery required to add an alert below each row, given that `ItemsForm` uses `BaseTable`
 */
const ItemTableRowRenderer: FC<ItemTableRowRendererProps> = ({
    displayCurrency,
    supplier,
    tableRowRendererProps,
    variantsById,
}) => {
    const item = tableRowRendererProps.item;
    const priceAndTargetState = getPriceAndTargetState({
        supplier,
        variantsById,
        unitNetPrice: item.unitNetPrice,
        totalGrossPrice: item.totalGrossPrice,
        quantity: item.quantity,
        variantId: item.variant,
    });
    return (
        <Box column marginTop={tableRowRendererProps.index > 0 ? 16 : 0} rowGap={8}>
            {defaultRowRenderer(tableRowRendererProps)}
            <AlertByState
                displayCurrency={displayCurrency}
                unitPriceSet={isPositive(tableRowRendererProps.item.unitNetPrice)}
                priceAndTargetState={priceAndTargetState}
            />
        </Box>
    );
};

interface AlertByStateProps {
    displayCurrency: Currency;
    unitPriceSet: boolean;
    priceAndTargetState: PriceAndTargetState;
}

const AlertByState: FC<AlertByStateProps> = ({ displayCurrency, unitPriceSet, priceAndTargetState }) => {
    if (priceAndTargetState.status === TargetPurchasePriceStatus.NoSelectedVariant) {
        return null;
    }

    if (priceAndTargetState.status === TargetPurchasePriceStatus.NoExchangeRate) {
        return (
            <CompactAlert
                icon={SvgIcon.AlertTriangle}
                type='negative'
                description={`Target purchase price not available: no exchange rate found for ${displayCurrency}`}
            />
        );
    }

    if (!unitPriceSet) {
        return null;
    }

    const { price, target } =
        priceAndTargetState.status === TargetPurchasePriceStatus.PriceIsGross
            ? {
                  price: priceAndTargetState.totalGrossPrice,
                  target: priceAndTargetState.totalGrossTargetPrice,
              }
            : {
                  price: priceAndTargetState.unitNetPrice,
                  target: priceAndTargetState.unitNetTargetPrice,
              };
    return <ItemTablePurchasePriceAlert price={price} targetPrice={target} />;
};

interface ItemTablePurchasePriceAlertProps {
    price: Price;
    targetPrice: Price;
}

const ItemTablePurchasePriceAlert = ({ price, targetPrice }: ItemTablePurchasePriceAlertProps) => {
    const alertProps = usePriceAlertProps(price, targetPrice);
    return <CompactAlert icon={SvgIcon.AlertCircle} {...alertProps} />;
};
const usePriceAlertProps = (
    price: Price,
    targetPrice: Price,
): {
    type: VisualType;
    description: string;
} => {
    const { formatPrice } = usePrice();
    const priceDifference = isPriceGreaterThan(price, targetPrice)
        ? minusPrice(price, targetPrice)
        : minusPrice(targetPrice, price);

    const priceAmount = priceToNumber(price);
    const targetAmount = priceToNumber(targetPrice);
    const percentOfTargetPrice = 100 + (100 * (priceAmount - targetAmount)) / targetAmount;

    if (percentOfTargetPrice < 80) {
        return {
            type: 'warning',
            description: `${formatPrice(priceDifference)} (${percentBelow(
                percentOfTargetPrice,
            )}%) less than the target price`,
        };
    }

    if (percentOfTargetPrice < 100) {
        return {
            type: 'informative',
            description: `${formatPrice(priceDifference)} (${percentBelow(
                percentOfTargetPrice,
            )}%) less than the target price`,
        };
    }

    if (percentOfTargetPrice === 100) {
        return {
            type: 'informative',
            description: `Price matches the target price`,
        };
    }

    return {
        type: 'warning',
        description: `${formatPrice(priceDifference)} (${percentOver(
            percentOfTargetPrice,
        )}%) greater than the target price`,
    };
};

const percentBelow = (num: number) => {
    return numberToPercent(100 - num);
};

const percentOver = (num: number) => {
    return numberToPercent(num - 100);
};
