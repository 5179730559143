import React, { FC } from 'react';

import {
    AuditableTable,
    OrganizationAddonConfigDetailsDto,
    useOrganizationAddonConfigQuery,
} from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenu, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import {
    Alert,
    Box,
    ErrorStatePlaceholder,
    Form,
    FormNumberInput,
    FormSwitch,
    Heading3,
    PageSkeleton,
    Paragraph3,
    SubmitButton,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useAuditLogMenuOption } from '../../../store/auditLogs/useAuditMenuOption';
import { useUpdateOrganizationAddonConfig } from '../../../store/organizationAddonConfigs/useUpdateOrganizationAddonConfig';

interface UpdateAddonConfigurationSlideoutProps {
    addonId: number;
    organizationId: number;
    onClose(): void;
}

export const UpdateAddonConfigurationSlideout: FC<UpdateAddonConfigurationSlideoutProps> = ({
    addonId,
    organizationId,
    onClose,
}) => {
    const { data, isLoading, isError } = useOrganizationAddonConfigQuery(organizationId, addonId);
    return (
        <Slideout width={600} onClose={onClose}>
            {isLoading || !data ? (
                <PageSkeleton />
            ) : isError ? (
                <ErrorStatePlaceholder />
            ) : (
                <UpdateAddonConfigurationSlideoutContent
                    addonId={addonId}
                    addonConfiguration={data}
                    organizationId={organizationId}
                    onClose={onClose}
                />
            )}
        </Slideout>
    );
};

interface UpdateAddonConfigurationSlideoutContentProps {
    addonId: number;
    addonConfiguration: OrganizationAddonConfigDetailsDto;
    organizationId: number;
    onClose(): void;
}

const UpdateAddonConfigurationSlideoutContent: FC<UpdateAddonConfigurationSlideoutContentProps> = ({
    addonId,
    addonConfiguration,
    organizationId,
    onClose,
}) => {
    const { form, isPending, isError } = useUpdateOrganizationAddonConfig(
        organizationId,
        addonId,
        addonConfiguration,
        onClose,
    );
    const { id, addonName, active } = addonConfiguration;
    const { hasPermission } = useSession();
    const canUpdate = hasPermission(Permission.AdminAddonDiscountsUpdate);
    return (
        <>
            <SlideoutHeader
                title={
                    <Box row gap={20}>
                        <Heading3>Edit {addonName} configuration</Heading3>
                        {id && <UpdateAddonConfigurationSlideoutMoreMenu configId={id} />}
                    </Box>
                }
            />
            <Form
                onSubmit={form.submit}
                isLoading={isPending}
                isError={isError}
                disabled={!canUpdate}
                column
                fullHeight
            >
                <SlideoutContent paddingVertical={40} column gap={24}>
                    <Box column gap={16}>
                        <FormSwitch
                            label='Active'
                            api={form.fields.active}
                            disabled={active.state && active.isReadonly}
                        />
                        {active.state && active.isReadonly && (
                            <Alert
                                type='informative'
                                description={`${addonName} is a public addon, so is always active`}
                            />
                        )}
                    </Box>
                    <FormNumberInput
                        label='Discount'
                        api={form.fields.discount}
                        rightSlot={<Paragraph3>%</Paragraph3>}
                    />
                    <FormNumberInput label='Max asset age (months)' api={form.fields.assetAgeMax} nullable />
                    <FormNumberInput
                        label='Enrollment duration (months)'
                        api={form.fields.enrollmentDuration}
                        nullable
                    />
                </SlideoutContent>
                <SlideoutFooter>
                    <CancelButton label='Cancel' onClick={onClose} />
                    <SubmitButton label='Save' />
                </SlideoutFooter>
            </Form>
        </>
    );
};

interface UpdateAddonConfigurationSlideoutHeaderProps {
    configId: number;
}

const UpdateAddonConfigurationSlideoutMoreMenu: FC<UpdateAddonConfigurationSlideoutHeaderProps> = ({
    configId,
}) => {
    const [auditMenuItem] = useAuditLogMenuOption(AuditableTable.AddonDiscounts, configId);
    const menuItems = [auditMenuItem];
    return <MoreMenu items={menuItems} />;
};
