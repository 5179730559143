import { xor } from 'lodash';
import React, { FC, ReactNode } from 'react';

import {
    allowedPaymentSchemasForPaymentType,
    allPaymentSchemasWithoutDeposit,
    allPaymentTypes,
    PaymentSchema,
    PaymentType,
    RentalTerm,
    useTrPaymentType,
} from '@hofy/api-shared';
import { Checkbox, FormRow, RadioGroup } from '@hofy/common';
import { Currency } from '@hofy/global';
import { Color } from '@hofy/theme';
import {
    Box,
    FormPriceInput,
    FormSection,
    Icon,
    Paragraph3,
    SvgIcon,
    UseForm,
    WarnTooltipIcon,
} from '@hofy/ui';

import { UpdateOrganizationFormData } from '../../../../services/organizations/types/UpdateOrganizationFormData';

interface OrganizationUpdatePricingTabProps {
    form: UseForm<UpdateOrganizationFormData>;
    canUpdateOrganization: boolean;
    canUpdateOrganizationFinancialSettings: boolean;
    isPaymentSchemaUpfront(schema: PaymentSchema): boolean;
    showSchemaWarning(schema: PaymentSchema): boolean;
    currency: Currency;
    availableRentalTerms: RentalTerm[];
    trPaymentSchema(v: PaymentSchema): ReactNode;
    trRentalTerm(v: RentalTerm): ReactNode;
}

export const OrganizationUpdatePricingTab: FC<OrganizationUpdatePricingTabProps> = ({
    form,
    canUpdateOrganization,
    canUpdateOrganizationFinancialSettings,
    isPaymentSchemaUpfront,
    showSchemaWarning,
    currency,
    availableRentalTerms,
    trPaymentSchema,
    trRentalTerm,
}) => {
    const isOnlyUpfrontSelected =
        form.values.allowedBillingFrequencies.length === 1 &&
        isPaymentSchemaUpfront(form.values.allowedBillingFrequencies[0]);

    const trPaymentType = useTrPaymentType();
    const isPaymentTypePrepaid = form.values.paymentType === PaymentType.Prepaid;

    const handlePaymentTypeChange = (paymentType: PaymentType) => {
        const allowedBillingFrequencies = form.values.allowedBillingFrequencies.filter(b => {
            const allowedBillingFrequenciesForPaymentType =
                allowedPaymentSchemasForPaymentType.get(paymentType);
            return allowedBillingFrequenciesForPaymentType?.includes(b);
        });
        form.setValues({
            paymentType,
            allowedBillingFrequencies,
        });
    };

    return (
        <>
            <FormSection label='Pricing'>
                <FormRow>
                    <FormPriceInput
                        label='Config fee'
                        disabled={!canUpdateOrganization || !canUpdateOrganizationFinancialSettings}
                        clearable
                        api={form.fields.laptopConfigFee}
                        defaultCurrency={currency}
                        nullable
                    />
                    <FormPriceInput
                        label='MDM Fee'
                        disabled={!canUpdateOrganization || !canUpdateOrganizationFinancialSettings}
                        clearable
                        defaultCurrency={currency}
                        api={form.fields.mdmFee}
                        nullable
                    />
                </FormRow>
            </FormSection>
            <FormSection
                label={
                    <TooltipLabel
                        title='Credit'
                        tooltip='Credit limit only applies to active organizations.'
                    />
                }
            >
                <FormPriceInput
                    label='Credit limit (Monthly)'
                    disabled={!canUpdateOrganization || !canUpdateOrganizationFinancialSettings}
                    clearable
                    nullable
                    api={form.fields.creditLimit}
                    defaultCurrency={currency}
                />
            </FormSection>
            <FormSection
                label={<TooltipLabel title='Payment type' tooltip='Allowed payment type for organization.' />}
            >
                <RadioGroup
                    spacing={20}
                    items={allPaymentTypes}
                    value={form.values.paymentType}
                    onChange={handlePaymentTypeChange}
                    labelFormatter={paymentType => <Paragraph3>{trPaymentType(paymentType)}</Paragraph3>}
                />
            </FormSection>
            <FormSection
                label={
                    <TooltipLabel
                        title='Allowed Billing Frequency'
                        tooltip='Only applies to active organizations.'
                    />
                }
            >
                {allPaymentSchemasWithoutDeposit.map(schema => (
                    <PaymentSchemaSection
                        key={schema}
                        schema={schema}
                        form={form}
                        isPaymentSchemaUpfront={isPaymentSchemaUpfront}
                        showSchemaWarning={showSchemaWarning}
                        trPaymentSchema={trPaymentSchema}
                        disabled={!canUpdateOrganizationFinancialSettings || isPaymentTypePrepaid}
                    />
                ))}
            </FormSection>
            <FormSection
                label={
                    <TooltipLabel
                        title='Allowed rental terms'
                        tooltip='Only applies to monthly & annual billing frequency.'
                    />
                }
            >
                {availableRentalTerms.map(rentalTerm => (
                    <Box key={rentalTerm} marginVertical={0}>
                        <Box>
                            <Checkbox
                                checked={
                                    isOnlyUpfrontSelected ||
                                    form.values.allowedRentalTerms.includes(rentalTerm)
                                }
                                onChange={() =>
                                    form.setValues({
                                        allowedRentalTerms: xor(form.values.allowedRentalTerms, [rentalTerm]),
                                    })
                                }
                                disabled={
                                    isOnlyUpfrontSelected ||
                                    !canUpdateOrganizationFinancialSettings ||
                                    !availableRentalTerms.includes(rentalTerm) ||
                                    isPaymentTypePrepaid
                                }
                            >
                                <Paragraph3>{trRentalTerm(rentalTerm)}</Paragraph3>
                            </Checkbox>
                        </Box>
                    </Box>
                ))}
            </FormSection>
        </>
    );
};

interface TooltipLabelProps {
    title: string;
    tooltip: string;
}

const TooltipLabel: FC<TooltipLabelProps> = ({ title, tooltip }) => {
    return (
        <Box row gap={4} inline>
            {title}
            <WarnTooltipIcon body={tooltip} />
        </Box>
    );
};

interface PaymentSchemaSectionProps {
    schema: PaymentSchema;
    form: UseForm<UpdateOrganizationFormData>;
    isPaymentSchemaUpfront(schema: PaymentSchema): boolean;
    showSchemaWarning(schema: PaymentSchema): boolean;
    trPaymentSchema(v: PaymentSchema): ReactNode;
    disabled: boolean;
}

const PaymentSchemaSection: FC<PaymentSchemaSectionProps> = ({
    schema,
    form,
    isPaymentSchemaUpfront,
    showSchemaWarning,
    trPaymentSchema,
    disabled,
}) => {
    return (
        <Box key={schema} marginVertical={0}>
            <Box>
                <Checkbox
                    key={schema}
                    checked={form.values.allowedBillingFrequencies.includes(schema)}
                    onChange={() =>
                        form.setValues({
                            allowedBillingFrequencies: xor(form.values.allowedBillingFrequencies, [schema]),
                        })
                    }
                    disabled={disabled || isPaymentSchemaUpfront(schema)}
                >
                    <Paragraph3 color={isPaymentSchemaUpfront(schema) ? Color.Neutral300 : Color.Neutral500}>
                        {trPaymentSchema(schema)}
                    </Paragraph3>
                </Checkbox>
            </Box>
            {showSchemaWarning(schema) && (
                <Box row marginTop={5}>
                    <Icon svg={SvgIcon.InfoCircle} size={18} marginRight={5} color={Color.AccentOrange} />
                    <Paragraph3 color={isPaymentSchemaUpfront(schema) ? Color.Neutral300 : Color.Neutral500}>
                        Payment schema in use as a default
                    </Paragraph3>
                </Box>
            )}
        </Box>
    );
};
