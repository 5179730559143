import { useMutation, useQueryClient } from '@tanstack/react-query';

import { countryMultipliersQueryKey, countryMultipliersService } from '@hofy/api-admin';
import { errorMap, isEmpty } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

import {
    emptyNewCountryMultiplierFormData,
    NewCountryMultiplierFormData,
} from './NewCountryMultiplierFormData';

export const useUpdateCountryMultipliers = () => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: ({ country, multiplier }: NewCountryMultiplierFormData) =>
            countryMultipliersService.updateMultiplier(country!, multiplier),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [countryMultipliersQueryKey] });
            showToast({
                type: 'positive',
                message: 'Country Multiplier added successfully',
            });
            form.reset();
        },
    });

    const form = useForm<NewCountryMultiplierFormData>({
        initial: emptyNewCountryMultiplierFormData,
        onSubmit: mutation.mutate,
        validate: formState => ({
            country: errorMap([isEmpty(formState.country), "Can't be empty"]),
            multiplier: errorMap([isEmpty(formState.multiplier), "Can't be empty"]),
        }),
    });

    return {
        form,
        isMutationLoading: mutation.isPending,
    };
};
