import { clamp, findIndex } from 'lodash';
import React, { ReactElement } from 'react';
import { SideBySideMagnifier } from 'react-image-magnifiers';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { Box, MarginBoxProps } from '../base';
import { IconButton, SvgIcon } from '../icon';
import { FramedImage, ImageBox } from './FramedImage';

export interface Image {
    url: string;
    id: number;
    isActive: boolean;
}

export interface ImageCarouselProps<T extends Image> extends MarginBoxProps {
    images: T[];
    selected?: T;
    onSelect(v: T | undefined): void;
    fallback: string | ReactElement;
    zoomable?: boolean;
    size?: number;
    thumbCount?: number;
    vertical?: boolean;
}

export const ImageCarousel = <T extends Image>({
    fallback,
    images,
    selected,
    onSelect,
    zoomable = false,
    size = 370,
    thumbCount = 3,
    vertical = false,
    ...margins
}: ImageCarouselProps<T>) => {
    const imageIndex = selected ? findIndex(images, v => v.url === selected.url) : 0;
    const isLast = imageIndex === images.length - 1;
    const isFirst = imageIndex === 0;

    const prevImage = () => {
        if (imageIndex > 0) {
            const index = imageIndex - 1;
            onSelect(images[index]);
        }
    };

    const nextImage = () => {
        if (!isLast) {
            const index = imageIndex + 1;
            onSelect(images[index]);
        }
    };

    const navButtonSize = 25;
    const thumbMargin = 10;
    const thumbListMargin = 8;

    const thumbListSize = size - navButtonSize * 2;
    const thumbSize = (thumbListSize - thumbMargin * (thumbCount - 1)) / thumbCount;

    const centerImageIndex = (thumbCount - 1) / 2;
    const maxScrollerIndex = images.length - 1 - centerImageIndex * 2;
    const scrollerIndex = clamp(imageIndex - centerImageIndex, 0, maxScrollerIndex);

    const translateFn = vertical ? 'translateX' : 'translateY';
    const translateOffset =
        (images.length < thumbCount ? (images.length - thumbCount) / 2 : scrollerIndex) *
        (thumbSize + thumbMargin) *
        -1;

    return (
        <WrapperBox
            direction={vertical ? 'column' : 'row'}
            alignItems='flex-start'
            shrink={0}
            gap={30}
            {...margins}
        >
            {zoomable && selected?.url ? (
                <ImageBox rect={size} selected={false}>
                    <WrapperMagnifier imageSrc={selected.url} fillAvailableSpace alwaysInPlace />
                </ImageBox>
            ) : (
                <FramedImage size={size} src={selected?.url} fallback={fallback} />
            )}

            <Box direction={vertical ? 'row' : 'column'} gap={thumbListMargin}>
                <IconButton
                    disabled={isFirst}
                    icon={vertical ? SvgIcon.ChevronLeft : SvgIcon.ChevronUp}
                    onClick={prevImage}
                />
                <Box
                    overflow='hidden'
                    height={vertical ? 'auto' : thumbListSize}
                    width={vertical ? thumbListSize : 'auto'}
                >
                    <ImageContainer
                        direction={vertical ? 'row' : 'column'}
                        style={{ transform: `${translateFn}(${translateOffset}px)` }}
                        gap={thumbMargin}
                    >
                        {images.map((image, index) => (
                            <Box key={index} onClick={() => onSelect(image)} cursor='pointer'>
                                <FramedImage
                                    size={thumbSize}
                                    selected={selected?.url === image.url}
                                    fallback={fallback}
                                    src={image.url}
                                />
                            </Box>
                        ))}
                    </ImageContainer>
                </Box>
                <IconButton
                    disabled={isLast}
                    icon={vertical ? SvgIcon.ChevronRight : SvgIcon.ChevronDown}
                    onClick={nextImage}
                />
            </Box>
        </WrapperBox>
    );
};

const ImageContainer = styled(Box)`
    transition: transform 0.2s ease-in-out;
`;

const WrapperBox = styled(Box)`
    user-select: none;
`;

const WrapperMagnifier = styled(SideBySideMagnifier)`
    height: 100%;
    & > div {
        height: 100%;
        & > img {
            height: 100%;
            object-fit: contain;
            & + div {
                background: ${Color.BackgroundDefault};
            }
        }
    }
`;
