import { groupBy, toNumber, uniq } from 'lodash';

import { OperatingSystem, ProductCategory, ProductTier, RentalTerm } from '@hofy/api-shared';
import { Country, Price, regionCountries, SubRegion, UUID, zeroDollars, zeroUuid } from '@hofy/global';

export interface ProductPayload {
    name: string;
    brand: string;
    isActive: boolean;
    isInternal: boolean;
    isRefurbished: boolean;
    isConsumable: boolean;
    description: string;
    specifications: SpecificationPayload[];
    category: ProductCategory;
    tier: ProductTier | null;
    variants: VariantPayload[];
    images: ImagePayload[];
    sendNotification: boolean;
    replacementProductId: number | null;
    forceReplacement: boolean;
}

export interface ImagePayload {
    uuid: UUID;
    url: string;
    isActive: boolean;
    position: number;
}

export interface VariantPayload {
    id: number | null;
    sku: string;
    isActive: boolean;
    isInternal: boolean;
    isAvailableForStoreAndReuse: boolean;
    os: OperatingSystem | null;
    size: string | null;
    style: string | null;
    price: Record<RentalTerm, Price>;
    unbundledPrice: Record<RentalTerm, Price>;
    purchasePrice: Price;
    recommendedRetailPrice: Price;
    unbundledPurchasePrice: Price;
    manufacturerPartCode: string | null;
    availability: Country[];
    organizationId: number | null;
    image: UUID;
}

export interface SpecificationPayload {
    name: string;
    value: string;
}

export const emptyVariantPayload: VariantPayload = {
    sku: '',
    manufacturerPartCode: '',
    isActive: true,
    isInternal: false,
    isAvailableForStoreAndReuse: false,
    availability: [
        ...regionCountries[SubRegion.UnitedKingdom],
        ...regionCountries[SubRegion.UnitedStates],
        ...regionCountries[SubRegion.Eu],
    ],
    recommendedRetailPrice: zeroDollars,
    price: {
        [RentalTerm.Rental6Months]: zeroDollars,
        [RentalTerm.Rental12Months]: zeroDollars,
        [RentalTerm.Rental24Months]: zeroDollars,
        [RentalTerm.Rental36Months]: zeroDollars,
    },
    unbundledPrice: {
        [RentalTerm.Rental6Months]: zeroDollars,
        [RentalTerm.Rental12Months]: zeroDollars,
        [RentalTerm.Rental24Months]: zeroDollars,
        [RentalTerm.Rental36Months]: zeroDollars,
    },
    purchasePrice: zeroDollars,
    unbundledPurchasePrice: zeroDollars,
    style: null,
    size: null,
    id: null,
    os: null,
    organizationId: null,
    image: zeroUuid,
};

export const emptyProductPayload: ProductPayload = {
    name: '',
    brand: '',
    isActive: true,
    isInternal: false,
    isConsumable: false,
    isRefurbished: false,
    description: '',
    specifications: [],
    images: [],
    variants: [],
    forceReplacement: false,
    category: ProductCategory.Laptop,
    tier: null,
    sendNotification: false,
    replacementProductId: null,
};

export const variantPriceContainsValue = (variantPrice: Record<RentalTerm, Price>, value: number) =>
    Object.keys(variantPrice).some(key => toNumber(variantPrice[key as RentalTerm]) === value);

const hasOverlappedCountries = (variants: VariantPayload[]) => {
    const all = variants.flatMap(v => v.availability);
    return all.length !== uniq(all).length;
};

export const hasOverlappedRegionsVariant = (variants: VariantPayload[]) => {
    const grouped = groupBy(variants, elem => `${elem.style ?? ''}:${elem.size ?? ''}`);
    return Object.keys(grouped).some(key => hasOverlappedCountries(grouped[key]));
};
