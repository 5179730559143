import React, { FC, useState } from 'react';

import {
    ShipmentCollectionRequestResolvePackagingAction,
    ShipmentCollectionRequestUpdateTrackingDataAction,
} from '@hofy/api-admin';
import { CopyToClipboardButton } from '@hofy/common';
import { Alert, Box, Button, Link, Modals, Paragraph3 } from '@hofy/ui';

import { PackagingTrackingDataModal } from './PackagingTrackingDataModal';
import { ResolvePackagingModal } from './ResolvePackagingModal';

interface PackagingActionProps {
    shipmentId: number;
    collectionRequestId: number;
    updateTrackingDataAction: ShipmentCollectionRequestUpdateTrackingDataAction;
    resolvePackagingAction: ShipmentCollectionRequestResolvePackagingAction;
}

export const PackagingAction: FC<PackagingActionProps> = ({
    shipmentId,
    collectionRequestId,
    updateTrackingDataAction,
    resolvePackagingAction,
}) => {
    const [isUpdatePackagingTrackingDataModalOpen, setIsUpdatePackagingTrackingDataModalOpen] =
        useState<boolean>(false);
    const [isResolveModalOpen, setIsResolveModalOpen] = useState<boolean>(false);

    return (
        <Alert
            type='negative'
            title='The user is missing one or all of manufacturer boxes, shipping boxes, and/or labels'
            description={
                updateTrackingDataAction.trackingReference !== null &&
                updateTrackingDataAction.trackingLink !== null && (
                    <Box column gap={2}>
                        <Box row gap={8}>
                            <Paragraph3>
                                Tracking reference: {updateTrackingDataAction.trackingReference}
                            </Paragraph3>
                            <CopyToClipboardButton
                                border={false}
                                value={updateTrackingDataAction.trackingReference}
                            />
                        </Box>
                        <Box row gap={8}>
                            <Paragraph3>
                                Tracking link: <Link to={updateTrackingDataAction.trackingLink} inline />
                            </Paragraph3>
                            <CopyToClipboardButton
                                border={false}
                                value={updateTrackingDataAction.trackingLink}
                            />
                        </Box>
                    </Box>
                )
            }
        >
            {resolvePackagingAction.enabled && (
                <Button
                    label='Mark as resolved'
                    size='small'
                    type='plain'
                    onClick={() => setIsResolveModalOpen(true)}
                />
            )}

            <Button
                label={
                    updateTrackingDataAction.trackingReference !== null &&
                    updateTrackingDataAction.trackingLink !== null
                        ? 'Edit packaging tracking data'
                        : 'Add packaging tracking data'
                }
                size='small'
                type='plain'
                onClick={() => setIsUpdatePackagingTrackingDataModalOpen(true)}
            />

            <Modals>
                {isResolveModalOpen && (
                    <ResolvePackagingModal
                        shipmentId={shipmentId}
                        collectionRequestId={collectionRequestId}
                        packagingFee={resolvePackagingAction.packagingFee}
                        onClose={() => setIsResolveModalOpen(false)}
                    />
                )}
                {isUpdatePackagingTrackingDataModalOpen && (
                    <PackagingTrackingDataModal
                        shipmentId={shipmentId}
                        collectionRequestId={collectionRequestId}
                        trackingData={updateTrackingDataAction}
                        onClose={() => setIsUpdatePackagingTrackingDataModalOpen(false)}
                    />
                )}
            </Modals>
        </Alert>
    );
};
