import React, { FC } from 'react';

import { BillingEntityDto } from '@hofy/api-admin';
import {
    BooleanBadge,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Placeholder,
    SvgIllustration,
    TextCell,
} from '@hofy/ui';

import { BillingEntityStatusChip } from '../../../../components/domain/billingEntity/BillingEntityStatusChip';
import { NetsuiteStatusChip } from '../../../../components/domain/netsuite/NetsuiteStatusChip';
import { OrganizationLinkOptional } from '../../../organizationsPage/OrganizationDetailsLink';
import { TwoStatusChip } from './TwoStatusChip';

interface BillingEntitiesInfinityListProps {
    entries: BillingEntityDto[];
    infinityScroll: InfiniteScrollConfig;
    onOpenBillingEntity(id: number): void;
}

export const BillingEntitiesInfinityList: FC<BillingEntitiesInfinityListProps> = ({
    entries,
    infinityScroll,
    onOpenBillingEntity,
}) => {
    return (
        <InfinityScrollTable
            data={entries}
            toKey={entry => entry.id}
            onRowClick={b => onOpenBillingEntity(b.id)}
            infinityScroll={infinityScroll}
            emptyContent={
                <Placeholder illustration={SvgIllustration.FinanceSearch} title='No billing entities' />
            }
            minWidth={1200}
            columns={billingEntityTableListColumns}
        />
    );
};

export enum BillingEntityTableListColumn {
    id = 'id',
    name = 'name',
    status = 'status',
    organization = 'organization',
    isDefault = 'isDefault',
    currency = 'currency',
    two = 'two',
    netsuite = 'netsuite',
}

export const billingEntityTableListColumns = [
    {
        id: BillingEntityTableListColumn.id,
        header: 'Id',
        renderer: (b: BillingEntityDto) => b.id,
    },
    {
        id: BillingEntityTableListColumn.name,
        header: 'Name',
        renderer: (b: BillingEntityDto) => b.name,
    },
    {
        id: BillingEntityTableListColumn.status,
        header: 'Status',
        renderer: (b: BillingEntityDto) => <BillingEntityStatusChip status={b.status} />,
    },
    {
        id: BillingEntityTableListColumn.organization,
        header: 'Organization',
        renderer: (b: BillingEntityDto) =>
            b.organization?.id ? (
                <TextCell>
                    <OrganizationLinkOptional id={b.organization?.id}>
                        {b.organization?.name}
                    </OrganizationLinkOptional>
                </TextCell>
            ) : (
                '--'
            ),
    },
    {
        id: BillingEntityTableListColumn.isDefault,
        header: 'Is default',
        renderer: (b: BillingEntityDto) => <BooleanBadge value={b.isDefault} offColor='grey' />,
    },
    {
        id: BillingEntityTableListColumn.currency,
        header: 'Currency',
        renderer: (b: BillingEntityDto) => b.currency,
    },
    {
        id: BillingEntityTableListColumn.two,
        header: 'Two',
        renderer: (b: BillingEntityDto) => <TwoStatusChip billingEntity={b} />,
    },
    {
        id: BillingEntityTableListColumn.netsuite,
        header: 'Netsuite',
        renderer: (b: BillingEntityDto) => <NetsuiteStatusChip status={b.netsuite} />,
    },
];
