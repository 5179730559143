import { isEmpty } from 'lodash';
import React, { FC, ReactNode, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AuditableTable, useTeamsQuery, useTermsAndConditionsQuery } from '@hofy/api-admin';
import { OrganizationStatus, Permission, Role } from '@hofy/api-shared';
import { useSession, useSignInToOrganization } from '@hofy/auth';
import { ComponentLoader, MoreMenu, Page } from '@hofy/common';
import { stringifyUrl } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { useStringQueryParam } from '@hofy/router';
import { Color } from '@hofy/theme';
import {
    ActionDropdown,
    ActionDropdownItem,
    Box,
    Button,
    Modals,
    PageHeader,
    SearchInput,
    SvgIcon,
    Tooltip,
} from '@hofy/ui';
import { PermissionWrapper } from '@hofy/ui-domain';

import { DangerousConfirmModal } from '../../components/design/modal/DangerousConfirmModal';
import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { useAuditLogMenuOption } from '../../store/auditLogs/useAuditMenuOption';
import { OrganizationTab, SearchableOrganizationTabs } from '../../store/organizations/types/OrganizationTab';
import { useAdminOrganizationAccountingSync } from '../../store/organizations/useAdminOrganizationAccountingSync';
import { useAdminSyncOrganizationToZendesk } from '../../store/organizations/useAdminSyncOrganizationToZendesk';
import { useDeleteOrganization } from '../../store/organizations/useDeleteOrganization';
import { useDisconnectOrganizationHRIS } from '../../store/organizations/useDisconnectOrganizationHRIS';
import { useOrganizationDetailsQuery } from '../../store/organizations/useOrganizationDetailsQuery';
import { AddonConfigurationsTab } from './addonsTab/AddonConfigurationsTab';
import { BillingEntitiesTab } from './billingEntitiesTab/BillingEntitiesTab';
import { AddTermAndConditionModal } from './components/AddTermAndConditionModal';
import { ApplyRetrospectivelyTermAndConditionModal } from './components/ApplyRetrospectivelyTermAndConditionModal';
import { ContractDefaultsUpdateModal } from './components/ContractDefaultsUpdateModal';
import { CreateTeamModal } from './components/CreateTeamModal';
import { CurrencyUpdateModal } from './components/CurrencyUpdateModal';
import { DataErasureUpdateModal } from './components/DataErasureUpdateModal';
import { DisconnectHRISConfirmationModal } from './components/DisconnectHRISConfirmationModal';
import { GenerateOrganizationInvoicesModal } from './components/GenerateOrganizationInvoicesModal';
import { MdmUpdateModal } from './components/MdmUpdateModal';
import { StatusUpdateModal } from './components/StatusUpdateModal';
import { UsersUploadModal } from './components/UsersUploadModal';
import { ContractSettingsTab } from './contractSettingsTab/ContractSettingsTab';
import { DetailsTab } from './detailsTab/DetailsTab';
import { OrganizationDevicesTab } from './devicesTab/OrganizationDevicesTab';
import { DiscountsTab } from './discountsTab/DiscountsTab';
import { OrganizationEmailsTab } from './emailsTab/OrganizationEmailsTab';
import { InvoiceGroupsTab } from './invoiceGroups/InvoiceGroupsTab';
import { OrganizationInvoicesTable } from './invoicesTab/OrganizationInvoicesTable';
import { OrganizationDetailsPageTabs } from './OrganizationDetailsPageTabs';
import { PaymentDiscountsTab } from './paymentDiscountsTab/PaymentDiscountsTab';
import { SubscriptionsTab } from './subscriptionsTab/SubscriptionsTab';
import { TeamsTab } from './teamsTab/TeamsTab';
import { TermsAndConditionsTab } from './termsAndConditionsTab/TermsAndConditionsTab';
import { UsersTab } from './usersTab/UsersTab';

enum ConfirmationModal {
    AddTeam,
    ApplyRetrospectively,
    DisconnectHRIS,
    DeleteOrganization,
    AddTermAndCondition,
}

interface OrganizationDetailsPageProps {
    organizationId: number;
    organizationTab: OrganizationTab;
    onChangeTab(organizationTab: OrganizationTab): void;
    onOpenEditOrganization(id: number): void;
    onOpenCreateUser(id: number): void;
    onOpenUser(id: number): void;
    onInvoiceClick(id: number): void;
    onItemClick(id: number): void;
    onOpenBillingEntity(billingEntityId: number): void;
    onUpdateContractSetting(organizationId: number, contractSettingId: number): void;
    onUpdateAddonConfiguration(addonId: number): void;
    onCreateSubscription(): void;
    onSubscriptionClick(subscriptionId: number): void;
    onOpenEmail(emailId: number): void;
    getCreateBillingEntityLink(): string;
}

export const OrganizationDetailsPage: FC<OrganizationDetailsPageProps> = ({
    organizationTab,
    organizationId,
    onChangeTab,
    onOpenEditOrganization,
    onOpenCreateUser,
    onOpenUser,
    getCreateBillingEntityLink,
    onOpenBillingEntity,
    onUpdateContractSetting,
    onUpdateAddonConfiguration,
    onCreateSubscription,
    onSubscriptionClick,
    onInvoiceClick,
    onItemClick,
    onOpenEmail,
}) => {
    const history = useHistory();

    const { data: organization, isLoading } = useOrganizationDetailsQuery(organizationId);
    const { data: termsAndConditions, isLoading: isTermsAndConditionsLoading } = useTermsAndConditionsQuery();
    const { goBack } = useGoBack();
    const { hasPermission, hasRole } = useSession();
    const { signInToOrganization } = useSignInToOrganization();
    const { accountingSync } = useAdminOrganizationAccountingSync(organizationId);
    const { syncToZendesk } = useAdminSyncOrganizationToZendesk(organizationId);
    const { form: disconnectOrganizationForm } = useDisconnectOrganizationHRIS(organizationId);
    const { deleteOrganization } = useDeleteOrganization(organizationId, () =>
        goBack(AdminNavLink.Organizations),
    );
    const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal>();
    const [search, setSearch] = useStringQueryParam('search', '');
    const [menuLink] = useAuditLogMenuOption(AuditableTable.Organization, organizationId);
    const { teams, isTeamsLoading } = useTeamsQuery(organizationId);
    const [generateInvoicesModal, setGenerateInvoicesModal] = useState(false);
    const [currencyUpdateModal, setCurrencyUpdateModal] = useState(false);
    const [dataErasureUpdateModal, setDataErasureUpdateModal] = useState(false);
    const [usersUploadModal, setUsersUploadModal] = useState(false);
    const [contractDefaultsUpdateModal, setContractDefaultsUpdateModal] = useState(false);
    const [mdmUpdateModal, setMdmUpdateModal] = useState(false);
    const [statusUpdateModal, setStatusUpdateModal] = useState(false);

    const isTabSearchable = useMemo(
        () => SearchableOrganizationTabs.includes(organizationTab),
        [organizationTab],
    );

    const moreMenu = (
        <MoreMenu
            items={[
                {
                    action: () => setConfirmationModal(ConfirmationModal.DisconnectHRIS),
                    label: 'Disconnect HRIS',
                    testKey: 'disconnect-hris',
                    visible: hasPermission(Permission.AdminOrganizationUpdate),
                },
                {
                    action: accountingSync,
                    label: 'Sync to Netsuite',
                    visible: hasPermission(Permission.AdminAccountingNonTransactionalSync),
                },
                {
                    action: syncToZendesk,
                    label: 'Sync to Zendesk',
                    visible: hasPermission(Permission.AdminOrganizationSyncToZendesk),
                },
                {
                    action: () => setGenerateInvoicesModal(true),
                    label: 'Generate invoices',
                    visible: hasPermission(Permission.AdminInvoicesGenerate),
                },
                {
                    action: () => {
                        history.push(
                            stringifyUrl({
                                url: AdminNavLink.Contracts,
                                query: {
                                    organizations: organization?.id,
                                },
                            }),
                        );
                    },
                    color: Color.NonContextualBlueDefault,
                    label: 'View contracts',
                },
                menuLink,
                {
                    action: () => setConfirmationModal(ConfirmationModal.DeleteOrganization),
                    color: Color.FoundationNegative,
                    label: 'Delete organization',
                    testKey: 'delete-organization',
                    visible: hasPermission(Permission.AdminOrganizationDelete),
                },
            ]}
        />
    );

    if (isLoading || isTermsAndConditionsLoading || !organization) {
        return <ComponentLoader />;
    }

    const renderContent = () => {
        switch (organizationTab) {
            case OrganizationTab.Details:
                return <DetailsTab organization={organization} />;
            case OrganizationTab.Invoices:
                return (
                    <OrganizationInvoicesTable
                        onEntryClick={onInvoiceClick}
                        organizationId={organizationId}
                        search={search}
                    />
                );
            case OrganizationTab.Teams:
                return <TeamsTab teams={teams} isTeamsLoading={isTeamsLoading} search={search} />;
            case OrganizationTab.Users:
                return <UsersTab organizationId={organizationId} onOpenUser={onOpenUser} search={search} />;
            case OrganizationTab.BillingEntities:
                return (
                    <BillingEntitiesTab
                        search={search}
                        onOpenBillingEntity={onOpenBillingEntity}
                        billingEntities={organization.billingEntities}
                    />
                );
            case OrganizationTab.Devices:
                return (
                    <OrganizationDevicesTab
                        onEntryClick={onItemClick}
                        organizationId={organizationId}
                        search={search}
                    />
                );
            case OrganizationTab.Discounts:
                return <DiscountsTab organization={organization} />;
            case OrganizationTab.RentalEquipmentPaymentDiscounts:
                return <PaymentDiscountsTab organization={organization} />;
            case OrganizationTab.ContractSettings:
                return (
                    <ContractSettingsTab
                        onEditContractSetting={id => onUpdateContractSetting(organizationId, id)}
                        contractSettings={organization.defaultContractSettings}
                    />
                );
            case OrganizationTab.TermsAndConditions:
                return <TermsAndConditionsTab organizationId={organizationId} search={search} />;
            case OrganizationTab.Addons:
                return (
                    <AddonConfigurationsTab
                        organizationId={organizationId}
                        onUpdateAddonConfiguration={onUpdateAddonConfiguration}
                        search={search}
                    />
                );
            case OrganizationTab.InvoiceGroups:
                return <InvoiceGroupsTab organization={organization} />;
            case OrganizationTab.Emails:
                return <OrganizationEmailsTab organization={organization} onOpenEmail={onOpenEmail} />;
            case OrganizationTab.Subscriptions:
                return <SubscriptionsTab organizationId={organizationId} onClick={onSubscriptionClick} />;
        }
    };
    const { teamLimit, billingEntityLimit } = organization.platformTierConfig;
    const billingEntityLimitExceeded =
        !!billingEntityLimit && organization.billingEntities.length >= billingEntityLimit;

    const renderExceededBillingEntityLimitTooltip = (content: ReactNode) => {
        if (billingEntityLimitExceeded) {
            return (
                <Tooltip body='Organization reached the maximum number of billing entities on current plan.'>
                    {content}
                </Tooltip>
            );
        }
        return content;
    };

    return (
        <Page>
            <PageHeader
                title={organization.name}
                rightSlot={
                    <>
                        {organizationTab === OrganizationTab.Users && (
                            <>
                                <Button
                                    type='secondary'
                                    label='New user'
                                    onClick={() => onOpenCreateUser(organizationId)}
                                    disabled={!hasPermission(Permission.AdminUsersCreate)}
                                    leftIcon={SvgIcon.Add}
                                />
                                <Button
                                    type='secondary'
                                    label='Upload users'
                                    onClick={() => setUsersUploadModal(true)}
                                    disabled={!hasPermission(Permission.AdminUsersCreate)}
                                    leftIcon={SvgIcon.Upload}
                                />
                            </>
                        )}
                        {organizationTab === OrganizationTab.Teams &&
                            (!teamLimit || teams.length < teamLimit) && (
                                <Button
                                    type='secondary'
                                    label='New team'
                                    onClick={() => setConfirmationModal(ConfirmationModal.AddTeam)}
                                    disabled={!hasPermission(Permission.AdminOrganizationUpdate)}
                                    leftIcon={SvgIcon.Add}
                                />
                            )}
                        {organizationTab === OrganizationTab.Details && (
                            <>
                                <Button
                                    type='secondary'
                                    leftIcon={SvgIcon.User}
                                    label='Sign in to organization'
                                    onClick={() => signInToOrganization(organization.id)}
                                    disabled={!hasPermission(Permission.AdminSignInToOrganization)}
                                />
                                <Button
                                    type='secondary'
                                    label='Organisation settings'
                                    leftIcon={SvgIcon.Settings}
                                    onClick={() => onOpenEditOrganization(organization.id)}
                                    disabled={
                                        !hasPermission(Permission.AdminOrganizationUpdate) &&
                                        !hasPermission(Permission.AdminOrganizationUpdateMdm)
                                    }
                                />
                            </>
                        )}
                        {organizationTab === OrganizationTab.BillingEntities &&
                            renderExceededBillingEntityLimitTooltip(
                                <Button
                                    type='secondary'
                                    label='Create billing entity'
                                    leftIcon={SvgIcon.Settings}
                                    to={getCreateBillingEntityLink()}
                                    disabled={
                                        !hasPermission(Permission.AdminOrganizationUpdateFinancialSettings) ||
                                        billingEntityLimitExceeded
                                    }
                                />,
                            )}
                        {organizationTab === OrganizationTab.TermsAndConditions && (
                            <>
                                <Button
                                    type='secondary'
                                    label='Add term and condition'
                                    onClick={() =>
                                        setConfirmationModal(ConfirmationModal.AddTermAndCondition)
                                    }
                                    disabled={!hasPermission(Permission.AdminOrganizationUpdate)}
                                    leftIcon={SvgIcon.Add}
                                />
                                {hasRole(Role.SuperAdmin) && (
                                    <Button
                                        type='secondary'
                                        label='Apply retrospectively'
                                        onClick={() =>
                                            setConfirmationModal(ConfirmationModal.ApplyRetrospectively)
                                        }
                                        disabled={!hasPermission(Permission.AdminOrganizationUpdate)}
                                    />
                                )}
                            </>
                        )}
                        {organizationTab === OrganizationTab.Subscriptions && (
                            <Button
                                type='secondary'
                                label='Create subscription'
                                onClick={onCreateSubscription}
                                disabled={!hasPermission(Permission.AdminOrganizationCreate)}
                                leftIcon={SvgIcon.Add}
                            />
                        )}
                        {isTabSearchable && <SearchInput value={search} onChange={setSearch} />}

                        <ActionDropdown label='Manage'>
                            <PermissionWrapper
                                permission={Permission.AdminOrganizationUpdateCurrency}
                                tooltip
                            >
                                <ActionDropdownItem
                                    title='Update currency'
                                    onClick={() => setCurrencyUpdateModal(true)}
                                />
                            </PermissionWrapper>
                            <PermissionWrapper
                                permission={Permission.AdminOrganizationUpdateDataErasureSettings}
                                tooltip
                            >
                                <ActionDropdownItem
                                    title='Update data erasure'
                                    onClick={() => setDataErasureUpdateModal(true)}
                                />
                            </PermissionWrapper>
                            {!isEmpty(organization.contractExpirySettings) && (
                                <PermissionWrapper
                                    permission={Permission.AdminOrganizationUpdateContractExpirySettings}
                                    tooltip
                                >
                                    <ActionDropdownItem
                                        title='Contract defaults'
                                        onClick={() => setContractDefaultsUpdateModal(true)}
                                    />
                                </PermissionWrapper>
                            )}
                            <PermissionWrapper permission={Permission.AdminOrganizationUpdateMdm} tooltip>
                                <ActionDropdownItem
                                    title='Update MDM'
                                    onClick={() => setMdmUpdateModal(true)}
                                />
                            </PermissionWrapper>
                            <PermissionWrapper
                                permission={
                                    organization.status === OrganizationStatus.Trial
                                        ? Permission.AdminOrganizationUpdateTrialStatus
                                        : Permission.AdminOrganizationUpdateFinancialSettings
                                }
                                tooltip
                            >
                                <ActionDropdownItem
                                    title='Update status'
                                    onClick={() => setStatusUpdateModal(true)}
                                />
                            </PermissionWrapper>
                        </ActionDropdown>

                        {moreMenu}
                    </>
                }
                tabsSlot={
                    <OrganizationDetailsPageTabs
                        tab={organizationTab}
                        onChange={onChangeTab}
                        unbundlingEnabled={organization.unbundlingEnabled}
                    />
                }
            />
            <Box flex='auto' relative>
                {renderContent()}
            </Box>

            <Modals>
                {confirmationModal === ConfirmationModal.AddTeam && (
                    <CreateTeamModal
                        onClose={() => setConfirmationModal(undefined)}
                        organization={organization}
                    />
                )}
                {confirmationModal === ConfirmationModal.AddTermAndCondition && (
                    <AddTermAndConditionModal
                        onClose={() => setConfirmationModal(undefined)}
                        organizationId={organizationId}
                        termsAndConditions={termsAndConditions}
                    />
                )}
                {confirmationModal === ConfirmationModal.ApplyRetrospectively && (
                    <ApplyRetrospectivelyTermAndConditionModal
                        onClose={() => setConfirmationModal(undefined)}
                        organizationId={organizationId}
                        termsAndConditions={termsAndConditions}
                    />
                )}
                {confirmationModal === ConfirmationModal.DisconnectHRIS && (
                    <DisconnectHRISConfirmationModal
                        form={disconnectOrganizationForm}
                        onClose={() => setConfirmationModal(undefined)}
                    />
                )}
                {generateInvoicesModal && (
                    <GenerateOrganizationInvoicesModal
                        organizationId={organizationId}
                        onClose={() => setGenerateInvoicesModal(false)}
                    />
                )}
                {confirmationModal === ConfirmationModal.DeleteOrganization && (
                    <DangerousConfirmModal
                        onClose={() => setConfirmationModal(undefined)}
                        onConfirm={deleteOrganization}
                        title={`Deleting organization ${organization!.name}`}
                        confirmText={organization.name}
                        confirmButtonLabel='Delete organization'
                        cancelButtonLabel='Cancel'
                        subTitle='This operation will delete organization with all users data, invoices, teams, etc. This operation is irreversible! Please enter organization to delete:'
                        inputLabel='Organization name'
                    />
                )}
                {currencyUpdateModal && (
                    <CurrencyUpdateModal
                        onClose={() => setCurrencyUpdateModal(false)}
                        organization={organization}
                    />
                )}
                {dataErasureUpdateModal && (
                    <DataErasureUpdateModal
                        onClose={() => setDataErasureUpdateModal(false)}
                        organization={organization}
                    />
                )}
                {usersUploadModal && (
                    <UsersUploadModal
                        onClose={() => setUsersUploadModal(false)}
                        organization={organization}
                    />
                )}
                {contractDefaultsUpdateModal && !isEmpty(organization.contractExpirySettings) && (
                    <ContractDefaultsUpdateModal
                        onClose={() => setContractDefaultsUpdateModal(false)}
                        organization={organization}
                    />
                )}
                {mdmUpdateModal && (
                    <MdmUpdateModal onClose={() => setMdmUpdateModal(false)} organization={organization} />
                )}
                {statusUpdateModal && (
                    <StatusUpdateModal
                        onClose={() => setStatusUpdateModal(false)}
                        organization={organization}
                    />
                )}
            </Modals>
        </Page>
    );
};
