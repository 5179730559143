import React, { FC } from 'react';

import { FormCheckbox } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Box, ConfirmModal, Paragraph4, UseForm } from '@hofy/ui';

import { useAdminI18n } from '../../../i18n/useAdminI18n';
import { DisconnectOrganizationHRISFormData } from '../../../services/organizations/types/DisconnectOrganizationHRISFormData';

interface DisconnectHRISConfirmationModalProps {
    form: UseForm<DisconnectOrganizationHRISFormData>;
    onClose(): void;
}

export const DisconnectHRISConfirmationModal: FC<DisconnectHRISConfirmationModalProps> = ({
    form,
    onClose,
}) => {
    const { tr } = useAdminI18n();

    return (
        <ConfirmModal
            onClose={onClose}
            onConfirm={form.submit}
            keyPrefix='disconnect-organization-hris-modal'
        >
            <Box marginTop={20}>
                <FormCheckbox
                    checked={form.values.deleteNonImportedUsers}
                    onChange={checked =>
                        form.setValues({
                            deleteNonImportedUsers: checked,
                        })
                    }
                >
                    {tr('disconnect-organization-hris-modal.delete-non-imported-users')}
                </FormCheckbox>
            </Box>
            <Paragraph4 color={Color.ContentNegative} marginTop={20}>
                If the organization needs to connect to a different HRIS system or no longer requires the
                current one, please contact the admin panel support channel to remove the corresponding Merge
                account.
            </Paragraph4>
        </ConfirmModal>
    );
};
