import React, { FC } from 'react';

import { TeamColor } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Paragraph3, TestKeyAware } from '@hofy/ui';
import { TeamDot } from '@hofy/ui-domain';

interface TeamItemProps extends BoxProps, TestKeyAware {
    team: {
        id: number;
        name: string;
        color: TeamColor;
    };
    rowReverse?: boolean;
}

export const TeamItem: FC<TeamItemProps> = ({ team, rowReverse, testKey, fullWidth, ...boxProps }) => {
    return (
        <Box
            row
            rowReverse={rowReverse}
            fullWidth={fullWidth}
            justify={rowReverse ? 'space-between' : 'flex-start'}
            data-test-key={testKey}
        >
            <TeamDot color={team.color} marginLeft={rowReverse ? 8 : 0} marginRight={rowReverse ? 0 : 8} />
            <Paragraph3
                color={Color.ContentPrimary}
                textNoWrap
                overflow='hidden'
                ellipsis
                {...boxProps}
                data-test-key={`team-${team.name}`}
            >
                {team.name}
            </Paragraph3>
        </Box>
    );
};
