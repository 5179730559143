import { filter, includes, map, xor } from 'lodash';
import React, { FC } from 'react';

import { useBillingEntitiesQuery, useHofySubsidiaryRefs, usePayments } from '@hofy/api-admin';
import {
    allNetsuiteSyncStatuses,
    allPaymentProviders,
    BillingEntityStatus,
    HofySubsidiaryRef,
    netsuiteStatusColors,
    NetsuiteSyncStatus,
    PaymentProvider,
    paymentProviderColor,
} from '@hofy/api-shared';
import { formatDateRange } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import {
    Box,
    FilterChip,
    FilterChipList,
    LabeledDateRangeInput,
    PageHeader,
    SearchInput,
    SvgIcon,
} from '@hofy/ui';

import { BlockFilter } from '../../../components/design/blockFilters/BlockFilter';
import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { EnumMultiBlockFilter } from '../../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { ListMultiBlockFilter } from '../../../components/design/blockFilters/ListMultiBlockFilter';
import { BillingEntityBlockFilter } from '../../../components/domain/filters/BillingEntityBlockFilter';
import { OrganizationBlockFilter } from '../../../components/domain/filters/OrganizationBlockFilter';
import { useTrNetsuiteSyncStatus } from '../../../store/netsuite/useTrNetsuiteSyncStatus';
import { useOrganizationsRefsQuery } from '../../../store/organizations/useOrganizationsQuery';
import { usePaymentsFilters } from '../../../store/payments/usePaymentsFilters';
import { useTrPaymentProvider } from '../../../store/payments/useTrPaymentProvider';
import { AccountingTabs } from '../AccountingTabs';
import { AdminAccountingTab } from '../AdminAccountingTab';
import { PaymentsPageMenu } from './PaymentsPageMenu';
import { PaymentsTable } from './PaymentsTable';

export const PaymentsPage: FC = () => {
    const {
        search,
        setSearch,
        filters,
        filterCount,
        setDateRange,
        setHofySubsidiaryIds,
        setOrganizationIds,
        setBillingEntityIds,
        setNetsuiteStatuses,
        setPaymentProviders,
    } = usePaymentsFilters();

    const { payments, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = usePayments(filters);

    const paymentsScrollDetails = useStructMemo({
        hasMore: hasNextPage,
        isLoading: isLoading,
        isLoadingMore: isFetchingNextPage,
        loadMore: fetchNextPage,
    });

    const { hofySubsidiaries } = useHofySubsidiaryRefs();
    const { data: organizations } = useOrganizationsRefsQuery();
    const { billingEntities } = useBillingEntitiesQuery({
        status: [BillingEntityStatus.Active, BillingEntityStatus.Archived],
    });
    const trNetsuiteStatus = useTrNetsuiteSyncStatus();
    const trPaymentProvider = useTrPaymentProvider();

    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    return (
        <Box column flex='auto'>
            <PageHeader
                title='Payments'
                rightSlot={
                    <>
                        <SearchInput
                            value={search}
                            onChange={setSearch}
                            placeholder='Invoice reference'
                            autoFocus
                        />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                        <PaymentsPageMenu />
                    </>
                }
                tabsSlot={<AccountingTabs tab={AdminAccountingTab.Payments} />}
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <ListMultiBlockFilter
                    title='Subsidiary'
                    selected={filter(hofySubsidiaries, s => includes(filters.hofySubsidiaryIds, s.id))}
                    items={hofySubsidiaries}
                    onChange={filter =>
                        setHofySubsidiaryIds(map<HofySubsidiaryRef, number>(filter, s => s.id))
                    }
                    renderItem={s => s?.name || ''}
                />
                <OrganizationBlockFilter
                    selected={filters.organizationIds}
                    onChange={setOrganizationIds}
                    organizations={organizations}
                />
                <BillingEntityBlockFilter
                    selected={filters.billingEntityIds}
                    onChange={setBillingEntityIds}
                    billingEntities={billingEntities.filter(
                        b =>
                            filters.organizationIds.length === 0 ||
                            (b.organization && filters.organizationIds.includes(b.organization.id)),
                    )}
                />
                <EnumMultiBlockFilter<NetsuiteSyncStatus>
                    title='Netsuite status'
                    icon={SvgIcon.Link}
                    selected={filters.netsuiteStatuses}
                    onChange={setNetsuiteStatuses}
                    items={allNetsuiteSyncStatuses}
                    renderItem={status => trNetsuiteStatus(status)}
                />
                <EnumMultiBlockFilter<PaymentProvider>
                    title='Provider'
                    selected={filters.paymentProviders}
                    onChange={setPaymentProviders}
                    items={allPaymentProviders}
                    renderItem={provider => trPaymentProvider(provider)}
                />
                <BlockFilter title='Date' icon={SvgIcon.Calendar}>
                    <LabeledDateRangeInput
                        label='Transaction date'
                        value={filters.dateRange}
                        onChange={setDateRange}
                        nullable
                        marginLeft={12}
                        marginTop={8}
                    />
                </BlockFilter>
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <FilterChipList
                    toKey={value => value}
                    selected={filters.hofySubsidiaryIds}
                    toLabel={id => hofySubsidiaries.find(s => s.id === id)?.name || ''}
                    onClear={value => setHofySubsidiaryIds(xor(filters.hofySubsidiaryIds, [value]))}
                    color='blue'
                />
                <FilterChipList
                    toKey={value => value}
                    selected={filters.organizationIds}
                    toLabel={id => organizations.find(b => b.id === id)?.name || ''}
                    onClear={value => setOrganizationIds(xor(filters.organizationIds, [value]))}
                    color='violet'
                />
                <FilterChipList
                    toKey={value => value}
                    selected={filters.billingEntityIds}
                    toLabel={id => billingEntities.find(b => b.id === id)?.name || ''}
                    onClear={value => setBillingEntityIds(xor(filters.billingEntityIds, [value]))}
                    color='cyan'
                />
                <FilterChipList
                    toKey={value => value}
                    selected={filters.netsuiteStatuses}
                    toLabel={trNetsuiteStatus}
                    onClear={value => setNetsuiteStatuses(xor(filters.netsuiteStatuses, [value]))}
                    color={p => netsuiteStatusColors[p]}
                />
                <FilterChipList
                    toKey={value => value}
                    selected={filters.paymentProviders}
                    toLabel={trPaymentProvider}
                    onClear={value => setPaymentProviders(xor(filters.paymentProviders, [value]))}
                    color={p => paymentProviderColor[p]}
                />
                {filters.dateRange && (
                    <FilterChip
                        label={formatDateRange(filters.dateRange)}
                        onClear={() => setDateRange(null)}
                        color='teal'
                    />
                )}
            </BlockFilterChipContainer>
            <PaymentsTable payments={payments} infinityScroll={paymentsScrollDetails} />
        </Box>
    );
};
