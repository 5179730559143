import React, { FC } from 'react';

import { OrderAction, teamColorMapping, TeamDto } from '@hofy/api-shared';
import { ComponentLoader } from '@hofy/common';
import { usePrice, useSearch } from '@hofy/hooks';
import { getColorFromColorDye } from '@hofy/theme';
import { BaseTable, Box, Paragraph3, Placeholder, SvgIllustration } from '@hofy/ui';

import { ColorBox } from '../components/ColorBox';

interface TeamsTabProps {
    isTeamsLoading: boolean;
    teams: TeamDto[];
    search: string;
}

export const TeamsTab: FC<TeamsTabProps> = ({ teams, isTeamsLoading, search }) => {
    const { formatPrice } = usePrice();
    const filteredTeams = useSearch(teams, t => [t.name], search);

    if (isTeamsLoading) {
        return <ComponentLoader />;
    }

    return (
        <BaseTable
            data={filteredTeams}
            toKey={team => team.id}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.UserSearch}
                    title={search ? 'No teams were found for the current search' : 'No teams'}
                />
            }
            columns={[
                {
                    id: 'name',
                    header: 'Name',
                    flexGrow: 1,
                    renderer: team => (
                        <Box row>
                            <ColorBox bg={getColorFromColorDye(teamColorMapping[team.color], 'main')} />
                            <Paragraph3>{team.name}</Paragraph3>
                        </Box>
                    ),
                },
                {
                    width: 200,
                    flexGrow: 0,
                    id: 'allowance',
                    header: 'Allowance',
                    renderer: team => formatPrice(team.allowance),
                },
                {
                    id: 'approval',
                    flexGrow: 0,
                    header: 'Approval',
                    renderer: team => (team.withinBudgetAction === OrderAction.Approve ? 'Auto' : 'Manual'),
                },
            ]}
        />
    );
};
