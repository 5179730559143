import { getEnumValues, UUID } from '@hofy/global';

enum AvatarColor {
    Red = '#E44F4E',
    Orange = '#F19066',
    Yellow = '#FCC766',
    Green = '#4EB39F',
    Teal = '#88C8D0',
    LightBlue = '#8FC1F4',
    Blue = '#4478FF',
    Purple = '#776FE7',
    Pink = '#F8A5C2',
    Magenta = '#DD4D76',
    Navy = '#2E427A',
    Grey = '#B2BEC3',
}

const allAvatarColorsOptions = getEnumValues<AvatarColor>(AvatarColor);

// get a deterministic background colour based for a user
export const getAvatarColor = (userUuid: UUID): AvatarColor => {
    const max = allAvatarColorsOptions.length;
    // Take first digit of UUID and convert it to integer
    const uuidInt = userUuid.charCodeAt(0);
    return allAvatarColorsOptions[uuidInt % max];
};
