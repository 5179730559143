import { useMutation, useQueryClient } from '@tanstack/react-query';

import { errorMap, isEmpty, isInvalidEmail } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import {
    CreateOrganizationFormData,
    emptyOrganizationFormData,
} from '../../services/organizations/types/CreateOrganizationPayload';
import { organizationsCacheKey } from './organizationsCacheKey';

const validateOrganizationForm = (formState: CreateOrganizationFormData) => {
    const { name, currency, managerFirstName, managerLastName, managerCountry, managerEmail, createManager } =
        formState;

    return {
        name: errorMap([isEmpty(name), 'Organization name is required']),
        currency: errorMap([isEmpty(currency), 'Currency is required']),
        managerFirstName: errorMap([
            createManager && isEmpty(managerFirstName),
            'Manager first name is required',
        ]),
        managerLastName: errorMap([
            createManager && isEmpty(managerLastName),
            'Manager last name is required',
        ]),
        managerCountry: errorMap([createManager && isEmpty(managerCountry), 'Manager country is required']),
        managerEmail: errorMap(
            [createManager && isEmpty(managerEmail), 'Manager email is required'],
            [createManager && isInvalidEmail(managerEmail), 'Manager email is invalid'],
        ),
    };
};

export const useCreateOrganization = (onClose: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: adminOrganizationsService.createOrganization,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Organisation successfully created',
            });
            onClose();
        },
    });

    const form = useForm<CreateOrganizationFormData>({
        initial: emptyOrganizationFormData,
        onSubmit: mutation.mutate,
        validate: validateOrganizationForm,
    });

    return {
        form,
        isLoadingMutation: mutation.isPending,
    };
};
