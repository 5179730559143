import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToast } from '@hofy/ui';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import { organizationsCacheKey } from './organizationsCacheKey';

export const useAdminSyncOrganizationToZendesk = (organizationId: number) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: adminOrganizationsService.syncToZendesk,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Synced to zendesk',
            });
        },
    });

    const syncToZendesk = () => {
        mutation.mutate(organizationId);
    };

    return {
        syncToZendesk,
    };
};
