import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Avatar, Box, BoxProps, Font } from '@hofy/ui';

export interface ProfileContentProps extends BoxProps {
    name: string;
    subtitle: ReactNode;
    userUuid: UUID;
    pictureUrl?: string;
}

export const ProfileContent: FC<ProfileContentProps> = ({
    name,
    subtitle,
    pictureUrl,
    userUuid,
    ...props
}) => {
    return (
        <Box row {...props}>
            <Avatar shrink={0} marginRight={12} name={name} userUuid={userUuid} pictureUrl={pictureUrl} />
            <Box column flex={1}>
                <NameWrapper
                    size={14}
                    color={Color.BackgroundDefault}
                    weight='normal'
                    marginBottom={2}
                    marginRight={8}
                >
                    {name}
                </NameWrapper>
                <Mode color={Color.BackgroundDefault}>{subtitle}</Mode>
            </Box>
        </Box>
    );
};

const Mode = styled(Font)`
    letter-spacing: 3px;
    font-size: 8px;
    text-transform: uppercase;
    opacity: 0.5;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 150px;
`;

const NameWrapper = styled(Font)`
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
