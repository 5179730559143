import React, { FC } from 'react';

import {
    AsyncButton,
    FormCheckbox,
    FormContainer,
    FormNumberInput,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useAdminI18n } from '../../../i18n/useAdminI18n';
import { OrganizationDetailsDto } from '../../../services/organizations/types/OrganizationDto';
import { useAdminUpdateOrganizationMdm } from '../../../store/organizations/useAdminUpdateOrganizationMdm';

interface MdmUpdateModalProps {
    organization: OrganizationDetailsDto;
    onClose(): void;
}

export const MdmUpdateModal: FC<MdmUpdateModalProps> = ({ organization, onClose }) => {
    const { form, isLoading } = useAdminUpdateOrganizationMdm(organization, onClose);
    const { tr } = useAdminI18n();

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader
                title={tr('organization-page.mdm-update.title')}
                subtitle={tr('organization-page.mdm-update.subtitle')}
            />
            <ModalContent>
                <FormContainer>
                    <FormCheckbox
                        api={form.fields.mdmEnabled}
                        label={tr('organization-page.data-erasure-settings-update.allowed-modal.enabled')}
                    />
                    <FormNumberInput
                        label={tr('organization-page.mdm-update.modal.count')}
                        nullable
                        api={form.fields.mdmCount}
                        disabled={!form.values.mdmEnabled}
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton label={tr('organization-page.mdm-update.modal.cancel')} onClick={onClose} />
                <AsyncButton
                    label={tr('organization-page.mdm-update.modal.confirm')}
                    disableCheck
                    onClick={form.submit}
                    isLoading={isLoading}
                />
            </ModalFooter>
        </Modal>
    );
};
