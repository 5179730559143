import React, { FC } from 'react';

import { ContractDetailsDtoWithPurchase, getPurchaseContractStatusDate } from '@hofy/api-admin';
import { allPurchaseContractStatuses, useTrPaymentType, useTrPurchaseContractStatus } from '@hofy/api-shared';
import { StatusHistory } from '@hofy/common';
import { useDecimal, usePrice } from '@hofy/hooks';
import { Box, FormGridRow, FormSection, Labeled, LabeledText } from '@hofy/ui';
import { ContractStatusChip } from '@hofy/ui-domain';

import { AuditSection } from '../../../components/domain/auditingInline/AuditSection';
import { OrganizationLink } from '../../organizationsPage/OrganizationDetailsLink';
import { BillingSection } from './components/BillingSection';
import { MainItemSection } from './components/MainItemSection';
import { PriceBreakdownHintTooltip } from './components/PriceBreakdownHintTooltip';
import {
    ContractBillingAddressLabeledText,
    ContractBillingCountryLabel,
    ContractBudgetLabeledText,
    ContractProductDetails,
    ContractTermsAndConditionsLabeledText,
} from './ContractDetailsTab';
import { ManagementSection } from './ManagementSection';

interface ContractDetailsTabProps {
    contract: ContractDetailsDtoWithPurchase;
}

export const PurchaseContractDetailsTab: FC<ContractDetailsTabProps> = ({ contract }) => {
    const { formatPrice } = usePrice();
    const { formatDecimal, formatPercent } = useDecimal();
    const trStatus = useTrPurchaseContractStatus();
    const trPaymentType = useTrPaymentType();

    return (
        <Box overflow='auto' fullHeight flex='auto' row alignItems='flex-start' paddingHorizontal={40}>
            <Box flex={1} marginVertical={30} marginRight={20} gap={30} column>
                <FormSection label='Details'>
                    <FormGridRow columns={4}>
                        <LabeledText label='Contract id' content={contract.id} />
                        <LabeledText label='Contract public id' content={contract.publicId} />
                        <LabeledText label='Purchase id' content={contract.purchaseContract.id} />
                        <LabeledText
                            label='Organization'
                            content={
                                <OrganizationLink id={contract.organization.id}>
                                    {contract.organization.name}
                                </OrganizationLink>
                            }
                        />
                    </FormGridRow>
                    <FormGridRow columns={4}>
                        <Labeled
                            label='Status'
                            content={<ContractStatusChip status={contract.purchaseContract.status} />}
                        />
                        <ContractBillingCountryLabel
                            billingToAddress={contract.purchaseContract.billingToAddress}
                        />
                        <ContractBillingAddressLabeledText
                            billingToAddress={contract.purchaseContract.billingToAddress}
                        />
                    </FormGridRow>
                    <ContractProductDetails product={contract.product} variant={contract.variant} />
                    <FormGridRow columns={4}>
                        <ContractTermsAndConditionsLabeledText termAndCondition={contract.termAndCondition} />
                        <ContractBudgetLabeledText budgetConsumption={contract.budgetConsumption} />
                        <LabeledText label='Payment type' content={trPaymentType(contract.paymentType)} />
                    </FormGridRow>
                    {contract.managementContract && (
                        <ManagementSection
                            label='Management'
                            managementContract={contract.managementContract}
                        />
                    )}
                </FormSection>
                <FormSection label='Status history'>
                    <StatusHistory
                        allStatuses={allPurchaseContractStatuses}
                        dateGetter={s => getPurchaseContractStatusDate(contract, s)}
                        labelFormatter={trStatus}
                    />
                </FormSection>
                <AuditSection model={contract} />
            </Box>
            <Box flex={1} marginVertical={30} marginLeft={20} column gap={30}>
                <FormSection label={<PriceBreakdownHintTooltip label='Pricing' />}>
                    <FormGridRow columns={2}>
                        <LabeledText
                            label='Base price'
                            content={formatPrice(contract.purchaseContract.basePrice)}
                        />
                        <LabeledText
                            label='Unit price'
                            content={formatPrice(contract.purchaseContract.unitPrice)}
                        />
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <LabeledText label='Price' content={formatPrice(contract.purchaseContract.price)} />
                        <LabeledText
                            label='Country multiplier'
                            content={formatDecimal(contract.purchaseContract.countryMultiplier)}
                        />
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <LabeledText
                            label='Store fee'
                            content={formatPercent(contract.purchaseContract.storefrontFee || '0')}
                        />
                    </FormGridRow>
                </FormSection>
                <BillingSection
                    hofySubsidiary={contract.purchaseContract.hofySubsidiary}
                    billingEntity={contract.purchaseContract.billingEntity}
                    label='Invoicing'
                />
                <MainItemSection item={contract.mainItem} />
            </Box>
        </Box>
    );
};
