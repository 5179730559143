import { useMutation, useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash';

import { notesService } from '@hofy/api-admin';
import { NoteSource } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

import { notesQueryKey } from './notesQueryKey';

export interface NoteCreateParams {
    text: string;
    isExternal: boolean | null;
}

export const useCreateNote = (sourceUuid: UUID, source: NoteSource, onSuccess = noop) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (params: NoteCreateParams) => {
            return notesService.createNote({
                text: params.text,
                isExternal: source === NoteSource.Items ? true : params.isExternal,
                source: source,

                assignmentUUID: source === NoteSource.Assignments ? sourceUuid : null,
                shipmentUUID: source === NoteSource.Shipments ? sourceUuid : null,
                itemUUID: source === NoteSource.Items ? sourceUuid : null,
                repairUUID: source === NoteSource.Repairs ? sourceUuid : null,
                inspectionUUID: source === NoteSource.Inspections ? sourceUuid : null,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [notesQueryKey] });
            onSuccess();
        },
    });

    return {
        createNote: mutation.mutate,
        isSubmitting: mutation.isPending,
    };
};
