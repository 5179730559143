import React, { FC } from 'react';

import { useAssignmentQuery } from '@hofy/api-admin';
import { ContractType, NoteSource, ShipmentStatus } from '@hofy/api-shared';
import { BlockLoader, Slideout, SlideoutContent, SlideoutTabbedHeader } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Box, Heading3, Tab, Tabs } from '@hofy/ui';

import { AssignmentMenu } from '../../components/domain/assignments/AssignmentMenu';
import { NotesTab } from '../../components/domain/notes/NotesTab';
import { useTrAdminAssignmentSlideoutTabs } from '../../store/assignments/useTrAdminAssignmentSlideoutTabs';
import { useUser } from '../../store/users/useUser';
import { AssignmentTypeChip } from '../assignmentsPage/components/AssignmentTypeChip';
import { AdminAssignmentSlideoutTabs, allAdminAssignmentSlideoutTabs } from './AdminAssignmentSlideoutTabs';
import { AssignmentSlideoutDetails } from './components/AssignmentSlideoutDetails';
import { AssignmentSlideoutShipments } from './components/AssignmentSlideoutShipments';

interface AssignmentSlideoutContentProps {
    assignmentId: number;
    activeTab: AdminAssignmentSlideoutTabs;
    onClose(): void;
    onSubstituteProduct(): void;
    onEraseDevice(): void;
    onConfigureProduct(): void;
    onPickCustomProduct(): void;
    onTabChange(tab: AdminAssignmentSlideoutTabs): void;
    onShipmentClick(id: number): void;
    onCreateRepair(repairId: number): void;
}

export const AssignmentSlideoutContent: FC<AssignmentSlideoutContentProps> = ({
    assignmentId,
    activeTab,
    onClose,
    onSubstituteProduct,
    onConfigureProduct,
    onEraseDevice,
    onPickCustomProduct,
    onTabChange,
    onShipmentClick,
    onCreateRepair,
}) => {
    const { data: assignment, isLoading: isLoadingAssignment } = useAssignmentQuery(assignmentId);

    const { user } = useUser(assignment?.userId ?? null);

    const trTab = useTrAdminAssignmentSlideoutTabs();

    const handleClose = () => {
        onClose();
    };

    if (!assignment || !user) {
        return (
            <Slideout width={800} onClose={handleClose}>
                <BlockLoader fullHeight flex={1} />
            </Slideout>
        );
    }

    const activeShipment = assignment.shipments.find(s => s.isActive);

    const isBeforeBeingShipped = () => {
        return (assignment.shipments.filter(s => s.status === ShipmentStatus.WithCourier) || []).length === 0;
    };

    const content = () => {
        switch (activeTab) {
            case AdminAssignmentSlideoutTabs.Details:
                return (
                    <SlideoutContent>
                        <AssignmentSlideoutDetails assignment={assignment} />
                    </SlideoutContent>
                );

            case AdminAssignmentSlideoutTabs.Shipments:
                return (
                    <AssignmentSlideoutShipments assignment={assignment} onShipmentClick={onShipmentClick} />
                );

            case AdminAssignmentSlideoutTabs.Notes:
                return (
                    <SlideoutContent column gap={20} paddingVertical={20}>
                        <NotesTab source={NoteSource.Assignments} entityUuid={assignment.uuid} />
                    </SlideoutContent>
                );
        }
    };

    const collectionCountry =
        (assignment.contract &&
            assignment.contract.type === ContractType.Rental &&
            assignment.contract.country) ||
        user.address.country;
    return (
        <Slideout width={800} onClose={handleClose} slideoutId='admin-assignment'>
            <SlideoutTabbedHeader
                title={
                    <Box row fullWidth>
                        <Heading3 color={Color.ContentPrimary}>Assignment #{assignmentId}</Heading3>
                        <AssignmentTypeChip
                            exception={assignment.exception}
                            collectionReason={assignment.collectionReason}
                            status={assignment.status}
                            marginLeft={20}
                        />
                        {!isLoadingAssignment && (
                            <AssignmentMenu
                                isPartner={assignment.isPartner}
                                assignmentId={assignment.id}
                                assignmentStatus={assignment.status}
                                assignmentType={assignment.type}
                                isStoreAndReuseDelivery={assignment.isStoreAndReuseDelivery}
                                isStoreAndReuseCollection={assignment.isStoreAndReuseCollection}
                                isConsumable={assignment.isConsumable}
                                isOnHold={assignment.isOnHold}
                                configurationStatus={assignment.configurationStatus}
                                dataErasureStatus={assignment.dataErasure?.status ?? null}
                                collectionReason={assignment.collectionReason}
                                productId={assignment.product.id}
                                salesOrderId={assignment.salesOrderId}
                                productCategory={assignment.product.category}
                                requestedVariantId={assignment.requestedProduct?.variantId ?? null}
                                hasCustomProduct={assignment.customProduct !== null}
                                itemId={assignment.item?.id ?? null}
                                itemStatus={assignment.item?.status ?? null}
                                itemOwnership={assignment.item?.ownership ?? null}
                                itemManagedByAssignmentContract={
                                    assignment.item?.assignedContract !== undefined &&
                                    assignment.item?.assignedContract?.id === assignment.contract?.id
                                }
                                contract={assignment.contract}
                                isPendingShipment={isBeforeBeingShipped()}
                                organizationId={assignment.organization.id}
                                collectionCountry={collectionCountry}
                                deliveryCountry={assignment.deliveryAddress?.country || user!.address.country}
                                activeShipmentId={activeShipment?.id || null}
                                assignmentUserId={user?.id || null}
                                activeShipment={activeShipment ?? null}
                                deliveryAddress={assignment.deliveryAddress}
                                userAddress={user.address}
                                onConfigureDevice={onConfigureProduct}
                                onEraseDevice={onEraseDevice}
                                onSubstituteProduct={onSubstituteProduct}
                                onPickCustomProduct={onPickCustomProduct}
                                onRepairItem={() => assignment.item && onCreateRepair(assignment.item.id)}
                                marginLeft={12}
                                otd={null}
                            />
                        )}
                    </Box>
                }
                tabsSlot={
                    <Tabs active={activeTab} onChange={onTabChange}>
                        {allAdminAssignmentSlideoutTabs.map(tab => (
                            <Tab key={tab} id={tab} label={trTab(tab)} />
                        ))}
                    </Tabs>
                }
            />
            {content()}
        </Slideout>
    );
};
