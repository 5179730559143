import React, { FC } from 'react';

import { AssignmentDetailsDto, getShipmentStatusDate } from '@hofy/api-admin';
import { DateString, DateTimeString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { BaseTable, Placeholder, SvgIllustration } from '@hofy/ui';

import { ShipmentLink } from '../../../components/domain/shipments/ShipmentLink';
import { ShipmentStatusChip } from '../../../components/domain/shipments/ShipmentStatusChip';
import { ShipmentTypeChip } from '../../../components/domain/shipments/ShipmentTypeChip';

interface AssignmentSlideoutShipmentsProps {
    assignment: AssignmentDetailsDto;
    onShipmentClick(id: number): void;
}

export const AssignmentSlideoutShipments: FC<AssignmentSlideoutShipmentsProps> = ({
    assignment,
    onShipmentClick,
}) => {
    const formatStatusDate = (statusDate: DateTimeString | DateString | null) => {
        return statusDate ? formatDate(statusDate) : statusDate;
    };

    return (
        <BaseTable
            data={assignment.shipments}
            toKey={shipment => shipment.id}
            onRowClick={shipment => onShipmentClick(shipment.id)}
            emptyContent={<Placeholder illustration={SvgIllustration.PackageSearch} title='No shipments' />}
            headerHorizontalPadding={40}
            rowHorizontalPadding={30}
            columns={[
                {
                    id: 'id',
                    header: '#Id',
                    width: 100,
                    flexGrow: 0,
                    renderer: shipment => <ShipmentLink id={shipment.id}>#{shipment.id}</ShipmentLink>,
                },
                {
                    id: 'type',
                    header: 'Type',
                    renderer: shipment => <ShipmentTypeChip type={shipment.adminType} />,
                },
                {
                    id: 'status',
                    header: 'Status',
                    renderer: shipment => <ShipmentStatusChip status={shipment.status} />,
                },
                {
                    id: 'lastUpdate',
                    header: 'Last update',
                    renderer: shipment => formatStatusDate(getShipmentStatusDate(shipment, shipment.status)),
                },
            ]}
        />
    );
};
