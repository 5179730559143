import { ImageDto, OrganizationRefDto, ProductCategory } from '@hofy/api-shared';
import { Country, UUID } from '@hofy/global';

export interface ProductDto {
    id: number;
    uuid: UUID;
    name: string;
    brand: string;
    category: ProductCategory;
    isRefurbished: boolean;
    isActive: boolean;
    isInternal: boolean;
    variants: VariantDto[];
}

export interface VariantDto {
    id: number;
    sku: string;
    style: string | null;
    size: string | null;
    isActive: boolean;
    manufacturerPartCode: string | null;
    image: ImageDto;
    availability: Country[];
    organization: OrganizationRefDto | null;
}

export const firstImage = (v: { variants: VariantDto[] }) => {
    const active = v.variants.find(v => v.isActive)?.image;
    return active || v.variants[0].image;
};
