import { useQuery } from '@tanstack/react-query';

import { teamService } from '../service/teamService';
import { teamCacheKey } from './teamCacheKey';

export const useTeamsQuery = (organizationId: number) => {
    const { isLoading: isTeamsLoading, data: teams } = useQuery({
        queryKey: [teamCacheKey, organizationId],

        queryFn: () => teamService.getTeams(organizationId),
    });

    return {
        teams: teams || [],
        isTeamsLoading,
    };
};
