import React, { FC } from 'react';

import { PostAddressView } from '@hofy/address';
import { RentalSubContractDto } from '@hofy/api-admin';
import { ContractType, RentalContractStatus, useTrPaymentSchema } from '@hofy/api-shared';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { BaseTable, BooleanBadge, Box, CountryLabel, Paragraph3 } from '@hofy/ui';

import { ContractStatusLabel } from '../../../components/domain/contracts/ContractStatusLabel';
import { RentalContractTypeLabel } from '../../../components/domain/contracts/RentalContractTypeLabel';
import { StoreAndReuseStatusChip } from '../../../components/domain/contracts/StoreAndReuseStatusChip';
import { SubsidiaryCell } from '../../../components/domain/contracts/SubsidiaryCell';

interface RentalSubContractsTableProps {
    contracts: RentalSubContractDto[];
}

export const RentalSubContractsTable: FC<RentalSubContractsTableProps> = ({ contracts }) => {
    const { formatPrice } = usePrice();
    const trPaymentSchema = useTrPaymentSchema();

    return (
        <BaseTable
            data={contracts}
            toKey={contract => contract.id}
            columns={[
                {
                    id: 'id',
                    header: '#Id',
                    flexGrow: 0,
                    width: 100,
                    renderer: contract => `#${contract.id}`,
                },
                {
                    id: 'is_main',
                    header: 'Is main',
                    width: 100,
                    flexGrow: 0,
                    renderer: contract => <BooleanBadge value={contract.isMain} />,
                },
                {
                    id: 'is_store_and_reuse',
                    header: 'Store and reuse delivery',
                    width: 140,
                    flexGrow: 0,
                    renderer: contract => <BooleanBadge value={contract.storeAndReuse?.isChild || false} />,
                },
                {
                    id: 'store_and_reuse_status',
                    header: 'Store and reuse',
                    width: 160,
                    flexGrow: 0,
                    renderer: contract => (
                        <StoreAndReuseStatusChip
                            status={contract.storeAndReuse?.status || null}
                            rolloverOn={contract.rolloverOn}
                        />
                    ),
                },
                {
                    id: 'subsidiary',
                    header: 'Subsidiary',
                    width: 200,
                    flexGrow: 0,
                    renderer: contract => <SubsidiaryCell entry={contract} />,
                },
                {
                    id: 'address',
                    header: 'Billing address',
                    width: 200,
                    flexGrow: 0,
                    renderer: contract => (
                        <Box column gap={4}>
                            <Paragraph3>
                                <PostAddressView address={contract.billingToAddress} />
                            </Paragraph3>
                            <CountryLabel country={contract.billingToAddress.country} />
                        </Box>
                    ),
                },
                {
                    id: 'status',
                    header: 'Status',
                    flexGrow: 0,
                    width: 200,
                    renderer: contract => (
                        <Box>
                            <ContractStatusLabel
                                simple={false}
                                contract={{
                                    type: ContractType.Rental,
                                    rentalContract: {
                                        ...contract,
                                    },
                                    purchaseContract: null,
                                }}
                            />
                            {contract.estimatedEndOfContract &&
                                contract.status !== RentalContractStatus.Ended && (
                                    <Paragraph3>{formatDate(contract.estimatedEndOfContract)}</Paragraph3>
                                )}
                        </Box>
                    ),
                },
                {
                    id: 'rental-length',
                    header: 'Type',
                    width: 160,
                    flexGrow: 0,
                    renderer: contract => (
                        <Box>
                            <RentalContractTypeLabel
                                rentalType={contract.rentalType}
                                rentalLength={contract.rentalLength}
                            />
                            {contract.status !== RentalContractStatus.Ended && (
                                <Paragraph3>{`${contract.remainingMonths} months remaining`}</Paragraph3>
                            )}
                        </Box>
                    ),
                },
                {
                    id: 'payment-schema',
                    header: 'Payment',
                    width: 150,
                    flexGrow: 0,
                    renderer: contract => trPaymentSchema(contract.paymentSchema),
                },
                {
                    id: 'final-price',
                    header: 'Price',
                    width: 120,
                    flexGrow: 0,
                    renderer: contract => formatPrice(contract.price),
                },
                {
                    id: 'tcv',
                    header: 'TCV',
                    width: 120,
                    flexGrow: 0,
                    renderer: contract => (
                        <Box>
                            <Paragraph3>{formatPrice(contract.tcv)}</Paragraph3>
                            <Paragraph3>{formatPrice(contract.organizationTcv)}</Paragraph3>
                        </Box>
                    ),
                },
            ]}
        />
    );
};
