import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import {
    AcquisitionType,
    CategoryContractSettingsConfigDto,
    OrganizationStatus,
    PaymentSchema,
    Permission,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { useToast } from '@hofy/ui';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import { OrganizationDetailsDto } from '../../services/organizations/types/OrganizationDto';
import { UpdateOrganizationFormData } from '../../services/organizations/types/UpdateOrganizationFormData';
import { organizationsCacheKey } from './organizationsCacheKey';
import { useUpdateOrganizationForm } from './useUpdateOrganizationForm';

const getPaymentSchemas = (contracts: CategoryContractSettingsConfigDto[]): PaymentSchema[] => {
    return contracts
        .filter(v => v.acquisitionType === AcquisitionType.Rental)
        .map(value => value.paymentSchema!);
};

export const useUpdateOrganization = (organization?: OrganizationDetailsDto, onSuccess?: () => void) => {
    const { showToast } = useToast();
    const { hasPermission } = useSession();
    const queryClient = useQueryClient();

    const updateOrganizationMutation = useMutation({
        mutationFn: adminOrganizationsService.updateOrganization,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey] });
            showToast({
                message: `Organization ${organization!.name} successfully updated`,
                type: 'positive',
            });
            onSuccess?.();
        },
    });

    const updatePermission = hasPermission(Permission.AdminOrganizationUpdate);
    const mutate = (data: UpdateOrganizationFormData) => {
        return updateOrganizationMutation.mutate(data);
    };

    const form = useUpdateOrganizationForm(organization?.status === OrganizationStatus.Active, mutate);

    useEffect(() => {
        if (organization) {
            form.setValues({
                id: organization.id,
                name: organization.name,
                size: organization.size,
                logoUrl: organization.logoUrl,
                unbundlingEnabled: organization.unbundlingEnabled,
                welcomePacksEnabled: organization.welcomePacksEnabled,
                collectionSurveySkipDeviceReset: organization.collectionSurveySkipDeviceReset,
                deviceConfigRequired: organization.deviceConfigRequired,
                serviceDeskEnabled: organization.serviceDeskEnabled,
                dseEnabled: organization.dseEnabled,
                hostAgentEnabled: organization.hostAgentEnabled,
                storeAndReuseManagedByHofy: organization.storeAndReuseManagedByHofy,
                originalStatus: organization.status,
                deviceResetInstructionsLink: organization.deviceResetInstructionsLink,
                creditLimit: organization.crediting.creditLimit,
                sassFee: organization.pricing.sassFee,
                laptopConfigFee: organization.pricing.laptopConfigFee,
                mdmFee: organization.pricing.mdmFee,
                allowedBillingFrequencies: organization.allowedBillingFrequencies,
                allowedRentalTerms: organization.allowedRentalTerms,
                billingEntities: organization.billingEntities,
                salesAdminId: organization.salesAdminUser?.id ?? null,
                successAdminId: organization.successAdminUser?.id ?? null,
                platformTierConfig: organization.platformTierConfig,
                usedPaymentSchemas: getPaymentSchemas(organization.defaultContractSettings),
                storefrontFee: organization.storefrontFee,
                allowedAcquisitionTypes: organization.allowedAcquisitionTypes,
                paymentType: organization.defaultPaymentType,
            });
        }
    }, [organization]);

    return {
        form,
        isLoadingMutation: updateOrganizationMutation.isPending,
        updatePermission: updatePermission,
    };
};
