import React, { FC } from 'react';

import { InvoiceEntryDto } from '@hofy/api-admin';
import { NetsuiteSyncStatus } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Badge, Tooltip } from '@hofy/ui';

import { InvoiceDetailTabs } from '../../../../store/invoices/types/InvoiceDetailTabs';
import { InvoiceDetailsLink } from '../../invoices/InvoiceDetailsLink';

interface InvoicingStatusChipProps {
    invoiceEntry: InvoiceEntryDto;
}

export const InvoicingStatusChip: FC<InvoicingStatusChipProps> = ({ invoiceEntry }) => {
    if (invoiceEntry.isInvoiced && !invoiceEntry.invoice?.reference) {
        return <Badge color='blue' label='Off platform' />;
    }
    if (invoiceEntry.isInvoiced) {
        return <Badge color='green' label={invoiceEntry.invoice?.reference} />;
    }
    if (invoiceEntry.invoice?.netsuite.status === NetsuiteSyncStatus.Error) {
        return (
            <Tooltip body={invoiceEntry.invoice?.reference}>
                <Badge color='red' label='Sync failed' />
            </Tooltip>
        );
    }
    if (invoiceEntry.invoice?.netsuite.status === null && invoiceEntry.invoice?.reference) {
        return (
            <Tooltip body={invoiceEntry.invoice?.reference}>
                <Badge color='orange' label='Pending sync' />
            </Tooltip>
        );
    }

    return (
        <Tooltip body={invoiceEntry.invoice?.reference}>
            <Badge color='orange' label='Pending' />
        </Tooltip>
    );
};

export const InvoicingChip: FC<InvoicingStatusChipProps> = ({ invoiceEntry }) => {
    if (invoiceEntry.invoice?.reference) {
        return (
            <InvoiceDetailsLink
                id={invoiceEntry.invoice?.id}
                tab={InvoiceDetailTabs.Details}
                color={Color.ContentSecondary}
            >
                <InvoicingStatusChip invoiceEntry={invoiceEntry} />
            </InvoiceDetailsLink>
        );
    }
    return <InvoicingStatusChip invoiceEntry={invoiceEntry} />;
};
