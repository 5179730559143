import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToast } from '@hofy/ui';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import { organizationsCacheKey } from './organizationsCacheKey';

export const useDeleteOrganizationDiscount = (orgId: number, onSuccess?: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: adminOrganizationsService.deleteOrganizationDiscount,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, orgId] });
            showToast({
                type: 'positive',
                message: `Organization discount deleted`,
            });
            onSuccess?.();
        },
    });

    return {
        deleteDiscount: mutation.mutate,
    };
};
