import { AddressPayload, useTrTaxIdLabel } from '@hofy/api-shared';
import { isUSA, isValidUSAState } from '@hofy/global';
import {
    FormErrors,
    is,
    isLongerThan,
    isRequiredIf,
    isShorterThan,
    isValidCountryCode,
    validator,
} from '@hofy/ui';

import { useAddressConfig } from './useAddressConfig';
import { useAddressI18n } from './useAddressI18n';

export const useAddressValidator = () => {
    const { tr } = useAddressI18n();
    const trTaxIdLabel = useTrTaxIdLabel();
    const getAddressConfig = useAddressConfig();

    return (payload: AddressPayload | null): FormErrors<AddressPayload> | undefined => {
        if (payload === null) {
            return undefined;
        }

        const config = getAddressConfig(payload.type, payload.country);

        return validator<AddressPayload>({
            country: isRequiredIf(
                () => config.required.includes('country'),
                tr('address.country.error.required'),
            ),
            line1: isRequiredIf(() => config.required.includes('line1'), tr('address.line1.error.required')),
            city: isRequiredIf(() => config.required.includes('city'), tr('address.city.error.required')),
            state: [
                isRequiredIf(() => config.required.includes('state'), tr('address.state.error.required')),
                is(
                    (state, { country }) => !state || !isUSA(country) || isValidUSAState(state),
                    tr('address.state.error.usa'),
                ),
            ],
            postCode: [
                isRequiredIf(
                    () => config.required.includes('postCode'),
                    tr('address.postcode.error.required'),
                ),
                isShorterThan(11, tr('address.postcode.error.max')),
            ],
            deliveryInstructions: isShorterThan(300, tr('address.delivery-instructions.error.max')),
            phoneNumber: [
                isRequiredIf(
                    () => config.required.includes('phoneNumber'),
                    tr('address.phone-number.error.required'),
                ),
                isShorterThan(15 + 1, tr('address.phone-number.error.max')),
                isLongerThan(7 + 1, tr('address.phone-number.error.min')),
                isValidCountryCode(tr('address.phone-number.error.country-code')),
            ],
            taxIdNumber: isRequiredIf(
                () => config.required.includes('taxIdNumber'),
                tr('address.tax-id.error.required', { value: trTaxIdLabel(payload.country) }),
            ),
            dateOfBirth: isRequiredIf(
                () => config.required.includes('dateOfBirth'),
                tr('address.date-of-birth.error.required'),
            ),
            passportNumber: isRequiredIf(
                () => config.required.includes('passportNumber'),
                tr('address.passport-number.error.required'),
            ),
        })(payload);
    };
};
