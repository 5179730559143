import { filter, includes } from 'lodash';
import React, { FC, useMemo, useState } from 'react';

import {
    HofyWarehouseDetailsDto,
    initialStockLevelsFilters,
    StockLevelsFilters,
    stockLevelsService,
    useHofyWarehousesQuery,
    useStockLevels,
} from '@hofy/api-admin';
import { allParentProductCategories, HofyWarehouseType, productCategoriesHierarchy } from '@hofy/api-shared';
import { ExportButton, RadioFilter } from '@hofy/common';
import { ProductCategoryTree } from '@hofy/product';
import {
    Box,
    Button,
    HiddenScroll,
    LabeledSwitch,
    Modals,
    PageHeader,
    SearchInput,
    SectionTitle2,
    SvgIcon,
} from '@hofy/ui';

import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { WarehouseFilterRenderer } from '../../../components/domain/filters/WarehouseFilterRenderer';
import { allProductStatuses } from '../../../store/products/types/ProductStatus';
import { useTrProductStatus } from '../../../store/products/useTrProductStatus';
import { InventoryTab } from '../../../store/purchaseOrders/types/InventoryTab';
import { useStockLevelsFilters } from '../../../store/stockLevels/useStockLevelsFilters';
import { InventoryTabs } from '../InventoryTabs';
import { WarehouseBlockFilter } from '../purchaseOrders/components/filter/WarehouseBlockFilter';
import { StockLevelsPageMenu } from './components/StockLevelsPageMenu';
import { LookupItemModal } from './LookupItemModal';
import { MinAvailabilityModal } from './minAvailability/MinAvailabilityModal';
import { StockLevelsTable } from './StockLevelsTable';

interface StockLevelsPageProps {
    onOpenNewItem(): void;
    onReceiveItem(): void;
    onOpenItemDetails(id: number): void;
}

export const StockLevelsPage: FC<StockLevelsPageProps> = ({
    onOpenNewItem,
    onReceiveItem,
    onOpenItemDetails,
}) => {
    const [showItemLookupModal, setShowItemLookupModal] = useState(false);
    const [showMinAvailabilityUploadModal, setShowMinAvailabilityUploadModal] = useState(false);

    const initialFilters: StockLevelsFilters = initialStockLevelsFilters;
    const { data: allWarehouses } = useHofyWarehousesQuery();
    const mainWarehouses = allWarehouses.filter(w => w.hofyWarehouse.type === HofyWarehouseType.Main);

    initialFilters.warehouses = mainWarehouses.map(w => w.id);
    const {
        queryFilters,
        filterCount,
        setSearch,
        setWarehouses,
        setFilterZeroStock,
        category,
        setCategory,
        productStatus,
        setProductStatus,
    } = useStockLevelsFilters(initialFilters);

    const { stockLevels, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
        useStockLevels(queryFilters);

    const trProductStatus = useTrProductStatus();
    const handleOpenItemDetails = (id: number) => {
        setShowItemLookupModal(false);
        onOpenItemDetails(id);
    };
    const onUploadMinAvailability = () => setShowMinAvailabilityUploadModal(true);

    const exportStockLevels = () => stockLevelsService.exportStockLevels(queryFilters);
    const selectedWarehouses = useMemo<HofyWarehouseDetailsDto[]>(() => {
        return filter(allWarehouses, w => includes(queryFilters.warehouses, w.id));
    }, [allWarehouses, queryFilters.warehouses]);

    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    return (
        <Box column flex='auto'>
            <PageHeader
                title='Stock levels'
                rightSlot={
                    <>
                        <ExportButton onClick={exportStockLevels} />
                        <LabeledSwitch
                            label='Hide inactive stock'
                            checked={queryFilters.filterZeroStock}
                            onChange={value => setFilterZeroStock(value)}
                        />
                        <SearchInput
                            value={queryFilters.search}
                            onChange={setSearch}
                            placeholder='SKU, MPN or product…'
                            autoFocus
                        />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                        <Button
                            label='Item lookup'
                            leftIcon={SvgIcon.Search}
                            onClick={() => setShowItemLookupModal(true)}
                        />
                        <StockLevelsPageMenu
                            onReceiveItem={onReceiveItem}
                            onOpenNewItem={onOpenNewItem}
                            onUploadMinAvailability={onUploadMinAvailability}
                        />
                    </>
                }
                tabsSlot={<InventoryTabs active={InventoryTab.StockLevels} />}
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <WarehouseBlockFilter
                    warehouses={allWarehouses}
                    selected={queryFilters.warehouses}
                    setSelected={setWarehouses}
                />
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <WarehouseFilterRenderer
                    warehouses={allWarehouses}
                    warehouseIds={queryFilters.warehouses}
                    onChange={setWarehouses}
                />
            </BlockFilterChipContainer>
            <Box relative flex='auto' row alignItems='stretch'>
                <Box column width={300}>
                    <HiddenScroll flex='auto' paddingHorizontal={30} paddingVertical={24} borderRight>
                        <SectionTitle2 paddingBottom={24} paddingLeft={10}>
                            Category
                        </SectionTitle2>
                        <ProductCategoryTree
                            category={category}
                            onChange={setCategory}
                            parentProductCategories={allParentProductCategories}
                            hierarchy={productCategoriesHierarchy}
                        />
                    </HiddenScroll>
                    <Box borderTop borderRight paddingLeft={30} paddingVertical={24}>
                        <RadioFilter
                            title='Product status'
                            options={allProductStatuses}
                            selected={productStatus}
                            onChange={status => setProductStatus(status)}
                            optionToText={trProductStatus}
                        />
                    </Box>
                </Box>
                <StockLevelsTable
                    stockLevels={stockLevels}
                    warehouses={selectedWarehouses}
                    infinityScroll={{
                        hasMore: hasNextPage,
                        isLoading: isLoading,
                        isLoadingMore: isFetchingNextPage,
                        loadMore: fetchNextPage,
                    }}
                />
            </Box>

            <Modals>
                {showItemLookupModal && (
                    <LookupItemModal
                        onCancel={() => setShowItemLookupModal(false)}
                        onOpenItemDetails={handleOpenItemDetails}
                    />
                )}
                {showMinAvailabilityUploadModal && (
                    <MinAvailabilityModal onClose={() => setShowMinAvailabilityUploadModal(false)} />
                )}
            </Modals>
        </Box>
    );
};
