import { sortBy } from 'lodash';
import React, { FC } from 'react';

import { VariantDto } from '@hofy/api-admin';
import { FormDropdown } from '@hofy/common';
import { BoxProps } from '@hofy/ui';

import { useVariants } from '../../hooks/useVariants';

interface VariantPickerProps extends BoxProps {
    variant: number | null;
    disabled?: boolean;
    isError?: boolean | string;
    onVariantSelected(variant: VariantDto, name: string): void;
}

export const VariantDropdown: FC<VariantPickerProps> = ({
    variant,
    disabled,
    isError,
    onVariantSelected,
    ...boxProps
}) => {
    const { variants, variantMap, variantProductMap, isLoading } = useVariants();

    const handleVariantSelect = (v: number) => {
        onVariantSelected(variantMap[v], variantProductMap[v]);
    };

    return (
        <FormDropdown
            label='Variant'
            disabled={disabled || isLoading}
            onChange={handleVariantSelect}
            items={sortBy(variants, [v => v.sku]).map(v => v.id)}
            value={variant}
            labelFormatter={v => (isLoading ? 'Loading variants...' : variantMap[v].sku)}
            clearable={false}
            searchable
            toSearchLabel={v => variantMap[v].sku || ''}
            emptyContent='Select SKU'
            isError={isError}
            {...boxProps}
        />
    );
};
