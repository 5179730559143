import React, { FC, ReactNode, useState } from 'react';

import { UUID } from '@hofy/global';
import { Palette } from '@hofy/theme';

import { easeInOutTransition } from '../../../config/animations';
import { ExpandHeight } from '../../animations/index';
import { Box } from '../../base/index';
import { NavigationSelect } from '../components/NavigationSelect';
import { useNavigationContext } from '../NavigationContext';
import { NavigationProfileContent } from './NavigationProfileContent';

interface NavigationProfileProps {
    name: string;
    subtitle: ReactNode;
    userUuid: UUID;
    pictureUrl?: string | null;
    children: ReactNode;
}

export const NavigationProfile: FC<NavigationProfileProps> = ({
    name,
    subtitle,
    userUuid,
    pictureUrl,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { collapsed } = useNavigationContext();

    return (
        <NavigationSelect height={50} isOpen={isOpen} setIsOpen={setIsOpen} testKey='user-menu-dropdown'>
            <ExpandHeight duration={easeInOutTransition.duration} testKey='content'>
                {isOpen && (
                    <Box column gap={8} paddingVertical={8} onClick={() => setIsOpen(false)}>
                        {children}
                    </Box>
                )}
            </ExpandHeight>
            <NavigationProfileContent
                testKey='top-content'
                isOpen={isOpen}
                onClick={() => setIsOpen(!isOpen)}
                collapsed={collapsed}
                name={name}
                subtitle={subtitle}
                userUuid={userUuid}
                pictureUrl={pictureUrl || null}
                height={50}
                bg={Palette.white['-7']}
                rounded
            />
        </NavigationSelect>
    );
};
