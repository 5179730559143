import { useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';

import {
    PurchaseOrderDetailsDto,
    PurchaseOrderPayload,
    purchaseOrderService,
    useHofySubsidiaryQuery,
    useHofyWarehousesQuery,
    useShipmentQuery,
    useSupplierDetailsQuery,
} from '@hofy/api-admin';
import { isShipmentFromSupplierToUser, PurchaseOrderStatus } from '@hofy/api-shared';
import {
    DateString,
    decimalToNumber,
    isNegative,
    isNegativePercent,
    isZeroDecimal,
    Percent,
    percentToNumber,
    Price,
    priceToNumber,
    sumPrice,
    zeroPrice,
} from '@hofy/global';
import { arrayRemove, arrayUpdateAt, errorMap, isEmpty } from '@hofy/helpers';
import { useNumberQueryParam } from '@hofy/router';
import { UseForm, useForm, useToast } from '@hofy/ui';

import {
    createUpdatePurchaseOrderFormDataToPayload,
    emptyPurchaseOrderFormData,
    emptyPurchaseOrderItemFormData,
    PurchaseOrderFormData,
    PurchaseOrderFormItemData,
} from './types/PurchaseOrderFormData';

export interface PurchaseOrderFormValidation {
    supplierId: string;
    currency: string;
    hofySubsidiaryId: string;
    estimatedReceiptOn: string;
    orderedOn: string;
    toWarehouseId: string;
    toUserId: string;
    purchaseOrderReference: string;
    items: Partial<PurchaseOrderItemFormValidation>[];
    noItemsError: string;
    basicGenericError: string;
    itemsGenericError: string;
    totalHandlingCost: string;
}

interface PurchaseOrderItemFormValidation {
    variant: string;
    supplierCode: string;
    quantity: string;
    unitPrice: string;
    taxRate: string;
    warranty: string;
}

type PurchaseOrderForm = UseForm<PurchaseOrderFormData, Partial<PurchaseOrderFormValidation>>;

const validatePurchaseOrderForm = (
    {
        supplierId,
        currency,
        isDropship,
        hofySubsidiary,
        estimatedReceiptOn,
        toWarehouseId,
        toUserId,
        orderedOn,
        items,
        purchaseOrderReference,
        totalHandlingCost,
        totalValue,
    }: PurchaseOrderFormData,
    validateItems: boolean,
    purchaseOrder?: PurchaseOrderDetailsDto,
): Partial<PurchaseOrderFormValidation> => {
    const isTotalAllowedToChange = purchaseOrder?.status !== PurchaseOrderStatus.PendingReceipt;

    const validateItem = (item: PurchaseOrderFormItemData): Partial<PurchaseOrderItemFormValidation> => ({
        variant: errorMap([item.variant === undefined, 'Variant must be selected']),
        supplierCode: errorMap([
            item.supplierCode ? item.supplierCode.trim() === '' : false,
            'Supplier code must not be whitespaces',
        ]),
        quantity: errorMap(
            [item.quantity === 0, 'Quantity must not be zero'],
            [item.quantity < 0, 'Quantity must not be negative'],
        ),
        unitPrice: errorMap(
            [isZeroDecimal(item.unitNetPrice.amount), 'Price must not be zero'],
            [isNegative(item.unitNetPrice), 'Price must not be negative'],
        ),
        taxRate: errorMap([isNegativePercent(item.taxRate), 'Tax must not be negative']),
        warranty: errorMap(
            [item.warranty !== null && item.warranty! <= 0, 'Warranty must be positive or blank'],
            [(item.warranty ?? 0) > 10, 'Warranty cannot be over 10 years'],
        ),
    });

    return {
        supplierId: errorMap([isEmpty(supplierId), 'Supplier is required']),
        currency: errorMap([isEmpty(currency), 'Currency is required']),
        hofySubsidiaryId: errorMap([isEmpty(hofySubsidiary), 'Hofy subsidiary is required']),
        estimatedReceiptOn: errorMap([isEmpty(estimatedReceiptOn), 'Estimated receipt date is required']),
        orderedOn: errorMap([isEmpty(orderedOn), 'Order date is required']),
        toWarehouseId: errorMap([!isDropship && isEmpty(toWarehouseId), 'Warehouse is required']),
        toUserId: errorMap([isDropship && isEmpty(toUserId), 'User is required']),
        purchaseOrderReference: errorMap([
            isEmpty(purchaseOrderReference),
            'Purchase order reference is required',
        ]),
        totalHandlingCost: errorMap([isNegative(totalHandlingCost), 'Handling cost cannot be negative']),
        items: validateItems ? items.map(validateItem) : [],
        noItemsError: errorMap([validateItems && items.length === 0, 'At least one item is required']),
        itemsGenericError: errorMap([
            !isTotalAllowedToChange && purchaseOrder?.totalPrice.amount !== totalValue.amount,
            'Total value is not allowed to be updated for the current purchase order.',
        ]),
    };
};

export const usePurchaseOrderForm = (
    onSubmit: (purchaseOrder: PurchaseOrderPayload) => void,
    isCreate: boolean = true,
    purchaseOrder?: PurchaseOrderDetailsDto,
) => {
    const [validateItems, setValidateItems] = useState(false);
    const { data: allWarehouses } = useHofyWarehousesQuery();
    const { showToast } = useToast();

    const form: PurchaseOrderForm = useForm<
        PurchaseOrderFormData,
        PurchaseOrderFormData,
        Partial<PurchaseOrderFormValidation>
    >({
        initial: emptyPurchaseOrderFormData,
        onSubmit: data => {
            onSubmit(createUpdatePurchaseOrderFormDataToPayload(data));
        },
        validate: state => validatePurchaseOrderForm(state, validateItems, purchaseOrder),
        validateDeps: [validateItems, purchaseOrder],
        onInvalid: () => {
            showToast({
                type: 'negative',
                message:
                    form.errors.itemsGenericError || 'Please complete all the highlighted required fields',
            });
        },
    });
    const warehouse = useMemo(
        () => allWarehouses.find(w => w.id === form.values.toWarehouseId),
        [form.values.toWarehouseId, allWarehouses],
    );
    const [shipmentId] = useNumberQueryParam('shipmentId');
    const { data: shipment } = useShipmentQuery(form.values.shipmentId ?? undefined);
    const [supplierId] = useNumberQueryParam('supplierId');
    const { data: supplier } = useSupplierDetailsQuery(form.values.supplierId);
    const { data: subsidiary } = useHofySubsidiaryQuery(
        form.values.toWarehouse?.address?.country ?? shipment?.toAddress?.country,
    );
    const previousCurrencyValue = usePrevious(form.values.currency);

    useEffect(() => {
        if (isCreate && !form.values.purchaseOrderReference) {
            purchaseOrderService.generatePurchaseOrderReference().then(purchaseOrderReference => {
                form.setValues({
                    purchaseOrderReference,
                });
            });
        }
    }, [isCreate, form.values.purchaseOrderReference]);

    useEffect(() => {
        if (!subsidiary) {
            return;
        }
        form.setValues({
            hofySubsidiary: subsidiary,
            hofySubsidiaryId: subsidiary?.id,
        });
    }, [subsidiary]);

    useEffect(() => {
        if (!supplier) {
            return;
        }
        form.setValues({
            supplier,
            currency: supplier?.currency,
        });
    }, [supplier]);

    useEffect(() => {
        if (supplierId) {
            form.setValues({
                supplierId,
            });
        }
    }, [supplierId]);

    useEffect(() => {
        if (shipmentId) {
            form.setValues({
                shipmentId,
            });
        }
    }, [shipmentId]);

    useEffect(() => {
        const warehouse = allWarehouses.find(w => w.id === form.values.toWarehouseId);
        if (warehouse) {
            form.setValues({
                toWarehouse: warehouse,
            });
        }
    }, [form.values.toWarehouseId]);

    useEffect(() => {
        const newCurrency = form.values.currency;
        if (previousCurrencyValue && newCurrency) {
            form.setValues(prevState => {
                const items = prevState.items.map(item => {
                    const { unitNetPrice: currentUnitPrice, totalGrossPrice: currentTotalPrice } = item;
                    return {
                        ...item,
                        unitNetPrice: {
                            amount: currentUnitPrice.amount,
                            currency: newCurrency,
                        },
                        totalGrossPrice: {
                            amount: currentTotalPrice.amount,
                            currency: newCurrency,
                        },
                    };
                });
                const { totalNetValue, totalHandlingCost, totalValue } = prevState;
                return {
                    items,
                    totalNetValue: {
                        amount: totalNetValue.amount,
                        currency: newCurrency,
                    },
                    totalHandlingCost: {
                        amount: totalHandlingCost.amount,
                        currency: newCurrency,
                    },
                    totalValue: {
                        amount: totalValue.amount,
                        currency: newCurrency,
                    },
                };
            });
        }
    }, [form.values.currency]);

    useEffect(() => {
        if (!isShipmentFromSupplierToUser(shipment)) {
            return;
        }
        if (!form.values.isDropship) {
            const items = shipment.assignments.map(assignment => {
                return {
                    variant: assignment.variant.id,
                    quantity: 1,
                    name: assignment.product.name,
                    supplierCode: '',
                    taxRate: '0.00',
                    unitNetPrice: zeroPrice(form.values.currency),
                    totalGrossPrice: zeroPrice(form.values.currency),
                    estimatedReceiveOn: shipment.estimatedAvailability?.from ?? null,
                    notes: null,
                    warranty: null,
                };
            });

            form.setValues({
                toUserId: shipment.toUser.id,
                toUser: shipment.toUser,
                isDropship: true,
                items,
            });
        } else if (!form.values.toUser) {
            form.setValues({
                toUser: shipment.toUser,
            });
        }
    }, [shipment]);

    const updateTotalValues = () => {
        const totalNetValue = form.values.items.reduce((sum, item) => {
            const netAmount = item.quantity * decimalToNumber(item.unitNetPrice.amount);
            const itemPrice = {
                currency: item.unitNetPrice.currency,
                amount: netAmount.toFixed(2),
            };
            return sumPrice(sum, itemPrice);
        }, zeroPrice(form.values.currency));

        const itemsValue = form.values.items.reduce((sum, item) => {
            return sumPrice(sum, item.totalGrossPrice);
        }, zeroPrice(form.values.currency));
        const totalValue = sumPrice(itemsValue, form.values.totalHandlingCost);
        form.setValues({
            totalNetValue,
            totalValue,
        });
    };

    useEffect(() => {
        updateTotalValues();
    }, [form.values.items, form.values.items.length, form.values.totalHandlingCost]);

    const calculateTotalGrossAmount = (unitNetPrice: Price, quantity: number, tax: Percent) => {
        const totalNetAmount = quantity * priceToNumber(unitNetPrice);
        const taxAmount = totalNetAmount * (percentToNumber(tax || '0') / 100);
        const totalGrossAmount = totalNetAmount + taxAmount;
        return totalGrossAmount.toFixed(2);
    };

    const updateItem = (index: number, t: Partial<PurchaseOrderFormItemData>) => {
        form.setValues({
            items: arrayUpdateAt(form.values.items, index, item => ({
                ...item,
                ...t,
            })),
        });
    };

    const updateItemWarranty = (index: number, warranty: number | null) => {
        updateItem(index, {
            warranty: warranty !== 0 ? warranty : null,
        });
    };

    const updateItemQuantity = (index: number, quantity?: number) => {
        const item = form.values.items[index];
        const totalGrossAmount = calculateTotalGrossAmount(item.unitNetPrice, quantity || 0, item.taxRate);
        updateItem(index, {
            quantity: quantity || 0,
            totalGrossPrice: {
                currency: item.totalGrossPrice.currency,
                amount: totalGrossAmount,
            },
        });
        updateTotalValues();
    };

    const updateItemUnitNetPrice = (index: number, price?: Price) => {
        const item = form.values.items[index];
        const unitNetPrice = price ?? zeroPrice(form.values.currency);
        const totalGrossAmount = calculateTotalGrossAmount(unitNetPrice, item.quantity, item.taxRate);
        updateItem(index, {
            unitNetPrice,
            totalGrossPrice: {
                currency: item.totalGrossPrice.currency,
                amount: totalGrossAmount,
            },
        });
        updateTotalValues();
    };

    const updateItemTaxRate = (index: number, taxRate: Percent) => {
        const item = form.values.items[index];
        const totalGrossAmount = calculateTotalGrossAmount(
            item.unitNetPrice,
            item.quantity,
            taxRate || '0.00',
        );
        updateItem(index, {
            taxRate: taxRate || '0.00',
            totalGrossPrice: {
                currency: item.totalGrossPrice.currency,
                amount: totalGrossAmount,
            },
        });
        updateTotalValues();
    };

    const updateItemSupplierCode = (index: number, supplierCode: string | null) => {
        updateItem(index, {
            supplierCode: supplierCode ? supplierCode : null,
        });
    };

    const updateItemVariant = (
        index: number,
        variant: {
            id: number;
            manufacturerPartCode: string | null;
        },
        name?: string,
    ) => {
        updateItem(index, {
            variant: variant.id,
            name,
            supplierCode: variant.manufacturerPartCode,
        });
    };

    const updateItemEstimatedReceiveOn = (index: number, time: DateString | null) => {
        updateItem(index, {
            estimatedReceiveOn: time,
        });
    };

    const updateItemNotes = (index: number, notes: string | null) => {
        updateItem(index, {
            notes,
        });
    };

    const deleteItem = (index: number) => {
        form.setValues({
            items: arrayRemove(form.values.items, index),
        });
    };

    const addItem = () => {
        form.setValues({
            items: [
                ...form.values.items,
                emptyPurchaseOrderItemFormData(form.values.currency, form.values.estimatedReceiptOn!),
            ],
        });
    };

    return {
        form,
        updateItemQuantity,
        updateItemWarranty,
        updateItemUnitPrice: updateItemUnitNetPrice,
        updateItemTaxRate,
        updateItemSupplierCode,
        updateItemVariant,
        updateItemEstimatedReceiveOn,
        updateItemNotes,
        deleteItem,
        addItem,
        setValidateItems,
        warehouse,
        deliveryAddress: form.values.isDropship ? shipment?.toAddress : warehouse?.address,
        supplier,
    };
};
