import { useMutation, useQueryClient } from '@tanstack/react-query';

import { isShorterThan, useForm, useToast, validator } from '@hofy/ui';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import { OrganizationDetailsDto } from '../../services/organizations/types/OrganizationDto';
import { UpdateOrganizationStatusPayload } from '../../services/organizations/types/UpdateOrganizationStatusPayload';
import { organizationsCacheKey } from './organizationsCacheKey';

export const useAdminUpdateOrganizationStatus = (
    organization: OrganizationDetailsDto,
    onSuccess?: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: UpdateOrganizationStatusPayload) =>
            adminOrganizationsService.updateOrganizationStatus(organization.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organization.id] });
            showToast({
                message: `Organization ${organization.name} status successfully updated`,
                type: 'positive',
            });
            onSuccess?.();
        },
    });

    const form = useForm<UpdateOrganizationStatusPayload>({
        initial: {
            status: organization.status,
            trialEndOn: organization.trialEndOn,
            bannerMessage: organization.bannerMessage || '',
        },
        onSubmit: mutation.mutate,
        validate: validator<UpdateOrganizationStatusPayload>({
            bannerMessage: [isShorterThan(250, 'Message cannot be longer than 250 characters')],
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};
