import { last } from 'lodash';
import React, { FC, ReactNode, useState } from 'react';

import { InspectionWithDeviceCheckDetailsDto } from '@hofy/api-admin';
import {
    getInspectionDeviceCheckStatusVisualType,
    InspectionDeviceCheckStatus,
    StatusUpdateDto,
    useTrInspectionDeviceCheckStatus,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { RadioGroup } from '@hofy/common';
import { UUID } from '@hofy/global';
import {
    Alert,
    AsyncButton,
    Box,
    Button,
    FormSection,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Modals,
    Paragraph3,
} from '@hofy/ui';

import {
    getInspectionDeviceCheckTypeLabel,
    InspectionDeviceCheckType,
} from '../../../store/inspections/InspectionDeviceCheckType';
import { useUpdateInspectionDeviceCheck } from '../../../store/inspections/useUpdateInspectionDeviceCheck';
import { InspectionTimeline } from '../components/InspectionTimeline';

interface DeviceCheckProgressSectionProps {
    inspection: InspectionWithDeviceCheckDetailsDto;
}

export const DeviceCheckProgressSection: React.FC<DeviceCheckProgressSectionProps> = ({
    inspection: { uuid, deviceCheck },
}) => {
    const { session } = useSession();

    return (
        <FormSection label='Device check progress'>
            {deviceCheck.activationLockRemovalStatus && (
                <DeviceCheckProgressStep
                    uuid={uuid}
                    type={InspectionDeviceCheckType.ActivationLockRemoval}
                    status={deviceCheck.activationLockRemovalStatus}
                    statusUpdates={deviceCheck.activationLockRemovalStatusUpdates}
                    adminId={session.userId}
                />
            )}
            <DeviceCheckProgressStep
                uuid={uuid}
                type={InspectionDeviceCheckType.UserAccountRemoval}
                status={deviceCheck.userAccountRemovalStatus}
                statusUpdates={deviceCheck.userAccountRemovalStatusUpdates}
                adminId={session.userId}
            />
            {deviceCheck.orgMDMRemovalStatus && (
                <DeviceCheckProgressStep
                    uuid={uuid}
                    type={InspectionDeviceCheckType.OrgMDMRemoval}
                    status={deviceCheck.orgMDMRemovalStatus}
                    statusUpdates={deviceCheck.orgMDMRemovalStatusUpdates}
                    adminId={session.userId}
                />
            )}
            {deviceCheck.pinLockRemovalStatus && (
                <DeviceCheckProgressStep
                    uuid={uuid}
                    type={InspectionDeviceCheckType.PinLockRemoval}
                    status={deviceCheck.pinLockRemovalStatus}
                    statusUpdates={deviceCheck.pinLockRemovalStatusUpdates}
                    adminId={session.userId}
                />
            )}
        </FormSection>
    );
};

interface DeviceCheckProgressStepProps {
    uuid: UUID;
    type: InspectionDeviceCheckType;
    status: InspectionDeviceCheckStatus;
    statusUpdates: StatusUpdateDto<InspectionDeviceCheckStatus>[];
    adminId: number;
}

const DeviceCheckProgressStep: FC<DeviceCheckProgressStepProps> = ({
    uuid,
    type,
    status,
    statusUpdates,
    adminId,
}) => {
    const trInspectionDeviceCheckStatus = useTrInspectionDeviceCheckStatus();

    return (
        <Box column gap={10}>
            <Alert
                description={
                    <Box row gap={10} justify='space-between'>
                        <Paragraph3 bold>{getInspectionDeviceCheckTypeLabel(type)}</Paragraph3>
                        <Box row gap={10}>
                            {getInspectionDeviceCheckActions(status, statusUpdates, adminId).map(action => (
                                <ActionWrapper key={action.label} uuid={uuid} type={type} {...action} />
                            ))}
                        </Box>
                    </Box>
                }
                type={getInspectionDeviceCheckStatusVisualType(status)}
            >
                <InspectionTimeline
                    statusUpdates={statusUpdates}
                    statusSequence={[
                        InspectionDeviceCheckStatus.Pending,
                        InspectionDeviceCheckStatus.InProgress,
                        InspectionDeviceCheckStatus.Completed,
                        InspectionDeviceCheckStatus.Verified,
                    ]}
                    toStatus={trInspectionDeviceCheckStatus}
                />
            </Alert>
        </Box>
    );
};

interface DeviceCheckAction {
    label: string;
    isDestructive: boolean;
    nextStatus: InspectionDeviceCheckStatus;
    requiresConfirmation?: {
        title: string;
        content: ReactNode;
        confirmationLabel: string;
        isConfirmationDestructive: boolean;
    };
}

const getInspectionDeviceCheckActions = (
    status: InspectionDeviceCheckStatus,
    statusUpdates: StatusUpdateDto<InspectionDeviceCheckStatus>[],
    adminId: number,
): DeviceCheckAction[] => {
    switch (status) {
        case InspectionDeviceCheckStatus.Pending:
            return [
                {
                    label: 'Not applicable',
                    nextStatus: InspectionDeviceCheckStatus.Cancelled,
                    isDestructive: true,
                    requiresConfirmation: {
                        title: 'Task not applicable',
                        content: (
                            <Alert
                                description='Please only skip this task if it is not applicable e.g if the device activation lock has already been completed by the team member.
                                Doing so will mark it as not applicable.
                                Are you sure you want to proceed?'
                                type='negative'
                            />
                        ),
                        confirmationLabel: 'Mark as not applicable',
                        isConfirmationDestructive: true,
                    },
                },
                {
                    label: 'Start',
                    nextStatus: InspectionDeviceCheckStatus.InProgress,
                    isDestructive: false,
                },
            ];
        case InspectionDeviceCheckStatus.InProgress:
            return [
                {
                    label: 'Mark as unsuccessful',
                    nextStatus: InspectionDeviceCheckStatus.Unsuccessful,
                    isDestructive: true,
                    requiresConfirmation: {
                        title: 'Mark as unsuccessful',
                        content: (
                            <Alert
                                description={
                                    <Box column gap={10}>
                                        <Paragraph3>
                                            Please confirm this task could not be completed despite our best
                                            effort.
                                        </Paragraph3>
                                        <Box padding={10} column gap={10}>
                                            <Paragraph3 bold>
                                                • If the device is a customer owned + store and reuse, the
                                                organization will no longer be able to use this device and
                                                need to be contacted.
                                            </Paragraph3>
                                            <Paragraph3 bold>
                                                • If the device belongs to Hofy, please check if the damage is
                                                outside warranty and if Hofy should invoice the customer. If
                                                the we were collecting through a store and reuse, we might
                                                want to cancel the store and reuse as well.
                                            </Paragraph3>
                                        </Box>
                                        <Paragraph3>Are you sure you want to proceed?</Paragraph3>
                                    </Box>
                                }
                                type='negative'
                            />
                        ),
                        confirmationLabel: 'Mark as unsuccessful',
                        isConfirmationDestructive: true,
                    },
                },
                {
                    label: 'Mark as completed',
                    nextStatus: InspectionDeviceCheckStatus.Completed,
                    isDestructive: false,
                },
            ];
        case InspectionDeviceCheckStatus.Completed:
            return [
                {
                    label: 'Mark as verified',
                    nextStatus: InspectionDeviceCheckStatus.Verified,
                    isDestructive: false,
                    ...(adminId === last(statusUpdates)?.updatedBy?.id && {
                        requiresConfirmation: {
                            title: '4-eye audit check',
                            content: (
                                <Alert
                                    description='This action requires 4-eye audit check and should be verified by another admin. Are you sure you want to proceed anyway?'
                                    type='warning'
                                />
                            ),
                            confirmationLabel: 'Mark as verified',
                            isConfirmationDestructive: true,
                        },
                    }),
                },
            ];
        case InspectionDeviceCheckStatus.Escalated:
        case InspectionDeviceCheckStatus.Verified:
        case InspectionDeviceCheckStatus.Unsuccessful:
        case InspectionDeviceCheckStatus.Cancelled:
            return [];
        default:
            status satisfies never;
            return [];
    }
};

interface ActionWrapperProps extends DeviceCheckAction {
    uuid: UUID;
    type: InspectionDeviceCheckType;
}

const ActionWrapper = ({
    uuid,
    type,
    label,
    nextStatus,
    isDestructive,
    requiresConfirmation,
}: ActionWrapperProps) => {
    const {
        updateInspectionDeviceCheck: update,
        isLoading,
        isError,
    } = useUpdateInspectionDeviceCheck(uuid, type);

    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState<boolean>(false);
    const [isEscalationModalVisible, setIsEscalationModalVisible] = useState<boolean>(false);
    const [isEscalated, setIsEscalated] = useState<boolean | null>(null);

    const handleAction = () => {
        if (requiresConfirmation) {
            setIsConfirmationModalVisible(true);
            return;
        }

        if (nextStatus === InspectionDeviceCheckStatus.Completed) {
            setIsEscalationModalVisible(true);
            return;
        }

        update(nextStatus);
    };

    return (
        <>
            <AsyncButton
                key={label}
                label={label}
                action={isDestructive ? 'destructive' : 'default'}
                onClick={handleAction}
                isLoading={isLoading}
                isError={isError}
            />
            <Modals>
                {isConfirmationModalVisible && requiresConfirmation && (
                    <Modal onClose={() => setIsConfirmationModalVisible(false)} width={600}>
                        <ModalHeader title={requiresConfirmation.title} />
                        <ModalContent>{requiresConfirmation.content}</ModalContent>
                        <ModalFooter>
                            <Button
                                label='Close'
                                type='secondary'
                                onClick={() => setIsConfirmationModalVisible(false)}
                            />
                            <Button
                                label={requiresConfirmation.confirmationLabel}
                                action={
                                    requiresConfirmation.isConfirmationDestructive ? 'destructive' : 'default'
                                }
                                onClick={() => {
                                    update(nextStatus);
                                    setIsConfirmationModalVisible(false);
                                }}
                            />
                        </ModalFooter>
                    </Modal>
                )}
                {isEscalationModalVisible && (
                    <Modal onClose={() => setIsEscalationModalVisible(false)} width={600}>
                        <ModalHeader title='Escalation check' />
                        <ModalContent>
                            <Box column gap={16}>
                                <Paragraph3>
                                    During {getInspectionDeviceCheckTypeLabel(type)}, did Hofy need to reach
                                    out to a team member or organisation for more information to complete the
                                    task?
                                </Paragraph3>
                                <RadioGroup
                                    orientation='vertical'
                                    items={['Yes', 'No']}
                                    onChange={v => setIsEscalated(v === 'Yes')}
                                    value={getEscalationRadioValue(isEscalated)}
                                    labelFormatter={option => <Paragraph3>{option}</Paragraph3>}
                                />
                            </Box>
                        </ModalContent>
                        <ModalFooter>
                            <Button
                                label='Close'
                                type='secondary'
                                onClick={() => {
                                    setIsEscalated(null);
                                    setIsEscalationModalVisible(false);
                                }}
                            />
                            <Button
                                label='Mark as completed'
                                onClick={() => {
                                    update(isEscalated ? InspectionDeviceCheckStatus.Escalated : nextStatus);
                                    setIsEscalationModalVisible(false);
                                }}
                                disabled={isEscalated === null}
                            />
                        </ModalFooter>
                    </Modal>
                )}
            </Modals>
        </>
    );
};

const getEscalationRadioValue = (isEscalated: boolean | null): string | null => {
    if (isEscalated === null) {
        return null;
    }
    return isEscalated ? 'Yes' : 'No';
};
