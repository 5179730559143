import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

import { itemsService } from '@hofy/api-admin';
import { zeroDollars } from '@hofy/global';

export const useItemEstimatedValue = (variantId?: number, warehouseId?: number) => {
    const { mutate, isPending, data } = useMutation({
        mutationFn: () => itemsService.getItemEstimatedValue(variantId!, warehouseId!),
    });

    useEffect(() => {
        if (variantId !== undefined && warehouseId !== undefined) {
            mutate();
        }
    }, [variantId, warehouseId]);

    return {
        isPending,
        value: data?.cost ?? zeroDollars,
    };
};
