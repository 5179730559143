import React, { FC, useState } from 'react';

import { isAdminHofyWarehouse, ShipmentDetailsDto } from '@hofy/api-admin';
import {
    CodeRefDto,
    isDefectiveStatus,
    isShipmentToHofyWarehouse,
    ItemStatus,
    ProductDto,
    VariantDetailsDto,
    WarehouseDto,
} from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import {
    Alert,
    AsyncButton,
    Box,
    Button,
    ConfirmModal,
    FormContainer,
    FormSection,
    LabeledText,
    Modals,
    Paragraph3,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { ItemCodeSelector } from '../../../components/domain/items/ItemCodeSelector';
import { WarehouseBinSelector } from '../../../components/domain/warehouses/WarehouseBinSelector';
import { useItemCheckInCoreWarehouse } from '../../../store/shipments/useItemCheckIn';
import { useRemoveAssignmentsFromShipment } from '../../../store/shipments/useRemoveAssignmentsFromShipment';
import { LabeledItemCodes } from '../../itemsPage/LabeledItemCodes';
import { ProductVariantDetails } from '../components/ProductVariantDetails';

enum CollectionItemCheckInSlideoutModal {
    RemoveAssignmentFromShipment = 'remove-assignment-from-shipment',
}

interface CollectionItemCheckInSlideoutProps {
    assignmentId: number;
    product: ProductDto;
    variant: VariantDetailsDto;
    item: {
        warehouse: WarehouseDto | null;
        id: number;
        status: ItemStatus;
        serialNumber: string | null;
        itemCodes: CodeRefDto[];
    };
    shipment: ShipmentDetailsDto;
    scannedCode: string | null;
    onClose(): void;
    onSuccess(): void;
}

export const CollectionItemCheckInSlideout: FC<CollectionItemCheckInSlideoutProps> = ({
    assignmentId,
    product,
    variant,
    item,
    shipment,
    scannedCode,
    onClose,
    onSuccess,
}) => {
    const [modal, setModal] = useState<CollectionItemCheckInSlideoutModal>();
    const { form, isLoading } = useItemCheckInCoreWarehouse(item.id, assignmentId, scannedCode, onSuccess);

    if (!isShipmentToHofyWarehouse(shipment)) {
        return null;
    }

    return (
        <Slideout width={600} onClose={onClose}>
            <SlideoutHeader title='Check in item' />
            <SlideoutContent column gap={30} paddingVertical={30}>
                <FormSection label='Product details'>
                    <ProductVariantDetails product={product} variant={variant} />
                </FormSection>
                <FormSection label='Item details'>
                    <FormContainer>
                        {isDefectiveStatus(item.status) && (
                            <Alert
                                type='warning'
                                marginBottom={20}
                                description='Item marked as damaged or broken'
                            />
                        )}
                        <Box row>
                            <LabeledItemCodes flex={1} label='Item codes' itemCodes={item.itemCodes} />
                            <LabeledText flex={1} label='Serial number' content={item.serialNumber} />
                        </Box>
                        <ItemCodeSelector
                            api={form.fields.code}
                            itemId={item.id}
                            variant={variant}
                            product={product}
                            isRequired
                            isAddItemCodePromptEnabled
                        />
                        {shipment.toWarehouse && (
                            <WarehouseBinSelector
                                api={form.fields.warehouseBinIdentifier}
                                warehouse={shipment.toWarehouse}
                                isAddBinPromptEnabled
                                isRequired
                            />
                        )}
                        <Box row gap={4}>
                            <Paragraph3>Item did not arrive?</Paragraph3>
                            <Button
                                type='plain'
                                action='destructive'
                                label='Remove item from current shipment'
                                onClick={() =>
                                    setModal(CollectionItemCheckInSlideoutModal.RemoveAssignmentFromShipment)
                                }
                            />
                        </Box>
                    </FormContainer>
                </FormSection>
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton isLoading={isLoading} disableCheck onClick={form.submit} label='Check in item' />
            </SlideoutFooter>
            <Modals>
                {modal && isAdminHofyWarehouse(shipment.toWarehouse) && (
                    <CollectionItemCheckInSlideoutModalRenderer
                        modal={modal}
                        assignmentId={assignmentId}
                        shipmentId={shipment.id}
                        onCancel={() => setModal(undefined)}
                    />
                )}
            </Modals>
        </Slideout>
    );
};

interface CollectionItemCheckInSlideoutModalRendererProps {
    modal: CollectionItemCheckInSlideoutModal;
    assignmentId: number;
    shipmentId: number;
    onCancel(): void;
}

const CollectionItemCheckInSlideoutModalRenderer: FC<CollectionItemCheckInSlideoutModalRendererProps> = ({
    modal,
    assignmentId,
    shipmentId,
    onCancel,
}) => {
    const { removeAssignmentFromShipment } = useRemoveAssignmentsFromShipment(shipmentId);

    switch (modal) {
        case CollectionItemCheckInSlideoutModal.RemoveAssignmentFromShipment: {
            const handleRemoveAssignmentFromShipment = () => {
                removeAssignmentFromShipment([assignmentId]);
                onCancel();
            };

            return (
                <ConfirmModal
                    keyPrefix='cancel-collection-modal'
                    onClose={onCancel}
                    onConfirm={handleRemoveAssignmentFromShipment}
                />
            );
        }
    }
};
