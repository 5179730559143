import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import {
    itemCacheKey,
    ItemDto,
    toLocationPayload,
    UpdateItemPayload,
    useHofyWarehousesQuery,
} from '@hofy/api-admin';
import {
    AssignmentDto,
    AssignmentStatus,
    isInOrganizationWarehouse,
    ItemPart,
    Ownership,
    Permission,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { DateTimeString } from '@hofy/global';
import { errorMap, isEmpty, parseOptionalDateTime } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

import { useItemMutation } from './useItemMutation';

export const useUpdateItem = (item: ItemDto, onEnd?: () => void) => {
    const queryClient = useQueryClient();
    const { hasPermission } = useSession();
    const { showToast } = useToast();
    const { warehouses, isWarehouseBinRequired } = useItemWarehouses(item);

    const isLocationEditable = useMemo(() => {
        const lastCollected = getLastCollectedAssignment(item.assignments);
        if (!lastCollected) {
            return true;
        }
        if (lastCollected.status === AssignmentStatus.Collected) {
            return true;
        }
    }, [item]);

    const mutation = useItemMutation(item.id, () => {
        queryClient.invalidateQueries({ queryKey: [itemCacheKey] });
        showToast({
            type: 'positive',
            message: `Item #${item.id} updated successfully`,
        });
        onEnd?.();
    });

    const form = useForm<UpdateItemPayload>({
        initial: {
            grade: item.grade,
            damageNote: item.damageNote,
            damagedParts: item.damagedParts,
            damagedPartsNote: item.damagedPartsNote,
            missingParts: item.missingParts,
            missingPartsNote: item.missingPartsNote,
            isMissingOriginalPackaging: item.isMissingOriginalPackaging,
            isDeviceLocked: item.isDeviceLocked,
            serialNumber: item.serialNumber,
            isLoaner: item.isLoaner,
            usableInStoreAndReuse: item.usableInStoreAndReuse,
            clearanceQuote: item.clearanceQuote,
            clearanceValue: item.clearanceValue,
            ...toLocationPayload(item.location),
        },
        onSubmit: mutation.mutate,
        onDiscard: onEnd,
        validate: ({
            warehouseId,
            warehouseBinIdentifier,
            damagedPartsNote,
            damagedParts,
            missingPartsNote,
            missingParts,
        }) => ({
            damagedPartsNote: errorMap([
                damagedParts.includes(ItemPart.Other) && isEmpty(damagedPartsNote),
                'Damaged parts note is required',
            ]),
            missingPartsNote: errorMap([
                missingParts.includes(ItemPart.Other) && isEmpty(missingPartsNote),
                'Missing parts note is required',
            ]),
            warehouseBinIdentifier: errorMap([
                isWarehouseBinRequired(warehouseId) && isEmpty(warehouseBinIdentifier),
                'Valid warehouse bin is required for core warehouse',
            ]),
        }),
    });

    return {
        form,
        isLocationEditable,
        canUpdateLocation: item.ownership.type === Ownership.Hofy,
        hasUpdatePermissions: hasPermission(Permission.AdminItemsUpdate),
        isLoading: mutation.isPending,
        isError: mutation.isError,
        warehouses,
    };
};

export const useItemWarehouses = (item: ItemDto) => {
    const { data: allWarehouses } = useHofyWarehousesQuery();
    const hofyWarehouses = allWarehouses.map(w => ({
        id: w.id,
        name: w.name,
        isWarehouseBinRequired: w.hofyWarehouse.isWarehouseBinRequired,
    }));
    // this is hack which lets update some settings of customer owned items without confusion in the warehouse dropdown
    const warehouses = useMemo(() => {
        if (isInOrganizationWarehouse(item.location)) {
            return [
                {
                    id: item.location.warehouse?.id!,
                    name: item.location.warehouse?.name!,
                    isWarehouseBinRequired: false,
                },
                ...hofyWarehouses,
            ];
        }
        return hofyWarehouses;
    }, [hofyWarehouses, item]);

    const isWarehouseBinRequired = (warehouseId: number | null): boolean => {
        const warehouse = warehouses.find(w => w.id === warehouseId);
        return warehouse?.isWarehouseBinRequired ?? false;
    };

    return {
        warehouses,
        isWarehouseBinRequired,
    };
};

export const getLastCollectedAssignment = (assignments: AssignmentDto[]): AssignmentDto | null => {
    const orderedAssignments = assignments
        .filter(a => a.status !== AssignmentStatus.Cancelled)
        .sort((a, b) => {
            const dateA = parseOptionalDateTime(
                a.statusDates[AssignmentStatus.CollectionWithCourier] as DateTimeString,
            );
            const dateB = parseOptionalDateTime(
                b.statusDates[AssignmentStatus.CollectionWithCourier] as DateTimeString,
            );
            return (dateA?.toMillis() ?? 0) - (dateB?.toMillis() ?? 0);
        });

    return orderedAssignments[0] || null;
};
