import { filter, xor } from 'lodash';
import React, { FC, useMemo } from 'react';

import { VariantDto } from '@hofy/api-admin';
import { Box, FilterChip } from '@hofy/ui';

interface VariantsFilterRendererProps {
    variants: VariantDto[];
    variantIds: number[];
    onChange(variants: number[]): void;
}

export const SkuFilterRenderer: FC<VariantsFilterRendererProps> = ({ variants, variantIds, onChange }) => {
    const selectedVariants = useMemo(
        () => filter(variants, variant => variantIds.includes(variant.id)),
        [variants, variantIds],
    );

    const removeVariant = (variantId: number) => {
        onChange(xor(variantIds, [variantId]));
    };

    return (
        <Box row gap={10}>
            {selectedVariants.map(variant => (
                <FilterChip
                    label={variant.sku}
                    onClear={() => removeVariant(variant.id)}
                    color='red'
                    key={variant.id}
                />
            ))}
        </Box>
    );
};
