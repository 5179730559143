import { useMutation } from '@tanstack/react-query';
import { omit } from 'lodash';
import { useMemo } from 'react';

import { OrganizationStatus, PlatformTier } from '@hofy/api-shared';
import { Currency } from '@hofy/global';
import { countObjectValues } from '@hofy/helpers';
import { useDebounceValue } from '@hofy/hooks';
import { useArrayQueryParam, useNumberQueryParam, useStringQueryParam } from '@hofy/router';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import { useOrganizationsQuery } from './useOrganizationsQuery';

export const useOrganizations = () => {
    const [search, setSearch] = useStringQueryParam('search', '');
    const [statuses, setStatuses] = useArrayQueryParam<OrganizationStatus>('status');
    const [currencies, setCurrencies] = useArrayQueryParam<Currency>('currency');
    const [creditUtilisationFrom, setCreditUtilisationFrom] = useNumberQueryParam('creditUtilisationFrom');
    const [creditUtilisationTo, setCreditUtilisationTo] = useNumberQueryParam('creditUtilisationTo');
    const [accountManagers, setAccountManagers] = useArrayQueryParam<number>('accountManager');
    const [salesReps, setSalesReps] = useArrayQueryParam<number>('salesRep');
    const [platformTiers, setPlatformTiers] = useArrayQueryParam<PlatformTier>('platformTier');

    const debouncedSearch = useDebounceValue(search, 500);
    const filters = useMemo(
        () => ({
            search: debouncedSearch,
            statuses,
            currencies,
            accountManagers,
            salesReps,
            platformTiers,
        }),
        [debouncedSearch, statuses, currencies, salesReps, accountManagers, platformTiers],
    );

    const { organizations, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
        useOrganizationsQuery(filters);

    const downloadReportMutation = useMutation({
        mutationFn: adminOrganizationsService.downloadListOrganizationsReport,
    });

    const filteredOrganizations = useMemo(() => {
        return organizations.filter(
            ({ creditUtilisationPercentage }) =>
                (creditUtilisationFrom === null || creditUtilisationPercentage >= creditUtilisationFrom) &&
                (creditUtilisationTo === null || creditUtilisationPercentage <= creditUtilisationTo),
        );
    }, [creditUtilisationFrom, creditUtilisationTo, organizations]);
    const filtersToCount = omit(filters, 'search', 'creditUtilisationFrom', 'creditUtilisationTo');
    const filterCount = countObjectValues(filtersToCount);

    return {
        organizations: filteredOrganizations,
        filterCount,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
        search,
        setSearch,
        statuses,
        setStatuses,
        currencies,
        setCurrencies,
        accountManagers,
        setAccountManagers,
        platformTiers,
        setPlatformTiers,
        salesReps,
        setSalesReps,
        downloadContractReport: downloadReportMutation.mutate,
        creditUtilisationFrom,
        setCreditUtilisationFrom,
        creditUtilisationTo,
        setCreditUtilisationTo,
    };
};
