import { useMutation, useQueryClient } from '@tanstack/react-query';

import { teamCacheKey, TeamPayload, teamService } from '@hofy/api-admin';
import { isTeamNameUsed, TeamColor, TeamDto } from '@hofy/api-shared';
import { errorMap, isEmpty } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

export const useCreateTeam = (organizationId: number, teams: TeamDto[], onEnd: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: TeamPayload) => teamService.createTeam(organizationId, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [teamCacheKey] });
            showToast({
                type: 'positive',
                message: `The team has been created!`,
            });
            onEnd();
            form.reset();
        },
    });

    const form = useForm<TeamPayload>({
        initial: {
            color: TeamColor.Red,
            name: '',
        },
        onSubmit: mutation.mutate,
        onDiscard: onEnd,
        validate: ({ name }) => ({
            name: errorMap(
                [isEmpty(name), 'Team name is required'],
                [isTeamNameUsed(teams, name), 'This name is already in use by another team'],
            ),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
