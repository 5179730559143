import React, { FC, ReactNode } from 'react';

import { allOperatingSystems, OperatingSystem } from '@hofy/api-shared';
import { FormRow, MultiSelectDropdown } from '@hofy/common';
import { FormCheckbox, FormInput, FormSection, UseForm } from '@hofy/ui';

import { UpdateOrganizationFormData } from '../../../../services/organizations/types/UpdateOrganizationFormData';

interface OrganizationUpdateFeaturesTabProps {
    form: UseForm<UpdateOrganizationFormData>;
    canUpdateOrganization: boolean;
    canUpdateOrganizationStoreAndReuse: boolean;
    trOperatingSystem(v: OperatingSystem): ReactNode;
}

export const OrganizationUpdateFeaturesTab: FC<OrganizationUpdateFeaturesTabProps> = ({
    form,
    canUpdateOrganization,
    canUpdateOrganizationStoreAndReuse,
    trOperatingSystem,
}) => {
    return (
        <>
            <FormSection label='Features'>
                <FormRow>
                    <FormCheckbox
                        label='Welcome packs'
                        disabled={!canUpdateOrganization}
                        api={form.fields.welcomePacksEnabled}
                    />
                    <FormCheckbox
                        label='Skip device wiping prompt in collection survey'
                        disabled={!canUpdateOrganization}
                        api={form.fields.collectionSurveySkipDeviceReset}
                    />
                </FormRow>
                <FormRow>
                    <FormCheckbox
                        label='Service Desk'
                        disabled={!canUpdateOrganization}
                        api={form.fields.serviceDeskEnabled}
                    />
                </FormRow>
                <FormRow>
                    <FormCheckbox
                        label='DSE'
                        disabled={!canUpdateOrganization}
                        api={form.fields.dseEnabled}
                    />

                    <FormCheckbox
                        label='Store and reuse managed by Hofy'
                        disabled={!canUpdateOrganization && !canUpdateOrganizationStoreAndReuse}
                        api={form.fields.storeAndReuseManagedByHofy}
                    />
                </FormRow>
                <FormRow>
                    <FormCheckbox label='Host agent' api={form.fields.hostAgentEnabled} />
                </FormRow>
                <MultiSelectDropdown
                    label='Device Configuration'
                    items={allOperatingSystems}
                    value={form.values.deviceConfigRequired}
                    onChange={deviceConfigRequired =>
                        form.setValues({
                            deviceConfigRequired,
                        })
                    }
                    labelFormatter={trOperatingSystem}
                    clearable
                />
            </FormSection>

            <FormInput
                label='Custom device reset instructions link'
                helperText='The link will be displayed as part of the collection request device reset instructions'
                disabled={!canUpdateOrganization}
                nullable
                api={form.fields.deviceResetInstructionsLink}
            />
        </>
    );
};
