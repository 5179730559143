import React, { FC } from 'react';

import {
    bundledPlatformTiers,
    Permission,
    PlatformTier,
    PlatformTierConfigDto,
    PlatformTierConfigFeaturesMap,
    PlatformTierPricesMap,
    unbundledPlatformTiers,
} from '@hofy/api-shared';
import { FormRow } from '@hofy/common';
import { Currency } from '@hofy/global';
import {
    FormContainer,
    FormGridRow,
    FormPriceInput,
    LabeledCheckbox,
    LabeledNumberInput,
    LabeledSelect,
    Paragraph3,
    UseForm,
} from '@hofy/ui';
import { PermissionWrapper } from '@hofy/ui-domain';

import { UpdateOrganizationFormData } from '../../../../services/organizations/types/UpdateOrganizationFormData';
import { useTrPlatformTier } from '../../../../store/organizations/useTrPlatformTier';

interface OrganizationUpdatePlatformTierConfigTabProps {
    form: UseForm<UpdateOrganizationFormData>;
    currency: Currency;
    platformTierPrices: PlatformTierPricesMap;
    platformTierConfigFeatures: PlatformTierConfigFeaturesMap;
}

export const OrganizationUpdatePlatformTierConfigTab: FC<OrganizationUpdatePlatformTierConfigTabProps> = ({
    form,
    platformTierPrices,
    platformTierConfigFeatures,
    currency,
}) => {
    const trPlatformTier = useTrPlatformTier();
    const platformTierConfig = form.values.platformTierConfig;
    const handleStateChange = (value: Partial<PlatformTierConfigDto>) => {
        form.setValues({
            platformTierConfig: {
                ...platformTierConfig,
                ...value,
            },
        });
    };

    const handlePlatformTierChange = (platformTier: PlatformTier) => {
        handleStateChange({
            platformTier,
            ...platformTierConfigFeatures[platformTier],
        });
        form.setValues({
            sassFee: platformTierPrices[platformTier][currency],
        });
    };

    const isUnbundlingEnabled = form.values.unbundlingEnabled;
    const platformTierOptions = isUnbundlingEnabled ? unbundledPlatformTiers : bundledPlatformTiers;

    return (
        <PermissionWrapper permission={Permission.AdminOrganizationUpdatePlatformTierConfig} tooltip>
            <FormContainer>
                <FormRow>
                    <LabeledSelect
                        label='Platform tier'
                        options={platformTierOptions}
                        onChange={handlePlatformTierChange}
                        value={platformTierConfig.platformTier}
                        toText={trPlatformTier}
                    />
                </FormRow>

                <FormGridRow columns={2}>
                    <FormPriceInput label='Platform tier price' clearable={false} api={form.fields.sassFee} />
                    <LabeledNumberInput
                        label='Subsidiary limit'
                        nullable
                        onChange={value =>
                            handleStateChange({
                                billingEntityLimit: value || null,
                            })
                        }
                        value={platformTierConfig.billingEntityLimit || null}
                    />
                    <LabeledNumberInput
                        label='Team limit'
                        nullable
                        onChange={value =>
                            handleStateChange({
                                teamLimit: value || null,
                            })
                        }
                        value={platformTierConfig.teamLimit || null}
                    />
                    {isUnbundlingEnabled && (
                        <LabeledNumberInput
                            label='Device tracked limit'
                            nullable
                            onChange={value =>
                                handleStateChange({
                                    deviceTrackedLimit: value || null,
                                })
                            }
                            value={platformTierConfig.deviceTrackedLimit || null}
                        />
                    )}

                    {platformTierConfig.platformTier === PlatformTier.OrgBasedFree && (
                        <LabeledNumberInput
                            label='Equip laptop limit'
                            nullable
                            onChange={value =>
                                handleStateChange({
                                    teamMemberEquipLimit: value || null,
                                })
                            }
                            value={platformTierConfig.teamMemberEquipLimit || null}
                        />
                    )}
                </FormGridRow>

                <FormGridRow columns={2}>
                    <LabeledCheckbox
                        label={<Paragraph3>SAML</Paragraph3>}
                        checked={platformTierConfig.samlEnabled}
                        onChange={value =>
                            handleStateChange({
                                samlEnabled: value,
                            })
                        }
                    />
                    <LabeledCheckbox
                        label={<Paragraph3>Auto approval</Paragraph3>}
                        checked={platformTierConfig.autoApprovalEnabled}
                        onChange={value =>
                            handleStateChange({
                                autoApprovalEnabled: value,
                            })
                        }
                    />
                    <LabeledCheckbox
                        label={<Paragraph3>HRIS Import Rules</Paragraph3>}
                        checked={platformTierConfig.hrisAutoImportEnabled}
                        onChange={value =>
                            handleStateChange({
                                hrisAutoImportEnabled: value,
                            })
                        }
                    />
                    <LabeledCheckbox
                        label={<Paragraph3>Manager only organisation</Paragraph3>}
                        checked={platformTierConfig.managerOnlyOrganizationEnabled}
                        onChange={managerOnlyOrganizationEnabled =>
                            handleStateChange({
                                managerOnlyOrganizationEnabled,
                            })
                        }
                    />
                    {!isUnbundlingEnabled && (
                        <LabeledCheckbox
                            label={<Paragraph3>5 Free Licenses</Paragraph3>}
                            checked={platformTierConfig.freeLicensesLimit > 0}
                            onChange={value =>
                                handleStateChange({
                                    freeLicensesLimit: value ? 5 : 0,
                                })
                            }
                        />
                    )}
                </FormGridRow>
            </FormContainer>
        </PermissionWrapper>
    );
};
