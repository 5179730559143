import { useMemo } from 'react';

import { AuditLogsFilters } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useNumberQueryParam, useStringQueryParam } from '@hofy/router';

export const useAuditLogsFilters = () => {
    const [auditTableName, setAuditTableName] = useStringQueryParam('auditTableName');
    const [auditTableId, setAuditTableId] = useNumberQueryParam('auditTableId');
    const [transactionUuid, setTransactionUuid] = useStringQueryParam<UUID>('transactionUuid');

    const filters: AuditLogsFilters = useMemo(() => {
        return {
            auditTableName,
            auditTableId,
            transactionUuid,
        };
    }, [auditTableName, auditTableId, transactionUuid]);

    return {
        filters,
        auditTableId,
        auditTableName,
        transactionUuid,
        setAuditTableId,
        setAuditTableName,
        setTransactionUuid,
    };
};
