import { useMutation } from '@tanstack/react-query';

import { useForm, useToast } from '@hofy/ui';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import { DisconnectOrganizationHRISFormData } from '../../services/organizations/types/DisconnectOrganizationHRISFormData';

export const useDisconnectOrganizationHRIS = (organizationId: number) => {
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (payload: DisconnectOrganizationHRISFormData) =>
            adminOrganizationsService.disconnectOrganizationHRIS(organizationId, payload),
        onSuccess: () => {
            showToast({
                type: 'positive',
                message: `HRIS system disconnected`,
            });
        },
    });

    const form = useForm<DisconnectOrganizationHRISFormData>({
        initial: { deleteNonImportedUsers: false },
        onSubmit: mutation.mutate,
    });

    return {
        form,
    };
};
