import React, { FC } from 'react';

import { OrganizationStatus, Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { nowISODate } from '@hofy/helpers';
import {
    AsyncButton,
    FormContainer,
    FormDateInput,
    FormInput,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useAdminI18n } from '../../../i18n/useAdminI18n';
import { OrganizationDetailsDto } from '../../../services/organizations/types/OrganizationDto';
import { useAdminUpdateOrganizationStatus } from '../../../store/organizations/useAdminUpdateOrganizationStatus';
import {
    OrganizationStatusDropdown,
    OrganizationStatusPermission,
} from '../createOrganizationSlideout/OrganizationStatusDropdown';

interface StatusUpdateModalProps {
    organization: OrganizationDetailsDto;
    onClose(): void;
}

export const StatusUpdateModal: FC<StatusUpdateModalProps> = ({ organization, onClose }) => {
    const { form, isLoading } = useAdminUpdateOrganizationStatus(organization, onClose);
    const { tr } = useAdminI18n();

    const { hasPermission } = useSession();

    const statusPermission = organizationStatusPermission(hasPermission);

    const organizationIsTrial = organization.status === OrganizationStatus.Trial;

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader
                title={tr('organization-page.status-update.title')}
                subtitle={tr('organization-page.status-update.subtitle')}
            />
            <ModalContent>
                <FormContainer>
                    <OrganizationStatusDropdown
                        onChange={status =>
                            form.setValues({
                                status,
                            })
                        }
                        status={form.values.status}
                        statusPermission={statusPermission}
                    />

                    {(organizationIsTrial || form.values.status === OrganizationStatus.Trial) && (
                        <FormDateInput
                            label={tr('organization-page.status-update.modal.trial-end')}
                            api={form.fields.trialEndOn}
                            minDate={nowISODate()}
                            nullable
                        />
                    )}
                    <FormInput
                        label={tr('organization-page.status-update.modal.set-org-message')}
                        helperText={tr('organization-page.status-update.modal.set-org-message-helper')}
                        api={form.fields.bannerMessage}
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton label={tr('organization-page.status-update.modal.cancel')} onClick={onClose} />
                <AsyncButton
                    label={tr('organization-page.status-update.modal.confirm')}
                    disableCheck
                    onClick={form.submit}
                    isLoading={isLoading}
                />
            </ModalFooter>
        </Modal>
    );
};

const organizationStatusPermission = (hasPermission: (permission: Permission) => boolean) => {
    const canUpdateOrganizationFinancialSettings = hasPermission(
        Permission.AdminOrganizationUpdateFinancialSettings,
    );
    const canUpdateOrganizationTrialStatus = hasPermission(Permission.AdminOrganizationUpdateTrialStatus);

    if (canUpdateOrganizationFinancialSettings) {
        return OrganizationStatusPermission.All;
    }
    if (canUpdateOrganizationTrialStatus) {
        return OrganizationStatusPermission.TrialOnly;
    }
    // Insufficient editing permissions
    return OrganizationStatusPermission.None;
};
