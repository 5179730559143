import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useForm, useToast } from '@hofy/ui';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import { OrganizationDetailsDto } from '../../services/organizations/types/OrganizationDto';
import { UpdateOrganizationDataErasureSettingsPayload } from '../../services/organizations/types/UpdateOrganizationDataErasureSettingsPayload';
import { organizationsCacheKey } from './organizationsCacheKey';

export const useAdminUpdateDataErasureSettings = (
    organization: OrganizationDetailsDto,
    onSuccess: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: adminOrganizationsService.updateOrganizationDataErasureSettings,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organization.id] });
            showToast({
                type: 'positive',
                message: `Organization data erasure settings successfully updated`,
            });
            onSuccess();
        },
    });

    const form = useForm<UpdateOrganizationDataErasureSettingsPayload>({
        initial: {
            organizationId: organization.id,
            enabled: organization.dataErasureEnabled,
            fee: organization.pricing.dataErasureFee,
        },
        onSubmit: mutation.mutate,
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
