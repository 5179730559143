import React, { FC } from 'react';

import { AuditLogDetailsDto, relationLabel } from '@hofy/api-admin';
import { formatDateTime } from '@hofy/helpers';
import { FormGridRow, FormSection, Labeled, LabeledText, Paragraph3 } from '@hofy/ui';

import { AuditLogOperationStatusChip } from '../../components/domain/auditLogs/AuditLogOperationTypeChip';
import { Link } from '../../components/routing/Link';
import { useAuditLink } from '../../store/auditLogs/useAuditLink';
import { AuditLogsDetailsEntityLink } from './AuditLogDetailEntityLink';
import { ActorLabel } from './components/ActorLabel';

interface AuditLogsDetailsSlideoutBaseDataProps {
    auditLog: AuditLogDetailsDto;
}

export const AuditLogsDetailsSlideoutBaseData: FC<AuditLogsDetailsSlideoutBaseDataProps> = ({ auditLog }) => {
    const auditLink = useAuditLink();
    const auditLogsLink = auditLink({
        transactionUuid: auditLog.transactionUuid,
    });
    const tableLink = auditLink({
        auditTableName: auditLog.relation.tableName,
        auditTableId: auditLog.relation.tableId,
    });
    return (
        <>
            <FormSection label='Details'>
                <FormGridRow columns={2}>
                    <LabeledText
                        label='Entity link'
                        content={
                            <AuditLogsDetailsEntityLink
                                tableName={auditLog.relation.tableName}
                                tableId={auditLog.relation.tableId}
                            />
                        }
                    />
                    <Labeled
                        label='Operation type'
                        content={<AuditLogOperationStatusChip operationType={auditLog.operationType} />}
                    />
                    <LabeledText label='Method' content={auditLog.method || '--'} />
                    <LabeledText label='Created at' content={formatDateTime(auditLog.createdAt)} />
                    {auditLog.transactionUuid && (
                        <LabeledText
                            label='Transaction'
                            content={<Link to={auditLogsLink}>{auditLog.transactionUuid}</Link>}
                        />
                    )}
                    <LabeledText
                        label='Table'
                        content={
                            <Link to={tableLink}>
                                {`${auditLog.relation.tableName} #${auditLog.relation.tableId}`}
                            </Link>
                        }
                    />
                    {auditLog.actor && (
                        <LabeledText label='Actor' content={<ActorLabel actor={auditLog.actor} />} />
                    )}
                </FormGridRow>
            </FormSection>
            {auditLog.dataRelations.length > 0 && (
                <FormSection marginTop={30} label='Related data'>
                    {auditLog.dataRelations.map((d, index) => (
                        <Link
                            to={auditLink({
                                auditTableName: d.tableName,
                                auditTableId: d.tableId,
                            })}
                            key={index}
                        >
                            <Paragraph3>{relationLabel(d)}</Paragraph3>
                        </Link>
                    ))}
                </FormSection>
            )}
        </>
    );
};
