import { keyBy } from 'lodash';
import React, { forwardRef, ReactElement, Ref, useMemo } from 'react';

import { useBillingEntitiesQuery } from '@hofy/api-admin';
import {
    LabeledSelectSearch,
    LabeledSelectSearchNullableStringProps,
    LabeledSelectSearchOnlyStringProps,
} from '@hofy/ui';

interface BaseProps {
    placeholder?: string;
    organizationId?: number;
}

type LabeledBillingEntityNormalProps = Omit<
    LabeledSelectSearchOnlyStringProps<number>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;
type LabeledBillingEntityNullableProps = Omit<
    LabeledSelectSearchNullableStringProps<number>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;

export type LabeledBillingEntityProps = LabeledBillingEntityNormalProps | LabeledBillingEntityNullableProps;

export const LabeledBillingEntitySelect = forwardRef(
    ({ organizationId, ...rest }: LabeledBillingEntityProps, ref: Ref<HTMLDivElement>) => {
        const { billingEntities } = useBillingEntitiesQuery({ organizationId });
        const nameMap = useMemo(() => keyBy(billingEntities, v => v.id), [billingEntities]);
        return (
            <LabeledSelectSearch<number>
                toText={org => nameMap[org]?.name}
                options={billingEntities.map(v => v.id)}
                ref={ref}
                {...rest}
            />
        );
    },
) as (props: LabeledBillingEntityProps & { ref?: Ref<HTMLDivElement> }) => ReactElement;
