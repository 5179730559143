import { includes, xor } from 'lodash';
import React, { FC, useState } from 'react';

import { formatAdminName, UserRefDto } from '@hofy/api-shared';
import { useSearch } from '@hofy/common';
import { SvgIcon } from '@hofy/ui';

import { SearchableBlockFilter } from '../../../../../components/design/blockFilters/SearchableBlockFilter';

interface AdminBlockFilterProps {
    admins: UserRefDto[];
    selected: number[];
    setSelected(ids?: number[]): void;
}

export const AdminBlockFilter: FC<AdminBlockFilterProps> = ({ admins, selected, setSelected }) => {
    const [userSearchQuery, setUserSearchQuery] = useState('');

    const { results } = useSearch(admins, admin => [formatAdminName(admin)], userSearchQuery);

    return (
        <SearchableBlockFilter<UserRefDto>
            title='Created by'
            icon={SvgIcon.User}
            onFilterClick={admin => setSelected(xor(selected, [admin.id]))}
            isSelected={admin => includes(selected, admin.id)}
            items={results}
            renderItem={formatAdminName}
            searchPlaceholder='Search'
            search={userSearchQuery}
            onSearch={setUserSearchQuery}
        />
    );
};
