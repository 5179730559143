import { keyBy } from 'lodash';
import React, { forwardRef, ReactElement, Ref, useMemo } from 'react';

import {
    LabeledSelectSearch,
    LabeledSelectSearchNullableStringProps,
    LabeledSelectSearchOnlyStringProps,
} from '@hofy/ui';

import {
    AdminOrganizationsFilters,
    emptyAdminOrganizationsFilters,
} from '../../../services/organizations/types/AdminOrganizationsFilters';
import { useOrganizationsRefsQuery } from '../../../store/organizations/useOrganizationsQuery';

interface BaseProps {
    filters?: AdminOrganizationsFilters;
}

type LabeledOrganizationNormalProps = Omit<
    LabeledSelectSearchOnlyStringProps<number>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;
type LabeledOrganizationNullableProps = Omit<
    LabeledSelectSearchNullableStringProps<number>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;

export type LabeledOrganizationProps = LabeledOrganizationNormalProps | LabeledOrganizationNullableProps;

export const LabeledOrganizationSelect = forwardRef(
    ({ filters, ...rest }: LabeledOrganizationProps, ref: Ref<HTMLDivElement>) => {
        const { data } = useOrganizationsRefsQuery(filters ?? emptyAdminOrganizationsFilters);
        const nameMap = useMemo(() => keyBy(data, v => v.id), [data]);
        return (
            <LabeledSelectSearch<number>
                toText={org => nameMap[org]?.name}
                options={data.map(v => v.id)}
                ref={ref}
                {...rest}
            />
        );
    },
) as (props: LabeledOrganizationProps & { ref?: Ref<HTMLDivElement> }) => ReactElement;
