import React, { FC, ReactNode } from 'react';

import { Link } from '../../components/routing/Link';

interface ProductDetailsLinkProps {
    id: number;
    children?: ReactNode;
}

export const ProductDetailsLink: FC<ProductDetailsLinkProps> = ({ id, children }) => {
    return <Link to={`/products/${id}/detail`}>{children}</Link>;
};
