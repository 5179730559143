import React, { FC, useState } from 'react';

import { AddressDto } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Alert, Box, Paragraph3 } from '@hofy/ui';

import { AddressDetails } from '../../../components/domain/address/AddressDetails';

interface AddressResolverModalProps {
    userAddress: AddressDto;
    deliveryAddress: AddressDto;
    onSelect(selectedAddress: AddressDto): void;
}

export const SelectAddress: FC<AddressResolverModalProps> = ({ userAddress, deliveryAddress, onSelect }) => {
    const multipleAddresses = userAddress.uuid !== deliveryAddress.uuid;
    const [selectedAddress, setSelectedAddress] = useState(deliveryAddress);

    return (
        <Box column gap={16} marginTop={16}>
            {multipleAddresses && (
                <Alert
                    type='warning'
                    description='Multiple addresses found - please select an address to use'
                />
            )}
            <Box row alignItems='stretch' gap={10} maxWidth={800}>
                {multipleAddresses && (
                    <Box column flex={1} minWidth={350} gap={20}>
                        <Paragraph3>User&apos;s current profile address</Paragraph3>
                        <Box
                            padding={20}
                            onClick={() => {
                                setSelectedAddress(userAddress);
                                onSelect(userAddress);
                            }}
                            pointer
                            border
                            rounded={8}
                            bg={
                                selectedAddress.uuid === userAddress.uuid
                                    ? Color.BackgroundSubtleBrand
                                    : Color.BackgroundDefault
                            }
                        >
                            <AddressDetails address={userAddress} />
                        </Box>
                    </Box>
                )}
                <Box column flex={1} minWidth={350} gap={20}>
                    <Paragraph3>Assignment initial delivery address</Paragraph3>
                    <Box
                        flex={1}
                        padding={20}
                        onClick={() => {
                            setSelectedAddress(deliveryAddress);
                            onSelect(deliveryAddress);
                        }}
                        pointer
                        border
                        rounded={8}
                        bg={
                            selectedAddress.uuid === deliveryAddress.uuid
                                ? Color.BackgroundSubtleBrand
                                : Color.BackgroundDefault
                        }
                    >
                        <AddressDetails address={deliveryAddress} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
