import { useMemo } from 'react';

import { useAdminsQuery } from '@hofy/api-admin';
import { Role, UserRefDto } from '@hofy/api-shared';

interface AdminOptions {
    [key: number]: string;
}

const mapAdminUserToOptions = (users: UserRefDto[]): [AdminOptions, number[]] => {
    const options: AdminOptions = {};
    for (const { id, firstName, lastName } of users) {
        options[id] = `${firstName || ''} ${lastName || ''}`;
    }

    const ids = users.map(i => i.id);
    return [options, ids];
};

export const useOrganizationUserByRoleOptions = (role: Role) => {
    const { data: admins, isLoading } = useAdminsQuery(role);
    const [options, ids] = useMemo(() => mapAdminUserToOptions(admins), [admins]);
    return {
        options,
        ids,
        isLoading,
    };
};
